import {Injectable} from '@angular/core';
import {HTTP} from '@ionic-native/http/ngx';
import {from} from 'rxjs';
import {Platform} from '@ionic/angular';

import {LocationService} from '../location/location.service';
import {environment} from '../../../environments/environment';
import {User} from '../../interfaces/user';

@Injectable({
    providedIn: 'root'
})
export class MarketingService {

    apiKey = 'pk_f97735d2a804a5669d00cfe5dfaf64ebd3';
    user: User;

    constructor(private http: HTTP, private plt: Platform,
                private location: LocationService) {
        if (this.plt.is('cordova')) {
            http.setDataSerializer('json');
        }
    }

    public subscribeUserToList(user: any) {
        // Only add users from production builds
        this.user = user;
        this.checkListForUser();
    }

    private checkListForUser() {
        if (this.plt.is('cordova') && this.user) {
            const nativeCall = this.http.get('https://a.klaviyo.com/api/v2/list/NGh84R/subscribe', {
                api_key: this.apiKey,
                emails: this.user.coachEmail
            }, {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            });

            from(nativeCall).pipe().subscribe(data => {
                if (JSON.parse(data.data).length === 0) {
                    // No user so we can go ahead and add them
                    this.addUserToList();
                }
            }, err => {
                // Fail
            });
        }
    }

    private addUserToList() {
        if (this.plt.is('cordova')) {
            const nativeCall = this.http.post('https://a.klaviyo.com/api/v2/list/NGh84R/subscribe', this.getBody(), {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            });

            from(nativeCall).pipe().subscribe(data => {
                // Success
            }, err => {
                // Fail
            });
        }
    }

    private getBody() {
        return {
            api_key: this.apiKey,
            profiles: [
                {
                    email: this.user.coachEmail,
                    full_name: this.user.coachName,
                    device_location: this.location.geoAddress,
                    device_type: this.getDeviceType(),
                    subscription_level: 'free',
                    accepts_marketing: !!this.user.coachPreferences.privacy
                }
            ]
        };
    }

    private getDeviceType(): string {
        if (this.plt.is('ios')) {
            return 'iPad';
        } else if (this.plt.is('android')) {
            return 'Android';
        } else {
            return 'Browser';
        }
    }
}
