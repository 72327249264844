import {Component, OnInit} from '@angular/core';
import { ModalController } from '@ionic/angular';
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-action-required-bulk-gameday-model',
    template: `
        <app-reusable-modal-structure-six [title]="title" [descriptionPoint1]="descriptionPoint1" [buttonText]="buttonText" [buttonPress]="buttonPressed">

        </app-reusable-modal-structure-six>`,
    styles: [`
        .container {
            /* Add your styles here */
        }
    `]
})
export class ActionRequiredBulkGamedayModelComponent implements OnInit {

    title: string;
    descriptionPoint1: string;
    buttonText: string;

    constructor(private translate: TranslateService, private modalController: ModalController) {
    }

    ngOnInit() {
        this.title = this.translate.instant('Resources.ActionRequired:');
        this.descriptionPoint1 = this.translate.instant('Resources.FinaliseYourBulkGamedaysMessage');
        this.buttonText = this.translate.instant('Resources.Close');
    }

    buttonPressed = () => {
       this.modalController.dismiss();
    }

}