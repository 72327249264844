export enum MetricType {
    totalDistance = 'totalDistance',
    distancePerMinute = 'distancePerMinute',
    avgPace = 'averagePace',
    speed = 'currentSpeed',
    maxSpeed = 'maxSpeed',
    highSpeedRunning = 'highSpeedRunning',
    distanceZ5 = 'distanceZone5',
    distanceZ6 = 'distanceZone6',
    highSpeedRunningPerMinute = 'highSpeedRunningPerMinute',
    highMetabolicLoadDistance = 'highMetabolicLoadDistance',
    highMetabolicLoadDistancePerMinute = 'highMetabolicLoadDistancePerMinute',
    numberOfSprints = 'numberOfSprints',
    sprintDistance = 'sprintDistance',
    speedIntensity = 'speedIntensity',
    accelerations = 'accelerations',
    decelerations = 'decelerations',
    averageMetabolicPower = 'averageMetabolicPower',
    equivalentMetabolicDistance = 'equivalentMetabolicDistance',
    heartRate = 'heartRate',
    maxHeartRate = 'maxHeartRate',
    heartRateExertion = 'heartRateExertion',
    averageHeartRate = 'averageHeartRate',
    timeInRedZone = 'timeInRedZone',
    impacts = 'impacts',
    dynamicStressLoad = 'dynamicStressLoad',
    entryZ5 = 'entriesZone5',
    entryZ6 = 'entriesZone6',
    fatigueIndex = 'fatigueIndex',
    stepBalance = 'stepBalance',
    calories = 'calories',
    count = 'allMetrics',
    sprintDistancePerMinute = 'sprintDistancePerMinute'
}
