import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {Device} from '@ionic-native/device/ngx';

import * as moment from 'moment';
import {DistanceUnit} from '../../enums/distance-unit.enum';
import {MetricType} from '../../interfaces/session/metricType';
import {ModalController, Platform, ToastController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {SpeedUnit} from '../../enums/speed-unit.enum';
import {ChartType} from '../../enums/chart-type.enum';
import {ComboChartType} from '../../interfaces/combo-chart-type';
import {ReportTemplate, ReportTemplateDisplay} from '../../interfaces/report-template';
import {ReportType} from '../../enums/report-type.enum';
import {MetricDetail} from '../../interfaces/metrics/metric-detail';
import {PositionData} from '../../interfaces/session/positionData';
import {Pitch} from '../../interfaces/pitch';
import {File} from '@ionic-native/file/ngx';
import {FileOpener} from '@ionic-native/file-opener/ngx';
import {MetricUnit} from '../../enums/metric-unit.enum';
import {GameDayHalf} from '../../enums/game-day-half.enum';
import {Router} from '@angular/router';
import {Group} from 'src/app/interfaces/groups/group';
import {Player} from '../../interfaces/player';
import {Session} from '../../interfaces/session/session';
import {SessionType} from '../../enums/session-type.enum';
import {GamedayBulkSessionState} from '../../enums/gameday-bulk-session-state';
import {SelectedChartTypeEnum} from '../../enums/selected-chart-type-enum';
import {SelectedZonesEnum} from '../../enums/selected-zones-enum';
import {ZonalSettingsSymbols} from '../../interfaces/zonals-settings-symbols';
import {HeartRateZoneValues, SpeedZoneValues} from '../../interfaces/zone-value-interface';
import {AddSessionComponent} from '../../components/modals/add-session/add-session.component';
import {ActivePageEnum} from '../../enums/active-page-enum';
import {ComparisonSelection} from '../../enums/comparison-selection.enum';
import {
    OnlyEditingCurrentSessionWarningComponent
} from "../../components/modals/only-editing-current-session-warning/only-editing-current-session-warning.component";

@Injectable({
    providedIn: 'root'
})
export class HelperService {
    private legendDataArrayHolder: any = [];

    constructor(private http: HttpClient, private toastController: ToastController, public translate: TranslateService, private fileOpener: FileOpener, private file: File, private platform: Platform, private device: Device, private router: Router, private modalController: ModalController) {
        this.translate.setDefaultLang(this.translationMapper(this.translate.getBrowserLang()));
        this.translate.use(this.translationMapper((this.translate.getBrowserLang())));
    }

    resetLegendDataArrayHolder() {
        this.legendDataArrayHolder = [];
    }

    formatTimestampToDateShort(timestamp) {
        const date = new Date(timestamp);

        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const month = months[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();

        return `${month} ${day}, ${year}`;
    }


    getTranslatedSortName(sortName: string) {
        switch (sortName) {
            case 'Min-Max':
                return this.getTransByKey('Resources.Min-Max');
            case 'Max-Min':
                return this.getTransByKey('Resources.Max-Min');
            case 'Z-A':
                return this.getTransByKey('Resources.Z-A');
            case 'A-Z':
                return this.getTransByKey('Resources.A-Z');
            case 'Newest-Oldest':
                return this.getTransByKey('Resources.Newest-Oldest');
            case 'Oldest-Newest':
                return this.getTransByKey('Resources.Oldest-Newest');
        }
    }

    translateToStandardNameFormat(inputString) {
        // Split the string into words by spaces
        const words = inputString.split(' ');

        // Capitalize the first letter of each word and make the rest lowercase
        const properNameWords = words.map(word => {
            if (word.length === 0) {
                return word; // Handle empty words gracefully
            }
            const firstLetter = word[0].toUpperCase();
            const restOfWord = word.slice(1).toLowerCase();
            return firstLetter + restOfWord;
        });

        // Join the transformed words back into a string
        return properNameWords.join(' ');
    }

    translationMapper(language: string) {
        switch (language) {
            case 'de':
                return 'de-DE'; // German (Germany)
            case 'fr':
                return 'fr-FR'; // French (France)
            case 'es':
                return 'es-ES'; // Spanish (Spain)
            case 'zh':
                return 'zh-CN'; // Chinese (Simplified, China)
            case 'it':
                return 'it-IT'; // Italian (Italy)
            case 'nl':
                return 'nl-NL'; // Dutch (Netherlands)
            case 'pt':
                return 'pt-PT'; // Portuguese (Portugal)
            case 'ja':
                return 'ja-JP'; // Japanese (Japan)
            case 'ru':
                return 'ru-RU'; // Russian (Russia)
            case 'cs':
                return 'cs-CZ'; // Czech (Czech Republic)
            case 'at':
                return 'de-AT'; // German (Austria)
            case 'el':
                return 'el-GR'; // Greek (Greece)
            case 'fi':
                return 'fi-FI'; // Finish (Finland)
            case 'ar':
                return 'ar-EG'; // Arabic (Egypt)
            case 'bg':
                return 'bg-BG'; // Bulgarian (Bulgaria)
            case 'no':
                return 'nb-NO'; // Norwegian Bokmål (Norway)
            case 'pl':
                return 'pl-PL'; // Polish (Poland)
            case 'sk':
                return 'sk-SK'; // Slovak (Slovakia)
            case 'sl':
                return 'sl-SI'; // Slovenian (Slovenia)
            case 'sv':
                return 'sv-FI'; // Swedish (Sweedish)
            default:
                return 'en-GB'; // Use the original language code if not found
        }
    }

    translateIfMetricCategoryNameOrGamedayBreakdown(value: string) {
        switch (value) {
            case 'Speed':
                return this.getTransByKey('Resources.Speed');
            case 'Intensity':
                return this.getTransByKey('Resources.Intensity');
            case 'Volume':
                return this.getTransByKey('Resources.Volume');
            case 'Stress':
                return this.getTransByKey('Resources.Stress');
            case 'Heart Rate':
                return this.getTransByKey('Resources.HeartRate');
            case '1st Half':
                return this.getTransByKey('Resources.1stHalf');
            case '2nd Half':
                return this.getTransByKey('Resources.2ndHalf');
            case 'Total':
                return this.getTransByKey('Resources.Total');
            default:
                return value;
        }
    }

    public getDurationBetweenTimestamps(startTimestamp, endTimestamp): moment.Duration {
        const startOfSession = moment(startTimestamp).set('second', 0);
        const endOfSession = moment(endTimestamp).set('second', 0);
        const difference = endOfSession.diff(startOfSession);
        return moment.duration(difference);
    }

    deviceWidthLessThan1500() {
        return this.platform.width() < 1500;
    }

    shrinkImage(imageDataBase64: string, wantedWidth: number): Promise<string> {
        return new Promise(async (resolve, reject) => {

            const img = new Image();

            img.src = 'data:image/png;base64,' + imageDataBase64;
            img.onload = () => {

                // Check if image was loaded correctly
                if (img.width + img.height === 0) {
                    reject();
                }

                // Resizing is efficient for images bigger than 250px in width
                if (img.width <= 250) {
                    resolve(imageDataBase64);
                } else {
                    const shrinkedImage = this.resizeImage(img, wantedWidth);
                    resolve(shrinkedImage.replace('data:image/png;base64,', ''));
                }
            };
        });
    }

    resizeImage(image: HTMLImageElement, wantedWidth: number, wantedHeight?: number) {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const scaleFactor = wantedWidth / image.width;
        if (wantedHeight === undefined) {
            wantedHeight = image.height * scaleFactor;
        }

        canvas.width = wantedWidth;
        canvas.height = wantedHeight;

        ctx.drawImage(image, 0, 0, wantedWidth, wantedHeight);
        return canvas.toDataURL();
    }

    getDecimalPlacesByMetric(metricType: MetricType, distanceUnit: DistanceUnit) {
        if (metricType === MetricType.maxSpeed || (metricType === MetricType.totalDistance && (distanceUnit === DistanceUnit.Kilometers || distanceUnit === DistanceUnit.Miles))) {
            return 2;
        }

        return 0;
    }

    metersToKilometers(meters: number) {
        return this.convertDistanceByMetricType(meters, DistanceUnit.Kilometers, MetricType.totalDistance);
    }

    yardsToMiles(yards: number) {
        const meters = yards / 1.0936;
        const miles = this.convertDistanceByMetricType(meters, DistanceUnit.Miles, MetricType.totalDistance);
        return miles;
    }

    convertDistanceByMetricType(meters: number, distanceUnit: DistanceUnit, metricType: MetricType) {
        if (isNaN(meters) || meters === undefined) {
            return 0;
        }

        let updatedDistanceUnit = distanceUnit;
        let numDecimalPlaces = 0;

        if (metricType === MetricType.totalDistance && (distanceUnit === DistanceUnit.Kilometers || distanceUnit === DistanceUnit.Miles)) {
            numDecimalPlaces = 2;
        } else if (metricType === MetricType.highSpeedRunning || metricType === MetricType.distancePerMinute || metricType === MetricType.highMetabolicLoadDistance || metricType === MetricType.sprintDistance || metricType === MetricType.highSpeedRunningPerMinute || metricType === MetricType.highMetabolicLoadDistancePerMinute || metricType === MetricType.sprintDistancePerMinute) {
            if (distanceUnit === DistanceUnit.Kilometers) {
                updatedDistanceUnit = DistanceUnit.Meters;
            } else if (distanceUnit === DistanceUnit.Miles) {
                updatedDistanceUnit = DistanceUnit.Yards;
            }
        }

        switch (updatedDistanceUnit) {
            case 0:
            default:
                return this.roundValueByDecimals(meters, numDecimalPlaces);
            case 1:
                return this.roundValueByDecimals(meters / 1000, numDecimalPlaces);
            case 2:
                return this.roundValueByDecimals(meters * 0.00062137, numDecimalPlaces);
            case 3:
                return this.roundValueByDecimals(meters * 1.0936, numDecimalPlaces);
        }
    }

    /**
     *  Converts the default M/S speed into the users chose unit
     * @param metersPerSec
     * @param speedUnit
     */
    convertSpeed(metersPerSec: number, speedUnit: SpeedUnit) {
        switch (speedUnit) {
            case SpeedUnit.MetersPerSec:
            default:
                return this.roundValueByDecimals(metersPerSec, 2);
            case SpeedUnit.KilometersPerHour:
                return this.roundValueByDecimals(metersPerSec * 3.6, 2);
            case SpeedUnit.MilesPerHour:
                return this.roundValueByDecimals(metersPerSec * 2.236936, 2);
            case SpeedUnit.YardsPerSec:
                return this.roundValueByDecimals(metersPerSec / 0.9144, 2);
        }
    }

    /**
     *  Converts the current speed to M/s speed
     * @param metersPerSec
     * @param speedUnit
     */
    convertSpeedToMetersPerSecond(value: number, speedUnit: SpeedUnit) {
        switch (speedUnit) {
            case SpeedUnit.MetersPerSec:
            default:
                return this.roundValueByDecimals(value, 2);
            case SpeedUnit.KilometersPerHour:
                return this.roundValueByDecimals(value / 3.6, 2);
            case SpeedUnit.MilesPerHour:
                return this.roundValueByDecimals(value / 2.236936, 2);
            case SpeedUnit.YardsPerSec:
                return this.roundValueByDecimals(value * 0.9144, 2);
        }
    }


    /**
     * Update the zonal attributes to speed zone object
     * Usage:
     *  - Used when updating the db for the users selected speed zone intervals
     * @param zonalAttribute
     * @param zoneValues
     */
    updateZonalAttributes(zonalAttribute: ZonalSettingsSymbols[], zoneValues: any): any {
        zonalAttribute.forEach((zonalObject) => {
            if (zoneValues[zonalObject.attributeName] !== undefined && zoneValues[zonalObject.attributeName] !== null) {
                zoneValues[zonalObject.attributeName] = zonalObject.value;
            }
        });
        return zoneValues;
    }

    updatedZonalAttributesToSpeedZonesObject(zonalAttribute: ZonalSettingsSymbols[]): SpeedZoneValues {
        const speedZoneValues: SpeedZoneValues = {
            jogging: 0, running: 0, sprinting: 0, standing: 0, walking: 0
        };

        return this.updateZonalAttributes(zonalAttribute, speedZoneValues);
    }

    updatedZonalAttributesToHeartRateZonesObject(zonalAttribute: ZonalSettingsSymbols[]): HeartRateZoneValues {
        const heartRateZonesValue = this.getResetHeartRateZonesObject();

        return this.updateZonalAttributes(zonalAttribute, heartRateZonesValue);
    }


    downloadAndOpenBlob(blob: Blob, fileName: string, mimeType: string, reload: boolean = false): boolean {
        let path = this.file.dataDirectory;

        if (this.platform.is('android')) {
            path = this.file.externalDataDirectory;
        }
        const filePath = path + fileName;

        this.file.writeFile(path, fileName, blob, {replace: true})
            .then(() => {
                this.openFile(filePath, mimeType);
                if (reload) {
                    // window.location.reload();
                }

                return false;

            })
            .catch((error) => {
                this.showToastMessage('Failed to write file to the device');
                return false;
            });
        return true;
    }

    openFile(filePath: string, mimeType: string) {
        this.fileOpener.open(filePath, mimeType)
            .then(() => console.log('File opened successfully'))
            .catch((error) => console.log('Error opening file: ' + error));
    }

    getUnitByMetricType(metricType: MetricType, distanceUnit: DistanceUnit, speedUnit: SpeedUnit, comboMetric: boolean = false): string {
        switch (metricType) {
            case MetricType.totalDistance:
            case MetricType.highSpeedRunning:
            case MetricType.distancePerMinute:
            case MetricType.highMetabolicLoadDistance:
            case MetricType.sprintDistance:
            case MetricType.highSpeedRunningPerMinute:
            case MetricType.sprintDistancePerMinute:
            case MetricType.highMetabolicLoadDistancePerMinute:
                return this.getDistanceUnitByMetricType(distanceUnit, metricType, comboMetric);
            case MetricType.maxSpeed:
                return this.getSpeedMetricUnit(speedUnit);
            case MetricType.timeInRedZone:
                return 's';
            case MetricType.numberOfSprints:
            case MetricType.accelerations:
            case MetricType.decelerations:
            case MetricType.calories:
            case MetricType.stepBalance:
            case MetricType.dynamicStressLoad:
            default:
                return '';
            case MetricType.averageHeartRate:
            case MetricType.maxHeartRate:
                return 'bpm';
        }
    }


    getDistanceUnitByMetricType(distanceUnit: DistanceUnit, metricType: MetricType, comboMetric: boolean = false): string {
        let updatedDistanceUnit = distanceUnit;

        switch (metricType) {
            case MetricType.highSpeedRunning:
            case MetricType.distancePerMinute:
            case MetricType.highMetabolicLoadDistance:
            case MetricType.sprintDistance:
            case MetricType.highSpeedRunningPerMinute:
            case MetricType.sprintDistancePerMinute:
            case MetricType.highMetabolicLoadDistancePerMinute:
                if (distanceUnit === DistanceUnit.Kilometers && !comboMetric) {
                    updatedDistanceUnit = DistanceUnit.Meters;
                } else if (distanceUnit === DistanceUnit.Miles && !comboMetric) {
                    updatedDistanceUnit = DistanceUnit.Yards;
                }
                break;
        }

        switch (updatedDistanceUnit) {
            case 0:
                return 'm';
            case 1:
                return 'km';
            case 2:
                return 'mi';
            case 3:
                return 'yd';
        }
    }

    findMetricSecondaryDistance(distanceUnit: DistanceUnit, metricType: MetricType): boolean {
        switch (metricType) {
            case MetricType.highSpeedRunning:
            case MetricType.distancePerMinute:
            case MetricType.highMetabolicLoadDistance:
            case MetricType.sprintDistance:
            case MetricType.highSpeedRunningPerMinute:
            case MetricType.sprintDistancePerMinute:
            case MetricType.highMetabolicLoadDistancePerMinute:
                if (distanceUnit === DistanceUnit.Kilometers || distanceUnit === DistanceUnit.Miles) {
                    return true;
                }
        }

        return false;
    }

    /**
     * set an rgb a colour to full opacity e.g pass in rgb(255,255,233, 0.3) and get back (255,255,233, 0.3)
     * @param rgbaString
     */
    setFullOpacityColour(rgbaString) {
        // Split the string by commas to isolate each part of the RGBA
        const parts = rgbaString.split(',');

        // Change the alpha value (last part) to '1)'
        parts[3] = ' 1)';

        // Join the parts back into a full RGBA string
        return parts.join(',');
    }

    checkComboMetricsArePrimaryAndSecondaryDistance(metricType1: MetricType, metricType2: MetricType, distanceUnit: DistanceUnit): boolean {
        const isMetricType1Primary = this.findMetricPrimaryDistance(distanceUnit, metricType1);
        const isMetricType2Primary = this.findMetricPrimaryDistance(distanceUnit, metricType2);
        const isMetricType1Secondary = this.findMetricSecondaryDistance(distanceUnit, metricType1);
        const isMetricType2Secondary = this.findMetricSecondaryDistance(distanceUnit, metricType2);

        return (isMetricType1Primary && isMetricType2Secondary) || (isMetricType2Primary && isMetricType1Secondary);
    }

    findMetricPrimaryDistance(distanceUnit: DistanceUnit, metricType: MetricType) {
        switch (metricType) {
            case MetricType.totalDistance:
                if (distanceUnit === DistanceUnit.Kilometers || distanceUnit === DistanceUnit.Miles) {
                    return true;
                }
        }
        return false;
    }

    getSpeedMetricUnit(speedUnit: SpeedUnit) {
        switch (speedUnit) {
            case SpeedUnit.MetersPerSec:
                return 'm/s';
            case SpeedUnit.KilometersPerHour:
                return 'km/h';
            case SpeedUnit.MilesPerHour:
                return 'mph';
            case SpeedUnit.YardsPerSec:
                return 'yd/s';
        }
    }

    getDistanceMetricUnit(distanceUnit: DistanceUnit) {
        switch (distanceUnit) {
            case DistanceUnit.Meters:
                return 'm';
            case DistanceUnit.Kilometers:
                return 'km';
            case DistanceUnit.Miles:
                return 'mi';
            case DistanceUnit.Yards:
                return 'yd';
        }
    }

    public roundValueByDecimals(value: number, numDecimals: number) {
        if (isNaN(value) || value === undefined) {
            return 0;
        }

        let fixedValue;
        if (typeof value === 'number') {
            if (numDecimals === 0) {
                fixedValue = Math.round(value);
            } else {
                fixedValue = Number(value.toFixed(numDecimals));
            }

            if (fixedValue < 0) {
                return 0;
            }
            return fixedValue;
        }
        return 0;
    }

    public roundToOneDecimal(value) {
        if (isNaN(value) || value === undefined) {
            return 0;
        }

        const firstDecimal = Math.round((value * 10) / 10);
        if (value < 0) {
            return 0;
        }
        if (firstDecimal === 0) {
            return Math.round((value * 100) / 100);
        } else {
            return Math.round((value * 10) / 10);
        }
    }

    public getUrlFromBlob(blob: Blob): Promise<any> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);

            return reader.onload = () => {
                return resolve(reader.result);
            };
        });
    }

    public getS3ImageBlob(url): Observable<any> {
        return this.http.get(url + '?d=' + Date.now(), {responseType: 'blob'}).pipe(switchMap(response => {
            return of(this.getUrlFromBlob(response));
        }), switchMap((imgUrl => {
            return imgUrl;
        })));
    }

    public omitKeys(obj, keys): any {
        const dup = {};
        for (const key in obj) {
            if (keys.indexOf(key) === -1) {
                dup[key] = obj[key];
            }
        }
        return dup;
    }

    async showToastMessage(messageToShow) {
        const toast = await this.toastController.create({
            message: messageToShow, duration: 5000
        });
        toast.present();
    }

    getTransByKey(key: string): string {
        let trans = '';
        this.translate.get(encodeURI(key)).subscribe((res: string) => {
            trans = res;
        });
        return trans;
    }

    getTransByKeyAndValue(key: string, value: string) {
        let trans = '';
        this.translate.get(encodeURI(key), {value}).subscribe((res: string) => {
            trans = res;
        });
        return trans;
    }

    checkImageExists(src): Promise<boolean> {
        return new Promise((resolve) => {
            const image = new Image();
            image.onerror = () => {
                resolve(false);
            };
            image.onload = () => {
                resolve(true);
            };
            image.src = src;
        });
    }

    getTheCurrentPlatform(): string {
        if (this.platform.is('android')) {
            return 'Android';
        }
        if (this.platform.is('ios')) {
            return 'iOS';
        }

        return 'Web';
    }

    getTheDeviceType(): string {
        if (this.platform.is('cordova')) {
            return this.device.model;
        }

        return navigator.userAgent;
    }

    formatDate(date: number): string {
        if (date) {
            return moment(date).format('L');
        }
        return '';
    }

    formatDateByType(date: number, type: string): string {
        if (date) {
            return moment(date).format(type);
        }
        return '';
    }

    getComboChartTypes(): ComboChartType[] {
        return [{
            name: this.getTransByKey('Resources.Bar'), type: ChartType.Bar
        }, {
            name: this.getTransByKey('Resources.Line'), type: ChartType.Line
        }, {
            name: this.getTransByKey('Resources.Area'), type: ChartType.Area
        }];
    }

    getAllMetricsTemplate(isProUser: boolean, metricDetails: MetricDetail[]): ReportTemplateDisplay {
        if (isProUser) {
            return {
                metricDetails,
                metricsDisplay: this.getTemplateMetricDisplay(metricDetails.filter(m => m.name !== this.getTransByKey('Metrics.StepBalance'))),
                reportTemplate: {
                    templateId: this.getEmptyGuid(),
                    metrics: metricDetails.map(m => m.name),
                    name: this.getTransByKey('Resources.AllMetrics'),
                    reportType: ReportType.Default,
                    summaryTable: true
                }
            };
        } else {
            return {
                metricDetails: metricDetails.filter(m => !m.pro && m.name !== this.getTransByKey('Metrics.StepBalance')),
                metricsDisplay: this.getTemplateMetricDisplay(metricDetails.filter(m => !m.pro && m.name !== this.getTransByKey('Metrics.StepBalance'))),
                reportTemplate: {
                    templateId: this.getEmptyGuid(),
                    metrics: metricDetails.filter(m => !m.pro && m.name !== this.getTransByKey('Metrics.StepBalance')).map(m => m.name),
                    name: this.getTransByKey('Resources.AllMetrics'),
                    reportType: ReportType.Default,
                    summaryTable: true
                }
            };
        }
    }

    getReportTemplateDisplay(reportTemplate: ReportTemplate, metricDetails: MetricDetail[]): ReportTemplateDisplay {
        const reportMetricDetails: MetricDetail[] = [];
        if (reportTemplate.metrics) {
            reportTemplate.metrics.forEach(m => {
                if (m) {
                    const cIndex = m.indexOf('combo-');
                    const zIndex = m.indexOf('zonal-');

                    if (cIndex !== -1) { // Combo
                        const metricArr = m.replace('combo-', '').split('/');

                        if (metricArr.length === 2) {
                            const filteredMetricDetails = metricDetails.filter(m2 => m2.selectedChartType === SelectedChartTypeEnum.combo);
                            const mIndex = filteredMetricDetails.findIndex(m2 => m2.comboChartConfig.metricDetail1.type === metricArr[0] && m2.comboChartConfig.metricDetail2.type === metricArr[1]);

                            if (mIndex !== -1) {
                                reportMetricDetails.push(filteredMetricDetails[mIndex]);
                            }
                        }
                    } else if (zIndex !== -1) {
                        // get the number of the zone

                        const combinedNumber = Number(m.match(/\d+/g).join(''));
                        const zonalMetric = metricDetails.find(metric => metric.zoneType === combinedNumber);

                        reportMetricDetails.push(zonalMetric);
                    } else { // Standard
                        const mIndex = metricDetails.findIndex(m2 => m2.type === m);

                        if (mIndex !== -1) {
                            reportMetricDetails.push(metricDetails[mIndex]);
                        }
                    }
                }
            });
        }

        return {
            metricDetails: reportMetricDetails,
            metricsDisplay: this.getTemplateMetricDisplay(reportMetricDetails),
            reportTemplate
        };
    }

    getEmptyGuid() {
        return '00000000-0000-0000-0000-000000000000';
    }

    getTemplateMetricDisplay(metricDetails: MetricDetail[]): string {
        let metrics = '';
        let index = 0;
        for (const md of metricDetails) {
            if (metrics.length < 60) {
                if (metrics.length > 0) {
                    metrics += ', ';
                }
                if (md) {
                    if (md.selectedChartType === SelectedChartTypeEnum.combo) {
                        metrics += this.translate.instant(this.getMetricDisplayText(md.comboChartConfig.metricDetail1)) + '/' + this.translate.instant(this.getMetricDisplayText(md.comboChartConfig.metricDetail2));
                    } else {
                        metrics += md.name;
                    }
                }
                index++;
            } else {
                metrics += ' ' + this.getTransByKeyAndValue('Resources.RemainingMetrics', (metricDetails.length - index).toString());
                break;
            }
        }

        return metrics;
    }

    worldPositionTo2DArray(worldPosition: PositionData[], pitch: Pitch): { x: number, y: number }[] {
        const points = [];

        if (!pitch) {
            return points;
        }
        // @ts-ignore
        const mapsApi = google.maps;

        const cornerOne = new mapsApi.LatLng(pitch.pitchCornerOne.latitude, pitch.pitchCornerOne.longitude);
        const cornerTwo = new mapsApi.LatLng(pitch.pitchCornerTwo.latitude, pitch.pitchCornerTwo.longitude);
        const cornerThree = new mapsApi.LatLng(pitch.pitchCornerThree.latitude, pitch.pitchCornerThree.longitude);
        const cornerFour = new mapsApi.LatLng(pitch.pitchCornerFour.latitude, pitch.pitchCornerFour.longitude);
        const polygon = new mapsApi.Polygon({paths: [cornerOne, cornerTwo, cornerThree, cornerFour]});


        const width = mapsApi.geometry.spherical.computeDistanceBetween(cornerOne, cornerTwo);
        const height = mapsApi.geometry.spherical.computeDistanceBetween(cornerOne, cornerFour);
        const a = width;

        for (let i = worldPosition.length - 1; i >= 0; i--) {
            const worldPoint = worldPosition[i];

            const point = new mapsApi.LatLng(worldPoint.latitude, worldPoint.longitude);

            if (mapsApi.geometry.poly.containsLocation(point, polygon)) {
                const b = mapsApi.geometry.spherical.computeDistanceBetween(cornerOne, point);
                const c = mapsApi.geometry.spherical.computeDistanceBetween(cornerTwo, point);

                // Calculate position using Heron's area formula and Pithagoras
                const p = (a + b + c) / 2;
                const area = Math.sqrt(p * (p - a) * (p - b) * (p - c));
                const y = (2 * area) / a;
                const x = Math.sqrt((b * b) - (y * y));

                points.push({x: 1 - x / width, y: 1 - y / height});
            }
        }
        return points;
    }

    numberOnlyChecker($event): boolean {
        return !($event.keyCode !== 46 && $event.keyCode > 31 && ($event.keyCode < 48 || $event.keyCode > 57));
    }

    replaceSpacesBetweenWords(words: string) {
        // Replace all characters except numbers and letters.
        return words.replace(/[^a-zA-Z0-9]/g, '_');
    }

    getUserRegionString(region: number) {
        let userRegionStr = '';
        switch (region) {
            case 0:
                userRegionStr = 'Africa';
                break;
            case 1:
                userRegionStr = 'Asia';
                break;
            case 2:
                userRegionStr = 'Europe';
                break;
            case 3:
                userRegionStr = 'North America';
                break;
            case 4:
                userRegionStr = 'Oceania';
                break;
            case 5:
                userRegionStr = 'South America';
                break;
        }

        return userRegionStr;
    }

    convertSecondsToTime(seconds) {
        const hours = Math.floor(seconds / 3600);
        seconds %= 3600;
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;

        const formattedHours = this.formatNumber(hours);
        const formattedMinutes = this.formatNumber(minutes);
        const formattedSeconds = this.formatNumber(Math.round(remainingSeconds));

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }

    formatNumber(numberValue) {
        return numberValue.toLocaleString('en-US', {
            minimumIntegerDigits: 2, useGrouping: false,
        });
    }


    getMetricDisplayTextFromMetricType(type: MetricType) {
        return this.getMetricDisplayText({
            type, group: null, pro: null, unit: null, selectedChartType: null, name: null
        });
    }


    getMetricAbbreviatedDisplayTextFromMetricType(type: MetricType) {
        return this.getMetricDisplayTextAbbreviated({
            type, group: null, pro: null, unit: null, selectedChartType: null, name: null
        });
    }


    getMetricDisplayText(metricDetail: MetricDetail) {
        switch (metricDetail.type) {
            case MetricType.accelerations:
                return 'Metrics.Accelerations';
            case MetricType.decelerations:
                return 'Metrics.Decelerations';
            case MetricType.impacts:
                return 'Metrics.Impacts';
            case MetricType.highMetabolicLoadDistance:
                return 'Metrics.HMLD';
            case MetricType.highMetabolicLoadDistancePerMinute:
                return 'Metrics.HMLDPerMin';
            case MetricType.numberOfSprints:
                return 'Metrics.NumberOfSprints';
            case MetricType.sprintDistance:
                return 'Metrics.SprintDistance';
            case MetricType.sprintDistancePerMinute:
                return 'Metrics.SprintDistancePerMin';
            case MetricType.timeInRedZone:
                return 'Metrics.TimeInRedZone';
            case MetricType.totalDistance:
                return 'Metrics.TotalDistance';
            case MetricType.highSpeedRunning:
                return 'Metrics.HSR';
            case MetricType.calories:
                return 'Metrics.Calories';
            case MetricType.speed:
                return 'Metrics.Speed';
            case MetricType.maxSpeed:
                return 'Metrics.MaxSpeed';
            case MetricType.distancePerMinute:
                return 'Metrics.DistPerMin';
            case MetricType.highSpeedRunningPerMinute:
                return 'Metrics.HSRPerMin';
            case MetricType.dynamicStressLoad:
                return 'Metrics.DSL';
            case MetricType.maxHeartRate:
                return 'Metrics.MaxHR';
            case MetricType.averageHeartRate:
                return 'Metrics.AvgHR';
        }

        return metricDetail.name;
    }

    formatShortYearDate(inputDate) {

        // some input dates are ints
        if (typeof inputDate === 'string') {
            // tslint:disable-next-line:radix
            inputDate = parseInt(inputDate);
        }

        try {
            // Check if the inputDate is in milliseconds
            if (inputDate > 10000000000) {
                inputDate = moment(inputDate).toDate(); // Convert milliseconds to a JavaScript Date
            }

            inputDate = moment(inputDate).format('L');

            const parts = inputDate.split('/');

            if (parts[2].length > 2) {
                parts[2] = parts[2].slice(-2);
            }

            return parts.join('/');
        } catch (e) {
            console.log(inputDate);
            console.log(e.message);
        }
        return '';
    }

    getMetricDisplayTextAbbreviated(metricDetail: MetricDetail) {
        switch (metricDetail.type) {
            case MetricType.accelerations:
                return 'Metrics.AbbreviatedAccelerations';
            case MetricType.decelerations:
                return 'Metrics.AbbreviatedDecelerations';
            case MetricType.highMetabolicLoadDistance:
                return 'Metrics.AbbreviatedHighIntensityDistance';
            case MetricType.numberOfSprints:
                return 'Metrics.AbbreviatedNoOfSprint';
            case MetricType.sprintDistance:
                return 'Metrics.AbbreviatedSprintDistance';
            case MetricType.sprintDistancePerMinute:
                return 'Metrics.AbbreviatedSprintDistancePerMinute';
            case MetricType.timeInRedZone:
                return 'Metrics.AbbreviatedTimeInRedZone';
            case MetricType.totalDistance:
                return 'Metrics.AbbreviatedTotalDistance';
            case MetricType.highSpeedRunning:
                return 'Metrics.AbbreviatedHighSpeedRunning';
            case MetricType.calories:
                return 'Metrics.Calories';
            case MetricType.speed:
                return 'Metrics.Speed';
            case MetricType.maxSpeed:
                return 'Metrics.MaxSpeed';
            case MetricType.distancePerMinute:
                return 'Metrics.AbbreviatedDistPerMin';
            case MetricType.highSpeedRunningPerMinute:
                return 'Metrics.HSRPerMin';
            case MetricType.dynamicStressLoad:
                return 'Metrics.DSL';
            case MetricType.maxHeartRate:
                return 'Metrics.MaxHR';
            case MetricType.averageHeartRate:
                return 'Metrics.AvgHR';
        }
        return metricDetail.name;
    }

    getMetricDisplayTextShortenedAbbreviated(metricDetail: MetricDetail) {
        switch (metricDetail.type) {
            case MetricType.accelerations:
                return 'Metrics.AbbreviatedAccelerations';
            case MetricType.decelerations:
                return 'Metrics.AbbreviatedDecelerations';
            case MetricType.highMetabolicLoadDistance:
                return 'Metrics.ShortenedHighIntensityDistance';
            case MetricType.numberOfSprints:
                return 'Metrics.ShortenedNoOfSprints';
            case MetricType.sprintDistance:
                return 'Metrics.AbbreviatedSprintDistance';
            case MetricType.sprintDistancePerMinute:
                return 'Metrics.ShortenedSprintDistPerMin';
            case MetricType.timeInRedZone:
                return 'Metrics.AbbreviatedTimeInRedZone';
            case MetricType.totalDistance:
                return 'Metrics.AbbreviatedTotalDistance';
            case MetricType.highSpeedRunning:
                return 'Metrics.AbbreviatedHighSpeedRunning';
            case MetricType.calories:
                return 'Metrics.Calories';
            case MetricType.speed:
                return 'Metrics.Speed';
            case MetricType.maxSpeed:
                return 'Metrics.MaxSpeed';
            case MetricType.highMetabolicLoadDistancePerMinute:
                return 'Metrics.ShortenedHighIntDistPerMin';
            case MetricType.distancePerMinute:
                return 'Metrics.ShortenedDistPerMin';
            case MetricType.highSpeedRunningPerMinute:
                return 'Metrics.ShortenedHSRPerMin';
            case MetricType.dynamicStressLoad:
                return 'Metrics.DSL';
            case MetricType.maxHeartRate:
                return 'Metrics.MaxHR';
            case MetricType.averageHeartRate:
                return 'Metrics.AvgHR';
        }
        return metricDetail.name;
    }


    getMetricDisplayTextShortenedAbbreviatedTranslated(metricDetail: MetricDetail) {
        return this.translate.instant(this.getMetricDisplayTextShortenedAbbreviated(metricDetail));
    }


    getMetricDisplayTextShortenedToOneWordTranslated(metricDetail: MetricDetail) {
        return this.translate.instant(this.getMetricDisplayTextShortenedToOneWord(metricDetail));
    }


    getMetricDisplayTextShortenedToOneWord(metricDetail: MetricDetail) {
        switch (metricDetail.type) {
            case MetricType.sprintDistance:
                return 'Metrics.ShortenedToOneWordSprintDistance';
            case MetricType.sprintDistancePerMinute:
                return 'Metrics.ShortenedToOneWordSprintDistancePerMinute';
            case MetricType.timeInRedZone:
                return 'Metrics.ShortenedToOneWordTimeInRedZone';
            case MetricType.totalDistance:
                return 'Metrics.ShortenedToOneWordTotalDistance';
            case MetricType.calories:
                return 'Metrics.ShortenedToOneWordCalories';
            case MetricType.maxSpeed:
                return 'Metrics.ShortenedToOneWordMaxSpeed';
            case MetricType.highMetabolicLoadDistancePerMinute:
                return 'Metrics.ShortenedToOneWordHighIntensityDistancePerMinute';
            case MetricType.distancePerMinute:
                return 'Metrics.ShortenedToOneWordDistancePerMinute';
            case MetricType.highSpeedRunningPerMinute:
                return 'Metrics.ShortenedToOneWordHighSpeedRunningPerMinute';
            case MetricType.maxHeartRate:
                return 'Metrics.ShortenedToOneWordMaxHeartRate';
            case MetricType.averageHeartRate:
                return 'Metrics.ShortenedToOneWordAverageHeartRate';
        }

        return this.getMetricDisplayTextShortenedAbbreviated(metricDetail);
    }


    getDistanceMetricUnitChildUnit(distanceUnit: DistanceUnit) {
        if (distanceUnit === DistanceUnit.Kilometers || distanceUnit === DistanceUnit.Meters) {
            return 'm';
        }
        return 'yd';
    }

    getDefaultAvatarURlFragment() {
        return 'default/genericProfileImage/default.png';
    }

    getDefaultAvatar() {
        return 'https://s3-eu-west-1.amazonaws.com/athlete-series-media-bucket-live-production/default/genericProfileImage/default.png?d=1710764262735';
    }


    removeStackingFromChart(metricChart) {
        metricChart.update({
            plotOptions: {
                column: {
                    stacking: null, grouping: true, minPointWidth: 35, pointPadding: 0.2, maxPointWidth: 35
                },
            }
        });
    }

    shuffleArray(array: any[]): any[] {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }


    addStackingToChart(metricChart, labelsEnabled, comboChart, pageURl, legendGameDayTotalComboEnabled = true) {
        for (const s of metricChart.series) {
            // hide the total bar for gameday combo sessions
            if (s.userOptions.type === 'column' && s.userOptions.gameDayHalf === GameDayHalf.Both && metricChart.series.length > 2) {
                s.hide();
            }
        }

        const totalLabel = (labelsEnabled && legendGameDayTotalComboEnabled);

        setTimeout(() => {
            metricChart.update({
                yAxis: {
                    stackLabels: {
                        enabled: totalLabel, align: 'center',
                    }, reversedStacks: false,
                }, plotOptions: {
                    column: {
                        stacking: 'normal', dataLabels: {
                            verticalAlign: 'bottom', color: 'white', enabled: labelsEnabled,
                        },
                    }, area: {
                        dataLabels: {
                            enabled: labelsEnabled, padding: 5
                        },
                    }, line: {
                        dataLabels: {
                            enabled: labelsEnabled, padding: 5
                        },
                    },
                }
            });
        }, 100);

    }

    layerOrStackCalculator(metricChart, labelsEnabled, combo, legendGameDayTotalComboEnabled) {
        if (combo) {
            const columnSeries = metricChart.series.filter((s) => s.userOptions.type === 'column' && s.visible);

            // check if there is a column as one of the combo charts
            const totalColumnSeriesVisibility = columnSeries.find(s => s.userOptions.gameDayHalf === GameDayHalf.Both && s.visible);

            // if column series update chart to be layered
            if (totalColumnSeriesVisibility) {

                this.updateLayeringChart(metricChart, labelsEnabled);
            } else {
                this.addStackingToChart(metricChart, labelsEnabled, combo, '', legendGameDayTotalComboEnabled);
            }
        }
    }

    updateChartWithLabels(metricChart, labelsEnabled, selectedChartType: SelectedChartTypeEnum, pageURl, chartSeries, legendGameDayTotalComboEnabled = true) {

        metricChart.update({
            yAxis: {
                stackLabels: {
                    style: {
                        color: 'black'
                    },
                }
            }, plotOptions: {
                column: {
                    stacking: selectedChartType === SelectedChartTypeEnum.zones, dataLabels: {
                        enabled: labelsEnabled, padding: 5
                    },
                }, line: {
                    dataLabels: {
                        enabled: labelsEnabled, padding: 5
                    }
                }, area: {
                    dataLabels: {
                        enabled: labelsEnabled, padding: 5
                    }
                }
            },
        });

    }

    updateLayeringChart(metricChart, labelsEnabled) {
        setTimeout(() => {
            metricChart.update({
                plotOptions: {
                    column: {
                        grouping: false, stacking: undefined,
                    },
                }
            });
        }, 100);
    }


    getAnalysisIndexSymbol(metricChart: any, index: number, text: string, borderColor = true, metricLabel = null, gameDayCombo = true, zonal = false, selectedZoneType: SelectedZonesEnum = null) {
        if (this.legendDataArrayHolder[zonal ? 'zonal' + selectedZoneType + ':' + index : index]) {
            return this.legendDataArrayHolder[zonal ? 'zonal' + selectedZoneType + ':' + index : index];
        }

        if (!zonal) {
            return this.getAnalysisIndexSymbolsStandardChart(metricChart, index, text, borderColor, metricLabel, gameDayCombo);
        } else {
            return this.getAnalysisIndexSymbolsZonalChart(metricChart, index);
        }
    }

    checkIfBothHearRateMetricsAndOneTimeInRedZone(metricDetail1: MetricDetail, metricDetail2: MetricDetail) {
        return metricDetail1.unit === MetricUnit.HeartRate && metricDetail2.unit === MetricUnit.HeartRate && (metricDetail2.type === MetricType.timeInRedZone || metricDetail1.type === MetricType.timeInRedZone);
    }

    /**
     * Used for getting the indices for zones so we can loop through them
     *  Usage- Legend for the analysis page generation
     */
    getZoneIndices() {
        return [0, 1, 2, 3, 4];
    }

    getSelectedPlayersForSessionByGroup(players: Player[], currentSelectedGroup: Group) {
        if (currentSelectedGroup && currentSelectedGroup.groupPlayerIds && currentSelectedGroup.groupPlayerIds.length >= 1) {
            const groupPlayerIdsSet = new Set(currentSelectedGroup.groupPlayerIds);

            return players.filter(p => groupPlayerIdsSet.has(p.playerId));
        }
        return [];
    }


    /**
     *  Used to check that the object in its list has at least one item in the array and returns the value else it returns null
     * @param objectWithArrayAttribute
     * @param propertyPath
     */
    safeGetItemInArray(objectWithArrayAttribute, propertyPath) {
        try {
            return propertyPath.split('.').reduce((acc, part) => acc && acc[part], objectWithArrayAttribute) ?? null;
        } catch (error) {
            return null;
        }
    }

    /**
     *  Used to safely get the length of an attributes array
     * @param object
     * @param propertyPath
     */
    safeGetArrayLength(object, propertyPath) {
        try {
            const result = propertyPath.split('.').reduce((acc, part) => acc && acc[part], object);
            return Array.isArray(result) ? result.length : null;
        } catch (error) {
            return null;
        }
    }

    getGamedayBulkSessionState(s: Session) {
        if (s.sessionType === SessionType.GameDay && s.gameDayBulkId) {
            if (!s.sessionOppoTeamName && s.sessionOppoTeamName === '') {
                return GamedayBulkSessionState.GamedayBUlkDetailsNotAdded;
            } else {
                return GamedayBulkSessionState.GamedayBulkDetailsAdded;
            }
        }

        return null;
    }

    getClassNamesCalender(session: Session) {

        // todo add in logic to check session is in future
        if (session.sessionType === SessionType.GameDay) {
            if (session.gameDayBulkId) {
                if (session.sessionOppoTeamName === '' || !session.sessionOppoTeamName) {
                    return ['bulk-gameday-details-not-added'];
                } else {
                    return ['bulk-gameday-details-added'];
                }
            }
        }
        return [];
    }

    getAnalysisIndexSymbolsStandardChart(metricChart: any, index: number, text: string, borderColor = true, metricLabel = null, gameDayCombo = true) {
        let color;
        let html;

        if (metricChart && metricChart.chartSeries && metricChart.chartSeries.length > 0 && metricChart.metricChart && metricChart.metricChart.series) {
            if (metricChart.chartSeries[index]) {
                if (borderColor && metricChart.chartSeries[index].borderColorClassName) {
                    color = metricChart.chartSeries[index].borderColorClassName.substring(2);
                } else if (metricChart.chartSeries[index].colorClassName) {
                    color = metricChart.chartSeries[index].colorClassName.substring(2);
                }
            }

            if (metricChart.metricChart.series[index] && metricChart.metricChart.series[index].symbol !== undefined) {
                switch (metricChart.metricChart.series[index].symbol) {
                    case 'diamond':
                        html = `<div class="diamond-icon ${color} legend-custom-combo-icon"></div>`;
                        break;
                    case 'square':
                        html = `<div class="square-icon ${color} legend-custom-combo-icon"></div>`;
                        break;
                    case 'triangle':
                        html = `<div class="triangle-icon ${color} legend-custom-combo-icon"></div>`;
                        break;
                    case 'triangle-down':
                        html = `<div class="triangle-down-icon ${color} legend-custom-combo-icon"></div>`;
                        break;
                    default:
                        html = `<div class="legend-icon legend-custom-combo-icon ${color}" ></div>`;
                        break;
                }
            } else {
                html = `<div class="legend-icon legend-custom-combo-icon ${color}" ></div>`;
            }

            if (!metricLabel) {
                html += `<div class="legend-name">${this.translate.instant(text)}</div>`;
            } else if (!gameDayCombo) {
                html += `<div class="legend-name">${this.translate.instant(text)}
(${this.translate.instant(this.getMetricDisplayTextAbbreviated(metricLabel))})</div>`;
            } else {
                let className = `legend-name ${gameDayCombo && 'legend-name-combo'}`;
                if (this.deviceWidthLessThan1500()) {
                    className = `legend-name-sm ${gameDayCombo && 'legend-name-combo'}`;
                }

                html += `<div class="${className}">${this.translate.instant(text)}
(${this.translate.instant(this.getMetricDisplayTextAbbreviated(metricLabel))})</div>`;
            }
        }

        this.legendDataArrayHolder[index] = html;
        return html;
    }


    getAnalysisIndexSymbolsZonalChart(metricChart: any, index: number) {
        let html = `<div class="d-flex vertical-align-content zonal-legend-gaps pointer">`;
        let seriesName = '';

        if (metricChart && metricChart.metricChart && metricChart.metricChart.series && metricChart.metricChart.series.length === 5) {
            seriesName = metricChart.metricChart.series[index].name;
            // rgb string so need to set full opacity

            html += `<div><div class="legend-icon legend-custom-combo-icon zone-color-${5 - index}" ></div></div>`;
            html += `<div class="legend-name">${seriesName}</div>`;

            this.legendDataArrayHolder['zonal' + ':' + index] = html;
        }

        html += '</div>';
        return html;
    }


    removeDuplicatePlayers(players: Player[]) {
        const uniqueIds = new Set();
        const uniquePlayers = players.filter(player => {
            if (!uniqueIds.has(player.playerId)) {
                uniqueIds.add(player.playerId);
                return true;
            }
            return false;
        });
        return uniquePlayers;
    }

    add7DaysToTheDate(date: number) {
        const sessionDate = moment(date);

        const newDate = sessionDate.add(7, 'days');
        return newDate.valueOf();
    }

    add365DaysToTheDate(date: number) {
        const sessionDate = moment(date);

        const newDate = sessionDate.add(365, 'days');
        return newDate.valueOf();
    }


    getTimestamp7DaysAgo(timestamp) {
        const nextSessionDateMoment = moment.unix(timestamp);
        const sevenDaysAgoMoment = nextSessionDateMoment.subtract(7, 'days');
        return sevenDaysAgoMoment.unix();
    }

    areSameTime = (timestamp1: moment.Moment, timestamp2: moment.Moment) => {
        return timestamp1.isSame(timestamp2, 'second');
    }

    checkTimestampIsAfterToday(currentTimestamp) {
        return moment(currentTimestamp).isAfter(moment.now());
    }

    getSessionTitleForSession = (session: Session) => {
        if (session.gameDayBulkId && session.sessionTitle === '') {
            return this.translate.instant('Resources.BulkGameday');
        }

        return session.sessionTitle;
    }

    showWarningOnlyEditingCurrentPracticeSessionModel = async () => {
        const modal = await this.modalController.create({
            component: OnlyEditingCurrentSessionWarningComponent, cssClass: 'modal-structure-5', backdropDismiss: true,
        });

        await modal.present();

        return modal.onDidDismiss().then((dismiss) => {
            return;
        });
    }

    async editSessionBySession(session: Session) {
        if (this.checkIfEditPracticeModalShouldBeShown(session.practiceSessionScheduleId, session.sessionStartTime, session.sessionType === SessionType.GameDay)) {
            await this.showWarningOnlyEditingCurrentPracticeSessionModel();
        }

        const modal = await this.modalController.create({
            component: AddSessionComponent, cssClass: 'add-session-modal', backdropDismiss: true, componentProps: {
                modalType: 2, sessionId: session.sessionId, existingSession: session
            }
        });

        modal.onDidDismiss()
            .then((dismiss) => {
            });

        return await modal.present();
    }

    /**
     *  Used to check object is not empty
     *  Usage -
     *  Checking the zonal intervals that the set squad has e.g. squad may not have set zonal intervals
     * @param obj
     */
    isObjectEmpty(obj: any): boolean {
        return Object.keys(obj).length === 0;
    }


    /**
     * Used to reset speed zone object
     * Usage:
     *  - User has not set speed zones intervals yet
     *  - user want to reset the speed zone interval values to default
     */
    getResetSpeedZonesObject = () => {
        return {standing: 0, walking: 2, jogging: 4, running: 5.5, sprinting: 6};
    }


    getResetHeartRateZonesObject = () => {
        return {warmUp: 80, fatBurn: 90, cardio: 100, peak: 110, redZone: 120};
    }


    /**
     *  Used for getting the symbols in the settings page and displaying the users current zone values
     *
     *  reset passed in if want to reset the users custom values
     * @param speedZone
     * @param speedUnit
     * @param reset
     */
    getSpeedZoneSettingsSymbolsAndNames = (speedZone: SpeedZoneValues, speedUnit: SpeedUnit, reset = false): ZonalSettingsSymbols[] => {
        if (this.isObjectEmpty(speedZone) || reset) {
            speedZone = this.getResetSpeedZonesObject();
        }

        return [{
            attributeName: 'standing',
            translatedName: this.translate.instant('Resources.Standing'),
            color: getComputedStyle(document.documentElement).getPropertyValue('--zone-color-1-border'),
            value: speedZone.standing,
            displayValue: this.convertSpeed(speedZone.standing, speedUnit)
        }, {
            attributeName: 'walking',
            translatedName: this.translate.instant('Resources.Walking'),
            color: getComputedStyle(document.documentElement).getPropertyValue('--zone-color-2-border'),
            value: speedZone.walking,
            displayValue: this.convertSpeed(speedZone.walking, speedUnit)
        }, {
            attributeName: 'jogging',
            translatedName: this.translate.instant('Resources.Jogging'),
            color: getComputedStyle(document.documentElement).getPropertyValue('--zone-color-3-border'),
            value: speedZone.jogging,
            displayValue: this.convertSpeed(speedZone.jogging, speedUnit)
        }, {
            attributeName: 'running',
            translatedName: this.translate.instant('Resources.Running'),
            color: getComputedStyle(document.documentElement).getPropertyValue('--zone-color-4-border'),
            value: speedZone.jogging,
            displayValue: this.convertSpeed(speedZone.jogging, speedUnit)
        }, {
            attributeName: 'sprinting',
            translatedName: this.translate.instant('Resources.Sprinting'),
            color: getComputedStyle(document.documentElement).getPropertyValue('--zone-color-5-border'),
            value: speedZone.sprinting,
            displayValue: this.convertSpeed(speedZone.sprinting, speedUnit)
        }];
    }


    getHeartRateZoneSettingsSymbolAndNames = (heartRateZone: HeartRateZoneValues, reset = false) => {
        if (this.isObjectEmpty(heartRateZone) || reset) {
            heartRateZone = this.getResetHeartRateZonesObject();
        }

        return [{
            attributeName: 'warmUp',
            translatedName: this.translate.instant('Resources.WarmUp'),
            color: getComputedStyle(document.documentElement).getPropertyValue('--zone-color-1-border'),
            value: heartRateZone.warmUp,
            displayValue: heartRateZone.warmUp
        }, {
            attributeName: 'fatBurn',
            translatedName: this.translate.instant('Resources.FatBurn'),
            color: getComputedStyle(document.documentElement).getPropertyValue('--zone-color-2-border'),
            value: heartRateZone.fatBurn,
            displayValue: heartRateZone.fatBurn
        }, {
            attributeName: 'cardio',
            translatedName: this.translate.instant('Resources.Cardio'),
            color: getComputedStyle(document.documentElement).getPropertyValue('--zone-color-3-border'),
            value: heartRateZone.cardio,
            displayValue: heartRateZone.cardio
        }, {
            attributeName: 'peak',
            translatedName: this.translate.instant('Resources.Peak'),
            color: getComputedStyle(document.documentElement).getPropertyValue('--zone-color-4-border'),
            value: heartRateZone.peak,
            displayValue: heartRateZone.peak
        }, {
            attributeName: 'redZone',
            translatedName: this.translate.instant('Resources.RedZone'),
            color: getComputedStyle(document.documentElement).getPropertyValue('--zone-color-5-border'),
            value: heartRateZone.redZone,
            displayValue: heartRateZone.redZone
        }];


    }

    checkIfEditPracticeModalShouldBeShown = (practiceSessionId, timestamp, gameday = false) => {
        return this.checkTimestampIsAfterToday(moment(timestamp).valueOf()) && !gameday && practiceSessionId;
    }

    getDeepCopyOfArray = (array: any[]) => {
        if (!Array.isArray(array)) {
            throw new Error('Input is not an array');
        }

        return array.map(item => this.isObject(item) ? this.deepCopyObject(item) : item);
    }

    isObject(item: any): boolean {
        return item && typeof item === 'object' && !Array.isArray(item);
    }

    deepCopyObject(obj: any): any {
        const copiedObj: any = {};
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                copiedObj[key] = this.isObject(obj[key]) ? this.deepCopyObject(obj[key]) : obj[key];
            }
        }
        return copiedObj;
    }


    getSpeedZoneAttributeTranslated = (attributeName) => {
        switch (attributeName) {
            case 'sprinting':
                return this.translate.instant('Resources.Sprinting');
            case 'running':
                return this.translate.instant('Resources.Running');
            case 'jogging':
                return this.translate.instant('Resources.Jogging');
            case 'walking':
                return this.translate.instant('Resources.Walking');
            case 'standing':
                return this.translate.instant('Resources.Standing');
        }
    }


    getSeriesZonalSprint() {
        const series = [];
        const seriesLength = 5;
        for (let i = 0; i < seriesLength; i++) {
            let name;
            switch (i) {
                case 0:
                    name = this.translate.instant('Resources.Sprinting');
                    break;
                case 1:
                    name = this.translate.instant('Resources.Running');
                    break;
                case 2:
                    name = this.translate.instant('Resources.Jogging');
                    break;
                case 3:
                    name = this.translate.instant('Resources.Walking');
                    break;
                case 4:
                    name = this.translate.instant('Resources.Standing');
                    break;
            }

            series.push({
                name, data: []
            });
        }
        return series;
    }


    getSeriesZonalHeartRate() {
        const series = [];
        const seriesLength = 5;
        for (let i = 0; i < seriesLength; i++) {
            let name;
            switch (i) {
                case 0:
                    name = this.translate.instant('Resources.RedZone');
                    break;
                case 1:
                    name = this.translate.instant('Resources.Peak');
                    break;
                case 2:
                    name = this.translate.instant('Resources.Cardio');
                    break;
                case 3:
                    name = this.translate.instant('Resources.FatBurn');
                    break;
                case 4:
                    name = this.translate.instant('Resources.WarmUp');
                    break;
            }

            series.push({
                name, data: []
            });
        }
        return series;
    }


    /**
     * Get zonal series data for creating zonal charts
     */
    getZonalChartInitialSeries(selectedZoneTypeEnum: SelectedZonesEnum, pdf = false): {
        visible?: boolean, borderColor?: string, color?: string, name: string, data: number []
    } [] {

        const series = selectedZoneTypeEnum === SelectedZonesEnum.SPEED ? this.getSeriesZonalSprint() : this.getSeriesZonalHeartRate();

        for (const [index, s] of series.entries()) {
            s.visible = true;
            const color = pdf && 5 - (index) !== 1 ? `--zone-color-${5 - (index)}-border` : `--zone-color-${5 - (index)}`;
            const borderColor = `--zone-color-${5 - (index)}-border`;
            s.color = getComputedStyle(document.documentElement).getPropertyValue(color);
            s.borderColor = getComputedStyle(document.documentElement).getPropertyValue(borderColor);
        }

        return series;
    }


    /**
     *  To get series and categories for the zonal data based on player
     * @param chartSeriesData
     * @param selectedPlayers
     * @param selectedZoneType
     * @param pdf
     */
    getCategoriesAndSeriesForZonesDataByPlayer(chartSeriesData: any[], selectedPlayers: Player[], selectedZoneType: SelectedZonesEnum = SelectedZonesEnum.SPEED, pdf = false) {
        const selectedPlayerIdsSet = new Set(selectedPlayers.map(player => player.playerId));

        const categories = chartSeriesData
            .filter(player => selectedPlayerIdsSet.has(player.name))
            .map(player => player.name);

        // length of zones
        const series = this.getZonalChartInitialSeries(selectedZoneType, pdf);

        // // if its heart rate zone we need to calculate the total time of all sessions to return the data based on the percentage of the session and the heart rate

        chartSeriesData.forEach(player => {
            player.data.forEach((value, index) => {
                if (selectedPlayerIdsSet.has(player.name) && series[index] && series[index].data) {
                    if (selectedZoneType === SelectedZonesEnum.HEARTRATE) {
                        series[index].data.push(Math.round((value / player.totalSessionsElapsedTime) * 100));
                    } else {
                        series[index].data.push(value);
                    }
                }
            });
        });

        return {categories, series};
    }


    /**
     *  To get series and categories for the zonal data based on Session
     *  for colum chart
     * @param chartSeriesData
     * @param selectedZoneEnum
     */
    getCategoriesAndSeriesForZonesDataBySession(chartSeriesData: any[], selectedZoneEnum: SelectedZonesEnum) {
        const categories = chartSeriesData.map(session => session.name);
        // length of zones
        const series = this.getZonalChartInitialSeries(selectedZoneEnum);

        chartSeriesData.forEach(session => {
            session.data.forEach((value, index) => {
                if (selectedZoneEnum === SelectedZonesEnum.HEARTRATE) {
                    series[index].data.push(Math.round((value / session.totalSessionsElapsedTime) * 100));
                } else {
                    series[index].data.push(value);
                }
            });
        });

        return {categories, series};
    }


    getDistanceZonesPropertiesArray(): string[] {
        return ['sprinting', 'running', 'jogging', 'walking', 'standing'];
    }

    getHeartRateZonesPropertiesArray() {
        return ['redZone', 'peak', 'cardio', 'fatBurn', 'warmUp'];
    }

    getGameHalfPropertiesArray() {
        return ['total', 'firstHalf', 'secondHalf'];
    }

    /**
     * Return the translated zone name based on zone type
     */
    getTranslatedZoneNameByZoneType(zone: SelectedZonesEnum) {
        switch (zone) {
            case SelectedZonesEnum.SPEED:
                return this.translate.instant('Resources.SpeedZones');
            case SelectedZonesEnum.HEARTRATE:
                return this.translate.instant('Resources.HeartRateZones');
        }
    }

    getZonalHalfAttributeByGamedayHalfEnum(gameDayHalf: GameDayHalf) {
        switch (gameDayHalf) {
            case GameDayHalf.Both:
                return 'total';
            case GameDayHalf.First:
                return 'firstHalf';
            case GameDayHalf.Second:
                return 'secondHalf';
        }
    }

    calculateTheTotalSecondsRuntimeOfSessions(selectedSessions: Session[]) {
        let total = 0;

        for (const session of selectedSessions) {
            const startTime = session.sessionStartTime;
            const endTime = session.sessionEndTime;
            const timeDifference = endTime - startTime;
            const timeDifferenceInSeconds = timeDifference / 1000;
            total += timeDifferenceInSeconds;
        }
        return total;
    }

    getSelectedZoneAttributeNameBySelectedZoneEnum(selectedZoneEnum: SelectedZonesEnum) {
        switch (selectedZoneEnum) {
            case SelectedZonesEnum.SPEED:
                return 'speedZone';
            case SelectedZonesEnum.HEARTRATE:
                return 'heartRateZone';
        }
    }

    getHeartRateZoneAttributeTranslated(attribute: string) {
        switch (attribute) {
            case 'warmUp':
                return this.translate.instant('Resources.WarmUp');
            case 'fatBurn':
                return this.translate.instant('Resources.FatBurn');
            case 'cardio':
                return this.translate.instant('Resources.Cardio');
            case 'peak':
                return this.translate.instant('Resources.Peak');
            case 'redZone':
                return this.translate.instant('Resources.RedZone');
        }
    }

    convertSecondsToMinuteFormat(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');
        return `${formattedMinutes}:${formattedSeconds}`;
    }

    getSortNameByType(sortType: number) {
        switch (sortType) {
            case 0:
                return 'A_Z';
            case 1:
                return 'Z_A';
            case 2:
                return '1-9';
            case 3:
                return '9-1';
            case 4:
                return 'N_O';
            case 5:
                return 'O_N';
        }
    }

    getInitialsByMetricType(type: MetricType) {
        switch (type) {
            case MetricType.totalDistance:
                return 'TD';
            case MetricType.maxSpeed:
                return 'MS';
            case MetricType.highSpeedRunning:
                return 'HSR';
            case MetricType.distancePerMinute:
                return 'DPM';
            case MetricType.avgPace:
                return 'AP';
            case MetricType.speed:
                return 'CS';
            case MetricType.distanceZ5:
                return 'DZ5';
            case MetricType.distanceZ6:
                return 'DZ6';
            case MetricType.highSpeedRunningPerMinute:
                return 'HSRPM';
            case MetricType.highMetabolicLoadDistance:
                return 'HMLD';
            case MetricType.highMetabolicLoadDistancePerMinute:
                return 'HMLDPM';
            case MetricType.numberOfSprints:
                return 'NOS';
            case MetricType.sprintDistance:
                return 'SD';
            case MetricType.speedIntensity:
                return 'SI';
            case MetricType.accelerations:
                return 'ACC';
            case MetricType.decelerations:
                return 'DEC';
            case MetricType.averageMetabolicPower:
                return 'AMP';
            case MetricType.equivalentMetabolicDistance:
                return 'EMD';
            case MetricType.heartRate:
                return 'HR';
            case MetricType.maxHeartRate:
                return 'MHR';
            case MetricType.heartRateExertion:
                return 'HRE';
            case MetricType.averageHeartRate:
                return 'AHR';
            case MetricType.timeInRedZone:
                return 'TRZ';
            case MetricType.impacts:
                return 'IMP';
            case MetricType.dynamicStressLoad:
                return 'DSL';
            case MetricType.entryZ5:
                return 'EZ5';
            case MetricType.entryZ6:
                return 'EZ6';
            case MetricType.fatigueIndex:
                return 'FI';
            case MetricType.stepBalance:
                return 'SB';
            case MetricType.calories:
                return 'CAL';
            case MetricType.count:
                return 'CT';
            case MetricType.sprintDistancePerMinute:
                return 'SDPM';
            default:
                return '';
        }
    }

    getDifferenceInWeeksBetweenTwoDates(nextSessionToAddDate, scheduleSessionEndDate) {
        const startDate = moment(nextSessionToAddDate);
        const endDate = moment(scheduleSessionEndDate);

        const differenceInWeeks = endDate.diff(startDate, 'weeks', true);
        return Math.round(differenceInWeeks);
    }

    isSessionBeforeOrAfterCurrentDateToLetter(sessionStartTime) {
        const sessionTime = moment(sessionStartTime);

        const currentTime = moment();

        if (sessionTime.isBefore(currentTime)) {
            return 'B';
        } else if (sessionTime.isAfter(currentTime)) {
            return 'A';
        } else {
            return 'S';
        }
    }

    getCurrentActivePageEnumByCurrentActivePage() {
        if (this.router.url.includes('squad-period')) {
            return ActivePageEnum.ANALYSIS_SQUAD_PERIOD;
        } else if (this.router.url.includes('squad')) {
            return ActivePageEnum.ANALYSIS_SQUAD;
        } else if (this.router.url.includes('player-comparison')) {
            return ActivePageEnum.ANALYSIS_SQUAD_PLAYER_COMPARISON;
        } else if (this.router.url.includes('analysis/player')) {
            return ActivePageEnum.ANALYSIS_PLAYER;
        }
    }


    getAnalyticsAnalysisChildPageValueByCurrentActiveTab() {
        const activePage = this.getCurrentActivePageEnumByCurrentActivePage();
        switch (activePage) {
            case ActivePageEnum.ANALYSIS_PLAYER:
                return 'Pr_s';
            case ActivePageEnum.ANALYSIS_SQUAD:
                return 'Sd_s';
            case ActivePageEnum.ANALYSIS_SQUAD_PERIOD:
                return 'SP_d';
            case ActivePageEnum.ANALYSIS_SQUAD_PLAYER_COMPARISON:
                return 'PCn';
        }
    }

    getAbbreviatedComparisonSelectionByComparisonSelected = (comparisonSelected: ComparisonSelection) => {
        switch (comparisonSelected) {
            case ComparisonSelection.AcademyPlayers:
                return 'APs';
            case ComparisonSelection.PersonalBest:
                return 'PBs';
            case ComparisonSelection.ProPlayers:
                return 'Pro';
            case ComparisonSelection.SquadPlayers:
                return 'Pr';
        }
    }

    getAnalysisSelectedMapNameByActiveMapType(activeMapType: number) {
        switch (activeMapType) {
            case 0:
                return 'Ht';
            case 1:
                return 'St';
            case 2:
                return 'Zs';
        }
    }
}
