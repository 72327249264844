import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage';
import {Regions} from '../../enums/regions.enum';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {
    favouritePitches: string[];

    constructor(private storage: Storage) {
        storage.create().then();
        this.getFavouritePitches();
    }

    // Logic to save favourite pitches to local storage

    getFavouritePitches() {
        this.storage.get('favPitches').then((pitches) => {
            if (Array.isArray(pitches) && pitches.length > 0) {
                this.favouritePitches = pitches;
            }
        });
    }

    saveFavouritePitches() {
        this.storage.set('favPitches', this.favouritePitches).then();
    }

    setFavouritePitch(pitchId: string) {
        if (!this.favouritePitches) {
            this.favouritePitches = [];
        }

        this.favouritePitches.push(pitchId);
        this.saveFavouritePitches();
    }

    removeFavouritePitch(pitchId: string) {
        if (Array.isArray(this.favouritePitches) && this.favouritePitches.length > 0) {
            if (this.favouritePitches.findIndex(pId => pId === pitchId) !== -1) {
                this.favouritePitches = this.favouritePitches.filter(pId => pId !== pitchId);
                this.saveFavouritePitches();
            }
        }
    }

    getFirstTimeFlag(usedId: string): Promise<boolean> {
        return new Promise((resolve) => {
            this.storage.get(usedId).then((val) => {
                if (!val) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            });
        });
    }

    setFirstTimeFlag(usedId: string) {
        this.storage.set(usedId, true).then();
    }

    getUsersRegion(): Promise<any> {
        return new Promise((resolve) => {
            this.storage.get('region').then((val) => {
                resolve(val);
            });
        });
    }

    setUsersRegion(region: Regions) {
        this.storage.set('region', region).then();
    }
}
