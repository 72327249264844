import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';

import {Session} from '../../interfaces/session/session';
import {ApiService} from '../api/api.service';
import {UpdatedTPData} from 'src/app/interfaces/session/updatedTPData';
import {PlayerBenchmarks} from '../../interfaces/player-benchmarks';
import {BenchmarkPros} from '../../interfaces/benchmark-pros';
import {PlayerMetricData} from '../../interfaces/playerMetricData';
import {BreakdownValues} from '../../interfaces/breakdown-values';
import {PlayerPositionData} from '../../interfaces/session/positionData';

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    sessionPath = '/session-management';
    benchMarkPath = '/benchmark/';

    constructor(private apiService: ApiService) {
    }

    public createSession(session: Session): Observable<Session> {
        return this.apiService.post(this.sessionPath, session);
    }

    public getSessionsByDate(squadId, startDate, endDate): Observable<Session[]> {
        return this.apiService.post(this.sessionPath + '/coach/get', {squadId, startDate, endDate});
    }

    public getUpcomingSessions(squadId: string): Observable<Session[]> {
        return this.apiService.post(this.sessionPath + '/coach/upcoming-sessions/get', {squadId});
    }

    public getCompleteSession(sessionId: string): Observable<Session> {
        return this.apiService.get(this.sessionPath + `/get/${sessionId}`);
    }

    public getSquadMetricsIndividual(sessionId: string): Observable<PlayerMetricData[]> {
        return this.apiService.get(this.sessionPath + `/getSquadMetricsIndividual/${sessionId}`);
    }

    public getSquadMetricsMultiple(sessionIds: string[], playerIds: string[]): Observable<PlayerMetricData[]> {
        return this.apiService.post(this.sessionPath + `/getSquadMetricsMultiple`, {sessionIds, playerIds});
    }

    public getSquadPeriodData(sessionIds: string[]): Observable<any> {
        return this.apiService.post(this.sessionPath + `/getSquadPeriodData`, {sessionIds});
    }

    public getFiveMinuteBreakdown(sessionId: string, playerId: string): Observable<BreakdownValues> {
        return this.apiService.post(this.sessionPath + `/getFiveMinuteBreakdown`, {sessionId: sessionId, playerId: playerId});
    }

    public getPlayerPositionalData(sessionId: string, playerId: string): Observable<PlayerPositionData> {
        return this.apiService.post(this.sessionPath + `/getPlayerPositionalData`, {sessionId: sessionId, playerId: playerId});
    }

    public getIndividualPeriodData(sessionIds: string[], playerId: string): Observable<PlayerMetricData[]> {
        return this.apiService.post(this.sessionPath + `/getIndividualPeriod/${playerId}`, {sessionIds});
    }

    public updateSession(session: Session): Observable<Session> {
        return this.apiService.put(this.sessionPath + '/put', session);
    }

    public deleteSession(sessionId: string): Observable<Session> {
        return this.apiService.delete(this.sessionPath + '/delete/' + sessionId);
    }

    public getBenchmarkPlayers(benchmarkGroupIsPro: boolean): Observable<BenchmarkPros[]> {
        return this.apiService.post(this.benchMarkPath + 'list', {benchmarkGroupIsPro});
    }

    public mergeSession(sessionIdToBeMerged, arrayOfUnscheduledSessionsIds): Observable<Session> {
        const body = [...arrayOfUnscheduledSessionsIds];

        return this.apiService.post(this.sessionPath + '/merge/' + sessionIdToBeMerged, body);
    }

    public getTopPerformances(usersIds?: string[], benchmarkPlayers?: { groupId: string, position: string }[]): Observable<PlayerBenchmarks[]> {
        if (usersIds && !benchmarkPlayers) {
            return this.apiService.post(this.benchMarkPath + 'comparison', {usersIds: [...usersIds]});
        }

        if (!usersIds && benchmarkPlayers) {
            return this.apiService.post(this.benchMarkPath + 'comparison', {benchmarkPlayers: [...benchmarkPlayers]});
        }

        if (usersIds && benchmarkPlayers) {
            return this.apiService.post(this.benchMarkPath + 'comparison', {
                usersIds: [...usersIds],
                benchmarkPlayers: [...benchmarkPlayers]
            });
        }
    }

    public updateSessionCoachMetrics(playerArrays: UpdatedTPData[]): Observable<any> {
        return this.apiService.post(this.sessionPath + '/metrics/coach', playerArrays);
    }

    public deleteAllBulkGameDaySessionsById(bulkGameDayId){
        return this.apiService.post(this.sessionPath + `/delete/bulk/gameday/${bulkGameDayId}`, {});
    }
}
