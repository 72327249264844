import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Platform } from '@ionic/angular';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class FCMTokenService {

  constructor(
    private platform: Platform,
    private apiService: ApiService
  ) { }

  public updateUserToken(newToken: string): Observable<any> {
    let currentPlatform;

    if (this.platform.is('android')) {
      currentPlatform = 'Android';
    }
    if (this.platform.is('ios')) {
      currentPlatform = 'iOS';
    }

    const body = {
      token: newToken,
      platform: currentPlatform
    };

    return this.apiService.post('/coach/' + 'push-token', body);
  }
}
