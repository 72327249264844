import {Injectable} from '@angular/core';

import {DataStoreService} from '../data-store/data-store.service';
import {LocalisationService} from '../localisation/localisation.service';
import {HelperService} from '../helper-service/helper.service';
import {Sports} from '../../enums/sports.enum';
import {AngularFireAnalytics} from '@angular/fire/compat/analytics';
import {Platform} from '@ionic/angular';
import {LocalStorageService} from '../local-storage/local-storage.service';
import {SessionType} from '../../enums/session-type.enum';
import {ResultType} from '../../enums/result-type.enum';
import {ReportType} from '../../enums/report-type.enum';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {
    public speedUnitValue = 0;
    public distanceUnitValue = 0;

    constructor(public dataStoreService: DataStoreService, private angularFireAnalytics: AngularFireAnalytics, public localisationService: LocalisationService, private helperService: HelperService, private platform: Platform, private localStorageService: LocalStorageService) {
    }

    totalNumberOfSquads() {
        if (Array.isArray(this.dataStoreService.squads) && this.dataStoreService.squads.length === 1) {
            this.angularFireAnalytics.logEvent('coach_has_one_squad', {coach_id: this.dataStoreService.user.coachId});
        } else if (Array.isArray(this.dataStoreService.squads) && this.dataStoreService.squads.length === 2) {
            this.angularFireAnalytics.logEvent('coach_has_two_squads', {coach_id: this.dataStoreService.user.coachId});
        } else if (Array.isArray(this.dataStoreService.squads) && this.dataStoreService.squads.length === 3) {
            this.angularFireAnalytics.logEvent('coach_has_three_squads', {coach_id: this.dataStoreService.user.coachId});
        }
    }

    selectedMetricChanged(user, metricUnit) {
        // if (metricUnit === 'speedUnit') {
        //     this.angularFireAnalytics.logEvent('speed_unit_changed', {
        //         new_speed_unit: this.helperService.getSpeedMetricUnit(user.coachPreferences.metricUnits.speedUnit),
        //         previous_speed_unit: this.helperService.getSpeedMetricUnit(this.speedUnitValue),
        //     });
        //     this.speedUnitValue = user.coachPreferences.metricUnits.speedUnit;
        //
        // } else if (metricUnit === 'distanceUnit') {
        //     this.angularFireAnalytics.logEvent('distance_unit_changed', {
        //         new_dist_unit: this.helperService.getDistanceMetricUnit(user.coachPreferences.metricUnits.distanceUnit),
        //         previous_dist_unit: this.helperService.getDistanceMetricUnit(this.distanceUnitValue),
        //     });
        //     this.distanceUnitValue = user.coachPreferences.metricUnits.distanceUnit;
        // }
    }

    logUserRegion(coachEmail: string) {
        // switch (this.localisationService.getUserRegion(this.localisationService.currentUserRegion)) {
        //     case 'Africa':
        //         return this.angularFireAnalytics.logEvent('user_region_africa', {coach_email: coachEmail});
        //     case 'Asia':
        //         return this.angularFireAnalytics.logEvent('user_region_asia', {coach_email: coachEmail});
        //     case 'Europe':
        //         return this.angularFireAnalytics.logEvent('user_region_europe', {coach_email: coachEmail});
        //     case 'NorthAmerica':
        //         return this.angularFireAnalytics.logEvent('user_region_north_america', {coach_email: coachEmail});
        //     case 'Oceania':
        //         return this.angularFireAnalytics.logEvent('user_region_oceania', {coach_email: coachEmail});
        //     case 'SouthAmerica':
        //         return this.angularFireAnalytics.logEvent('user_region_south_america', {coach_email: coachEmail});
        // }
    }

    logPreviousRegion(coachEmail: string) {
        // this.angularFireAnalytics.logEvent('user_update_region', {
        //     coach_id: this.dataStoreService.user.coachId,
        //     previous_user_region: this.localisationService.getUserRegion(this.localisationService.currentUserRegion)
        // });
    }

    updateUserRegion() {
        // switch (this.localisationService.getUserRegion(this.localisationService.currentUserRegion)) {
        //     case 'Africa':
        //         return this.angularFireAnalytics.logEvent('new_user_region_africa', {coach_id: this.dataStoreService.user.coachId});
        //     case 'Asia':
        //         return this.angularFireAnalytics.logEvent('new_user_region_asia', {coach_id: this.dataStoreService.user.coachId});
        //     case 'Europe':
        //         return this.angularFireAnalytics.logEvent('new_user_region_europe', {coach_id: this.dataStoreService.user.coachId});
        //     case 'NorthAmerica':
        //         return this.angularFireAnalytics.logEvent('new_user_region_north_america', {coach_id: this.dataStoreService.user.coachId});
        //     case 'Oceania':
        //         return this.angularFireAnalytics.logEvent('new_user_region_oceania', {coach_id: this.dataStoreService.user.coachId});
        //     case 'SouthAmerica':
        //         return this.angularFireAnalytics.logEvent('new_user_region_south_america', {coach_id: this.dataStoreService.user.coachId});
        // }
    }

    addNewSquad(chosenSport: Sports) {
        const user = this.dataStoreService.user;
        const squadsLength = this.dataStoreService.squads ? this.dataStoreService.squads.length : undefined;

        if (user && user.coachId && squadsLength !== undefined && user.activeSquadId) {
            this.angularFireAnalytics.logEvent('ANS', {
                coach_id: user.coachId,
                current_num_of_squads: squadsLength,
                squad_id: user.activeSquadId,
                chosen_sport: this.getSportName(chosenSport)
            });
        }
    }

    navigateToAnalysisScreen(navigationFromScreenName: string) {
        // when navigating when need to trigger that they are viewing the squad page
        this.navigateToAnalysisSubPage('Sd');

        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`NTA_${navigationFromScreenName}`, {
                coach_id: user.coachId,
                squad_id: user.activeSquadId,
                platform: this.getPlatformName(),
                session_id: this.helperService.safeGetItemInArray(this.dataStoreService, 'selectedSessions.0.sessionId'),
                selected_sessions: this.helperService.safeGetArrayLength(this.dataStoreService, 'selectedSessions')
            });
        }
    }

    downloadPdf(message, quality, numOfPages) {
        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`PDF_D_${this.getPlatformName()}_${quality}_${message}`, {
                coach_id: user.coachId,
                squad_id: user.activeSquadId,
                platform: this.getPlatformName(),
                pdf_pages: numOfPages,
                pdf_quality: quality
            });
        }
    }

    reportGenerated(reportType: string, selectedReportTemplate, sortType = null, notes = false) {
        const user = this.dataStoreService.user;

        const templateName = this.helperService.getEmptyGuid() === selectedReportTemplate ? 'A' : 'C';
        const notesValue = notes === true ? 'N' : '';
        const sort = sortType ? `${this.helperService.getSortNameByType(sortType)}` : '';

        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`${reportType}_G_${this.getPlatformName()}_${templateName}_${sort}_${notesValue}`, {
                coach_id: user.coachId, squad_id: user.activeSquadId, platform: this.getPlatformName(),
            });
        }
    }

    getPlatformName = () => {
        if (this.platform.is('ios')) {
            return 'I';
        } else if (this.platform.is('android')) {
            return 'A';
        } else {
            return 'W';
        }
    }

    navigateToScreen = (screenName: string) => {
        const user = this.dataStoreService.user;

        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`NTS_${screenName}`, {
                coach_id: user.coachId, squad_id: user.activeSquadId, platform: this.getPlatformName(),
            });
        }

    }

    updateChosenMetric(chartName, chosenMetric) {
        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`SM_${chosenMetric}`, {
                coach_id: user.coachId, squad_id: user.activeSquadId, platform: this.getPlatformName(),
                chart_name: chartName
            });
        }
    }

    getSportName(sport: Sports) {
        switch (sport) {
            case Sports.Soccer:
                return 'Soccer';
            case Sports.Rugby:
                return 'Rugby';
            case Sports.Gaa:
                return 'Gaa';
            case Sports.Hockey:
                return 'Hockey';
        }
    }

    navigateToAnalysisSubPage(analysisChildPage: string) {
        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`ASP_${analysisChildPage}`, {
                coach_id: user.coachId, squad_id: user.activeSquadId, platform: this.getPlatformName(),
            });
        }
    }

    updateSortForChart(chartName, sortType: number) {
        const user = this.dataStoreService.user;
        const sort = this.helperService.getSortNameByType(sortType);
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`St_${sort}`, {
                coach_id: user.coachId, squad_id: user.activeSquadId, platform: this.getPlatformName(),
                chart_name: chartName
            });
        }
    }

    dataLabels(chartName: string, dataLabelsEnabled: boolean) {
        const toggleValue = dataLabelsEnabled ? 'on' : 'off';
        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`DLT_${toggleValue}`, {
                coach_id: user.coachId, squad_id: user.activeSquadId, platform: this.getPlatformName(),
                chart_name: chartName
            });
        }
    }

    averageLine(chartName: string, dataLabelsEnabled: boolean) {
        const toggleValue = dataLabelsEnabled ? 'on' : 'off';
        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`ALT_${toggleValue}`, {
                coach_id: user.coachId, squad_id: user.activeSquadId, platform: this.getPlatformName(),
                chart_name: chartName
            });
        }
    }

    toggleChartSeries(chartName: string, chartLegendName: string, chartSeriesToggledOn: boolean) {
        const toggleValue = chartSeriesToggledOn ? 'on' : 'off';
        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`CST_${chartLegendName}`, {
                coach_id: user.coachId, squad_id: user.activeSquadId, platform: this.getPlatformName(),
                chart_name: chartName, toggle_value: toggleValue
            });
        }
    }

    sessionAdded(sessionTypeName: string, relativeTime = 'A', numberOfSessionsAdded: number = 1, recurringSessionWeeks = 1) {
        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`SA_${sessionTypeName}_${relativeTime}`, {
                coach_id: user.coachId,
                squad_id: user.activeSquadId,
                platform: this.getPlatformName(),
                selected_sessions: numberOfSessionsAdded,
                recurring_session_weeks: recurringSessionWeeks
            });
        }
    }

    sessionEdited = (sessionTypeName: string, relativeTime = 'A') => {
        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`SE_${sessionTypeName}_${relativeTime}`, {
                coach_id: user.coachId, squad_id: user.activeSquadId, platform: this.getPlatformName(),
            });
        }
    }

    sessionDeleted = (sessionTypeName: string, relativeTime = 'A') => {
        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`SD_${sessionTypeName}_${relativeTime}`, {
                coach_id: user.coachId, squad_id: user.activeSquadId, platform: this.getPlatformName(),
            });
        }
    }

    mergeSession = (numOfPlayersInMerge, sessionTypeName) => {
        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`MS_${sessionTypeName}`, {
                coach_id: user.coachId,
                squad_id: user.activeSquadId,
                platform: this.getPlatformName(),
                players_selected: numOfPlayersInMerge,
            });
        }
    }

    selectedDeselectAllPlayers(chartName: string, on: boolean) {
        const toggleValue = on ? 'on' : 'off';
        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`SDAP_${toggleValue}`, {
                coach_id: user.coachId, squad_id: user.activeSquadId, platform: this.getPlatformName(),
            });
        }
    }

    selectDeselectAPlayer(chartName: string, on: boolean) {
        const toggleValue = on ? 'on' : 'off';
        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`SDP_${toggleValue}`, {
                coach_id: user.coachId, squad_id: user.activeSquadId, platform: this.getPlatformName(),
                chart_name: chartName
            });
        }
    }

    searchBarUsed(chartName: string, searchBarType: string = 'PS') {
        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`SBU_${searchBarType}`, {
                coach_id: user.coachId, squad_id: user.activeSquadId, platform: this.getPlatformName(),
                chart_name: chartName
            });
        }
    }

    updateGroup(updateGroupType: string, chartName: string, playersInGroup: number, activeSquadsNumberOfGroups: number) {
        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`G_${updateGroupType}`, {
                coach_id: user.coachId,
                squad_id: user.activeSquadId,
                platform: this.getPlatformName(),
                number_of_groups_in_squad: activeSquadsNumberOfGroups,
                players_selected: playersInGroup,
                chart_name: chartName
            });
        }
    }

    togglePlayersListGroups(openedPlayerList: boolean) {
        const toggleValue = openedPlayerList ? 'on' : 'off';
        const user = this.dataStoreService.user;

        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`GPLT_${toggleValue}`, {
                coach_id: user.coachId, squad_id: user.activeSquadId, platform: this.getPlatformName(),
            });
        }
    }

    groupOrderRearranged(startIndex, endIndex) {
        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`GOR`, {
                coach_id: user.coachId,
                squad_id: user.activeSquadId,
                platform: this.getPlatformName(),
                start_index: startIndex,
                end_index: endIndex
            });
        }
    }

    analysisPlayerListPanelClosed(panelClosed: boolean, chartName: string) {
        const toggleValue = panelClosed ? 'open' : 'close';
        const user = this.dataStoreService.user;

        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`APPLC_${toggleValue}`, {
                coach_id: user.coachId, squad_id: user.activeSquadId, platform: this.getPlatformName(), chart_name: chartName
            });
        }
    }

    sendReminder(sessionTypeName, playersSelected) {
        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`SR_${sessionTypeName}`, {
                coach_id: user.coachId,
                squad_id: user.activeSquadId,
                platform: this.getPlatformName(),
                players_selected: playersSelected
            });
        }
    }

    hideSyncReminderMessage() {
        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`HSR`, {
                coach_id: user.coachId, squad_id: user.activeSquadId, platform: this.getPlatformName(),
            });
        }
    }

    playerZonalBreakdownToggleUpdated(playerZonalBreakdownSelection: string) {
        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`ZBCPr_S_${playerZonalBreakdownSelection}`, {
                coach_id: user.coachId, squad_id: user.activeSquadId, platform: this.getPlatformName(),
            });
        }
    }

    updateMetricCardBreakdownGroupPlayerPage(metricGroupName: string, sessionType: string, analysisChildPage: string) {
        const user = this.dataStoreService.user;

        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`UMCBGPr_${metricGroupName}`, {
                coach_id: user.coachId, squad_id: user.activeSquadId, platform: this.getPlatformName(),
                analysis_child_page: analysisChildPage
            });
        }
    }

    rotatePitch(pitchFlippedDirection: string, analysisChildPage: string) {
        const user = this.dataStoreService.user;

        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`RPD_${pitchFlippedDirection}`, {
                coach_id: user.coachId, squad_id: user.activeSquadId, platform: this.getPlatformName(),
                analysis_child_page: analysisChildPage
            });
        }
    }

    updateGameDayHalfBreakDownComparisonPlayerPageMetricGroup(metricGroupName: string) {
        const user = this.dataStoreService.user;

        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`UGSHBPr_${metricGroupName}`, {
                coach_id: user.coachId, squad_id: user.activeSquadId, platform: this.getPlatformName(),
            });
        }
    }

    updateAnalysisActiveMapViewMode(currentMapViewMode, analysisChildPage) {
        const user = this.dataStoreService.user;

        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`UAMAVM_${currentMapViewMode}`, {
                coach_id: user.coachId, squad_id: user.activeSquadId, platform: this.getPlatformName(),
                analysis_child_page: analysisChildPage
            });
        }
    }

    playerComparisonSelection(playerComparisonComparisonSlot, playerComparisonSelectionOption) {
        const user = this.dataStoreService.user;

        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`PCS_${playerComparisonComparisonSlot}_${playerComparisonSelectionOption}`, {
                coach_id: user.coachId, squad_id: user.activeSquadId, platform: this.getPlatformName(),
            });
        }
    }

    updatePlayerComparisonBreakdownHalfToggled(playerComparisonComparisonSlot, half) {
        const user = this.dataStoreService.user;

        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`UPCBHT_${half}_${playerComparisonComparisonSlot}`, {
                coach_id: user.coachId, squad_id: user.activeSquadId, platform: this.getPlatformName(),
            });
        }
    }

    updatePlayerComparisonSelectedMetrics(length: number) {
        const user = this.dataStoreService.user;

        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`UPCSM`, {
                coach_id: user.coachId,
                squad_id: user.activeSquadId,
                platform: this.getPlatformName(),
                number_of_selected_metrics: length
            });
        }
    }

    updatePlayerComparisonSelectedComparisonViewMode(currentActivePlayerComparisonMode, sliderIndex: number) {
        const user = this.dataStoreService.user;

        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`UPCSCVM_${currentActivePlayerComparisonMode}_${sliderIndex}`, {
                coach_id: user.coachId, squad_id: user.activeSquadId, platform: this.getPlatformName(),
            });
        }
    }

    updatePlayerComparisonZonalBreakDownPieChartOption(sliderIndex: number, zonalPieChartViewMode: string) {
        const user = this.dataStoreService.user;

        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`UPCZBPCO_${zonalPieChartViewMode}_${sliderIndex}`, {
                coach_id: user.coachId, squad_id: user.activeSquadId, platform: this.getPlatformName(),
            });
        }
    }

    deleteGroup() {
        const user = this.dataStoreService.user;

        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`DG`, {
                coach_id: user.coachId, squad_id: user.activeSquadId, platform: this.getPlatformName(),
            });
        }
    }

    changeSquad(activeSquadId: string, squadId: string) {
        const user = this.dataStoreService.user;

        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`CS`, {
                coach_id: user.coachId,
                squad_id: user.activeSquadId,
                platform: this.getPlatformName(),
                previous_squad_id: squadId,
                current_squad_id: activeSquadId
            });
        }
    }

    sendInviteCode(inviteCodeMode: string, numberOfSelectedEmails: number) {
        const user = this.dataStoreService.user;

        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`IC_${inviteCodeMode}`, {
                coach_id: user.coachId,
                squad_id: user.activeSquadId,
                platform: this.getPlatformName(),
                number_of_emails_invited: numberOfSelectedEmails,
                number_of_coaches: this.dataStoreService.activeSquad.squadCoaches.length,
                number_of_players: this.dataStoreService.activeSquad.squadPlayers.length
            });
        }
    }

    cannotAddPlayerPlayerLimitReached() {
        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`CAPPLR`, {
                coach_id: user.coachId, squad_id: user.activeSquadId, platform: this.getPlatformName(),
            });
        }
    }

    playersAcceptedToSquad(playerAddedMode: string, numberOfPlayersAccepted: number) {
        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`PA_${playerAddedMode}`, {
                coach_id: user.coachId,
                squad_id: user.activeSquadId,
                platform: this.getPlatformName(),
                selected_players: numberOfPlayersAccepted
            });
        }
    }

    updateSquadName() {
        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`USN`, {
                coach_id: user.coachId,
                squad_id: user.activeSquadId,
                platform: this.getPlatformName(),
                number_of_players: this.dataStoreService.activeSquad.squadPlayers.length,
                number_of_coaches: this.dataStoreService.activeSquad.squadCoaches.length
            });
        }
    }

    updateSquadAvatar() {
        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`USA`, {
                coach_id: user.coachId,
                squad_id: user.activeSquadId,
                platform: this.getPlatformName(),
                number_of_players: this.dataStoreService.activeSquad.squadPlayers.length,
                number_of_coaches: this.dataStoreService.activeSquad.squadCoaches.length
            });
        }
    }

    updateSquadSport(value) {
        const user = this.dataStoreService.user;
        const squadSport = this.getSquadSportNumberToInitials(value);
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`USS_${squadSport}`, {
                coach_id: user.coachId,
                squad_id: user.activeSquadId,
                platform: this.getPlatformName(),
                number_of_players: this.dataStoreService.activeSquad.squadPlayers.length,
                number_of_coaches: this.dataStoreService.activeSquad.squadCoaches.length,
                previous_squad_sport: this.getSquadSportNumberToInitials(this.dataStoreService.activeSquad.squadSport)
            });
        }
    }

    getSquadSportNumberToInitials(value: number) {
        let newSport = '';
        switch (value) {
            case 0:
                newSport = 'Scr';
                break;
            case 1:
                newSport = 'Rby';
                break;
            case 2:
                newSport = 'GAA';
                break;
            case 3:
                newSport = 'Hky';
                break;
        }
        return newSport;
    }

    removePlayersFromSquad(removePlayersMode: string, length: number) {
        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`RPS_${removePlayersMode}`, {
                coach_id: user.coachId,
                squad_id: user.activeSquadId,
                platform: this.getPlatformName(),
                number_of_players: this.dataStoreService.activeSquad.squadPlayers.length,
                number_of_coaches: this.dataStoreService.activeSquad.squadCoaches.length,
                players_selected: length
            });
        }
    }

    updatePlayersInitials() {
        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`UPI`, {
                coach_id: user.coachId,
                squad_id: user.activeSquadId,
                platform: this.getPlatformName(),
                number_of_players: this.dataStoreService.activeSquad.squadPlayers.length,
                number_of_coaches: this.dataStoreService.activeSquad.squadCoaches.length,
            });
        }
    }

    updateZonalValueIntervals(zonalPieChartViewMode: string) {
        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`UZI_${zonalPieChartViewMode}`, {
                coach_id: user.coachId,
                squad_id: user.activeSquadId,
                platform: this.getPlatformName(),
                number_of_players: this.dataStoreService.activeSquad.squadPlayers.length,
                number_of_coaches: this.dataStoreService.activeSquad.squadCoaches.length,
            });
        }
    }

    restSquadInviteCode() {
        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`RSIC`, {
                coach_id: user.coachId,
                squad_id: user.activeSquadId,
                platform: this.getPlatformName(),
                number_of_players: this.dataStoreService.activeSquad.squadPlayers.length,
                number_of_coaches: this.dataStoreService.activeSquad.squadCoaches.length,
            });
        }
    }

    setSquadAdmin() {
        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`SSA`, {
                coach_id: user.coachId,
                squad_id: user.activeSquadId,
                platform: this.getPlatformName(),
                number_of_players: this.dataStoreService.activeSquad.squadPlayers.length,
                number_of_coaches: this.dataStoreService.activeSquad.squadCoaches.length,
            });
        }
    }

    deleteCoach() {
        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`DC`, {
                coach_id: user.coachId,
                squad_id: user.activeSquadId,
                platform: this.getPlatformName(),
                number_of_players: this.dataStoreService.activeSquad.squadPlayers.length,
                number_of_coaches: this.dataStoreService.activeSquad.squadCoaches.length,
            });
        }
    }

    updateUsersName() {
        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`UUN`, {
                coach_id: user.coachId,
                squad_id: user.activeSquadId,
                platform: this.getPlatformName(),
                number_of_players: this.dataStoreService.activeSquad.squadPlayers.length,
                number_of_coaches: this.dataStoreService.activeSquad.squadCoaches.length,
            });
        }
    }

    updatePassword(passwordUpdatePage: string) {
        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`PU_${passwordUpdatePage}`, {
                coach_id: user.coachId,
                squad_id: user.activeSquadId,
                platform: this.getPlatformName(),
                number_of_players: this.dataStoreService.activeSquad.squadPlayers.length,
                number_of_coaches: this.dataStoreService.activeSquad.squadCoaches.length,
            });
        }
    }

    async updateRegion(regionNumber: number) {
        const regionName = this.getRegionNameFormRegionNum(regionNumber);

        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`RU_${regionName}`, {
                coach_id: user.coachId,
                squad_id: user.activeSquadId,
                platform: this.getPlatformName(),
                number_of_players: this.dataStoreService.activeSquad.squadPlayers.length,
                number_of_coaches: this.dataStoreService.activeSquad.squadCoaches.length,
                previous_region: this.getRegionNameFormRegionNum(await this.localStorageService.getUsersRegion())
            });
        }
    }

    updateSpeedMetricUnit(metricNumber: number) {
        const user = this.dataStoreService.user;

        const speedMetricName = this.getSpeedUnitName(metricNumber);

        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`UMSU_${speedMetricName}`, {
                coach_id: user.coachId,
                squad_id: user.activeSquadId,
                platform: this.getPlatformName(),
                number_of_players: this.dataStoreService.activeSquad.squadPlayers.length,
                number_of_coaches: this.dataStoreService.activeSquad.squadCoaches.length,
                previous_speed_unit: this.getSpeedUnitName(this.dataStoreService.user.coachPreferences.metricUnits.speedUnit)
            });
        }
    }

    updateDistanceMetricUnit(metricNumber: number) {
        const user = this.dataStoreService.user;

        const distanceMetricName = this.getDistanceName(metricNumber);

        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`UMDU_${distanceMetricName}}`, {
                coach_id: user.coachId,
                squad_id: user.activeSquadId,
                platform: this.getPlatformName(),
                number_of_players: this.dataStoreService.activeSquad.squadPlayers.length,
                number_of_coaches: this.dataStoreService.activeSquad.squadCoaches.length,
                previous_distance_unit: this.getDistanceName(this.dataStoreService.user.coachPreferences.metricUnits.distanceUnit)
            });
        }
    }

    getRegionNameFormRegionNum(regionEnum: number) {
        switch (regionEnum) {
            case 0:
                return 'Afa';
            case 1:
                return 'Asa';
            case 2:
                return 'EU';
            case 3:
                return 'NA';
            case 4:
                return 'Oca';
            case 5:
                return 'SA';

        }
    }

    getSpeedUnitName(value) {
        switch (value) {
            case 0:
                return 'M/S';
            case 1:
                return 'KMH';
            case 2:
                return 'MPH';
            case 3:
                return 'YS';
        }
    }

    getDistanceName(value) {
        switch (value) {
            case 0:
                return 'M';
            case 1:
                return 'KM';
            case 2:
                return 'Miles';
            case 3:
                return 'Yds';

        }
    }

    addPitch(pitchId: string) {
        const user = this.dataStoreService.user;

        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`APh`, {
                coach_id: user.coachId,
                squad_id: user.activeSquadId,
                platform: this.getPlatformName(),
                number_of_players: this.dataStoreService.activeSquad.squadPlayers.length,
                number_of_coaches: this.dataStoreService.activeSquad.squadCoaches.length,
                pitch_id: pitchId
            });
        }
    }

    updatePitch(pitchId: string) {
        const user = this.dataStoreService.user;

        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`UPh`, {
                coach_id: user.coachId,
                squad_id: user.activeSquadId,
                platform: this.getPlatformName(),
                number_of_players: this.dataStoreService.activeSquad.squadPlayers.length,
                number_of_coaches: this.dataStoreService.activeSquad.squadCoaches.length,
                pitch_id: pitchId
            });
        }
    }

    deletePitch(pitchId: string) {
        const user = this.dataStoreService.user;

        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`DPh`, {
                coach_id: user.coachId,
                squad_id: user.activeSquadId,
                platform: this.getPlatformName(),
                number_of_players: this.dataStoreService.activeSquad.squadPlayers.length,
                number_of_coaches: this.dataStoreService.activeSquad.squadCoaches.length,
                pitch_id: pitchId
            });
        }
    }

    setFavouritePitch(pitchId: string, favorited: boolean) {
        const user = this.dataStoreService.user;

        const favourite = favorited ? 'Fd' : 'Ufd';

        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`${favourite}`, {
                coach_id: user.coachId,
                squad_id: user.activeSquadId,
                platform: this.getPlatformName(),
                number_of_players: this.dataStoreService.activeSquad.squadPlayers.length,
                number_of_coaches: this.dataStoreService.activeSquad.squadCoaches.length,
                pitch_id: pitchId
            });
        }
    }

    updatePreferences(value, notifications) {
        const user = this.dataStoreService.user;
        const toggleValue = value ? 'on' : 'off';

        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`PfeU_${notifications}_${toggleValue}`, {
                platform: this.getPlatformName(),
            });
        }
    }

    openSupportUrl() {
        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`OSU`, {
                coach_id: user.coachId,
                squad_id: user.activeSquadId,
                platform: this.getPlatformName(),
                number_of_players: this.dataStoreService.activeSquad.squadPlayers.length,
                number_of_coaches: this.dataStoreService.activeSquad.squadCoaches.length,
            });
        }
    }

    shareFeedBackSettingsPage() {
        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`SFSP`, {
                coach_id: user.coachId,
                squad_id: user.activeSquadId,
                platform: this.getPlatformName(),
                number_of_players: this.dataStoreService.activeSquad.squadPlayers.length,
                number_of_coaches: this.dataStoreService.activeSquad.squadCoaches.length,
            });
        }
    }

    feedBackModalClosed() {
        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`FMC`, {
                coach_id: user.coachId,
                squad_id: user.activeSquadId,
                platform: this.getPlatformName(),
                number_of_players: this.dataStoreService.activeSquad.squadPlayers.length,
                number_of_coaches: this.dataStoreService.activeSquad.squadCoaches.length,
            });
        }
    }

    feedBackModalFeedbackAdded() {
        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`FMFA`, {
                coach_id: user.coachId,
                squad_id: user.activeSquadId,
                platform: this.getPlatformName(),
                number_of_players: this.dataStoreService.activeSquad.squadPlayers.length,
                number_of_coaches: this.dataStoreService.activeSquad.squadCoaches.length,
            });
        }
    }

    forgotPasswordEmailSent = () => {
        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`FPES`, {
                platform: this.getPlatformName(),
            });
        }
    }

    loginStatus(status: string) {
        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`LS_${status}`, {
                platform: this.getPlatformName(),
            });
        }
    }

    accountEmailConfirmed(status: string){
        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`AEC_${status}`, {
                platform: this.getPlatformName(),
            });
        }
    }

    tutorialScreenSkip(tutorialView: string) {
        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`TSS_${tutorialView}`, {
                platform: this.getPlatformName(),
            });
        }
    }

    addResultToResultsExplainerModel() {
        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`ARTREM`, {
                coach_id: user.coachId,
                squad_id: user.activeSquadId,
                platform: this.getPlatformName(),
            });
        }
    }

    skipAddingResultToResultsExplainerModel(){
        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`SARTREM`, {
                coach_id: user.coachId,
                squad_id: user.activeSquadId,
                platform: this.getPlatformName(),
            });
        }
    }

    filterSessionsByResultType(analysisChildPage: string, resultFilterType: SessionType) {
        const sessionFilterType = this.getSessionFilterTypeByFilterType(resultFilterType);

        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`FSBFT_${sessionFilterType}`, {
                coach_id: user.coachId,
                squad_id: user.activeSquadId,
                platform: this.getPlatformName(),
                analysis_child_page: analysisChildPage
            });
        }
    }

    filterSessionByGameDayResultFilterType(analysisChildPage: string, resultFilterType: ResultType){
        const sessionResultFilterType = this.getSessionResultFilterType(resultFilterType);

        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`FSBGRFT_${sessionResultFilterType}`, {
                coach_id: user.coachId,
                squad_id: user.activeSquadId,
                platform: this.getPlatformName(),
                analysis_child_page: analysisChildPage
            });
        }
    }

    getSessionFilterTypeByFilterType(filterType: SessionType){
        switch (filterType){
            case SessionType.All:
                return 'all';
            case SessionType.Practice:
                return 'PS';
            case SessionType.GameDay:
                return 'GS';
        }
    }

    getSessionResultFilterType(resultFilterType: ResultType){
        switch (resultFilterType){
            case ResultType.All:
                return 'all';
            case ResultType.Loss:
                return 'L';
            case ResultType.Win:
                return 'W';
        }
    }

    templateCreated(report: ReportType, length: number) {
        const reportType = this.getReportNameByReportType(report);

        console.log(`TC_${reportType}`);

        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`TC_${reportType}`, {
                coach_id: user.coachId,
                squad_id: user.activeSquadId,
                platform: this.getPlatformName(),
                number_of_selected_metrics: length
            });
        }
    }

    templateUpdated(report: ReportType, length: number, summaryTable: boolean) {
        const reportType = this.getReportNameByReportType(report);

        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`TU_${reportType}`, {
                coach_id: user.coachId,
                squad_id: user.activeSquadId,
                platform: this.getPlatformName(),
                number_of_selected_metrics: length,
                summary_table: summaryTable,
            });
        }
    }

    getReportNameByReportType = (reportType: ReportType) => {
        switch (reportType){
            case ReportType.CSV:
                return 'CSV';
            case ReportType.PDF:
                return 'PDF';
        }
    }

    updateCalendarViewMode(calendarViewMode: string) {
        const user = this.dataStoreService.user;
        if (user && user.coachId) {
            this.angularFireAnalytics.logEvent(`UCVM_${calendarViewMode}`, {
                coach_id: user.coachId,
                squad_id: user.activeSquadId,
                platform: this.getPlatformName(),
            });
        }
    }
}

