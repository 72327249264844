export enum EventType {
    None,
    Added,
    Edited,
    Removed,
    Selected,
}

export interface SessionEvent {
    type: EventType;
   session?: any;
   sessions?: any;
  practiceSessionScheduleId?: string;
}
