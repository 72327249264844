import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../../services/auth/auth.service';
import {Auth} from 'aws-amplify';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(private auth: AuthService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise((resolve) => {
            Auth.currentAuthenticatedUser()
                .then((user) => {
                    this.auth.signedIn = true;
                    this.auth.authUser = user;
                    resolve(true);
                }, () => {
                    this.auth.logoutWithURL(state);
                    resolve(false);
                });
        });
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return this.canActivate(route, state);
    }
}
