import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {
    CalendarComponentMonthChange, CalendarComponentOptions, CalendarComponentPayloadTypes, DayConfig
} from 'ion2-calendar';
import {Session} from '../../../interfaces/session/session';
import {DataStoreService} from '../../../services/data-store/data-store.service';
import * as _ from 'lodash';
import {SessionType} from '../../../enums/session-type.enum';
import * as moment from 'moment/moment';
import {SessionService} from 'src/app/services/session/session.service';
import {v4 as uuidv4} from 'uuid';
import {ModalController} from '@ionic/angular';
import {EventType} from '../../../interfaces/event-type';
import {
    ActionRequiredBulkGamedayModelComponent
} from '../action-required-bulk-gameday-model/action-required-bulk-gameday-model.component';
import {AnalyticsService} from "../../../services/analytics/analytics.service";


@Component({
    selector: 'app-add-bulk-gamedays', template: `
        <app-reusable-modal-structure-one [title]="title" [description]="description" [submitButtonText]="submitText"
                                          [disabledSubmitButton]="disableSubmitButton"
                                          [submitFunctionCallback]="submitButtonPressed" [loading]="loading">
            <div class="content w-100 h-100">
                <div class="add-buk-gameday-explanation-text pt-24 font-md font-color-1 font-w-light text-align-center">{{'Resources.TapToSelectOrDeselectGamedays' | translate}}</div>
                <div class="calendar-container pt-16">
                    <ion-calendar #calendar class="h-100 calendar calendar-bulk-gameday" style="padding: 0 10px 0"
                                  [(ngModel)]="selectedDate"
                                  [options]="optionsRange"
                                  (change)="dateChange($event)"
                                  (monthChange)="monthChange($event)">
                    </ion-calendar>
                </div>
                <div class="vertical-align-content">
                    <div class="calendar-session-legend-container pt-32">
                        <div class="calendar-session-legend-top-row calendar-session-legend-row">
                            <div class="calendar-session-legend-item d-flex" *ngFor="let legend of topRowLegendData">
                                <div class="calendar-session-legend-icon session-calendar"
                                     [ngClass]="legend.cssClass"></div>
                                <div class="calendar-session-legend-text font-400 font-xsm font-color-1"> {{legend.name}}
                                    ({{'Resources.Past' | translate}})
                                </div>
                            </div>
                        </div>
                        <div class="calender-session-legend-bottom-row calendar-session-legend-row">
                            <div class="calendar-session-legend-item d-flex" *ngFor="let legend of bottomRowLegendData">
                                <div class="calendar-session-legend-icon session-calendar"
                                     [ngClass]="legend.cssClass"></div>
                                <div class="calendar-session-legend-text font-400 font-xsm font-color-1"> {{legend.name}}
                                    ({{'Resources.Future' | translate}})
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </app-reusable-modal-structure-one>
    `, styles: [`
        .calendar-bulk-gameday .switch-btn {
            font-size: var(--font-md) !important;
        }

        .calendar-session-legend-container {
            gap: 12px;
            display: flex;
            flex-direction: column;
        }

        ion-calendar {
            width: 100%;
        }

        .calendar-session-legend-row {
            display: flex;
            gap: 31px;
            align-items: center;
        }

        .calendar-session-legend-top-row {
            gap: 42px;
        }

        .calendar-session-legend-item {
            display: flex;
            gap: 10px;
            align-items: center;
        }

        .calendar-container {
            width: 100%;
            overflow: scroll;
        }
    `]
})
export class AddBulkGamedaysComponent implements OnInit {

    title: string;
    description: string;
    selectedDate: any = '';
    submitText: string = '';
    optionsRange: CalendarComponentOptions;
    sessionCountDictPerDay = {};
    groupedSessions: any = [];
    selectedSessions: Session[] = [];
    daysConfig: DayConfig[];
    topRowLegendData = [];
    bottomRowLegendData = [];
    errorMessage: string;
    showError: boolean;
    loading: boolean = false;
    private previousSessions: Session[] = [];

    constructor(private translate: TranslateService, public dataStore: DataStoreService, private sessionService: SessionService, private modalController: ModalController, private analyticsService: AnalyticsService) {
        this.title = this.translate.instant('Resources.AddBulkGamedays');
        this.description = this.translate.instant('Resources.AddBulkGamedaysDescription');
        this.submitText = this.translate.instant('Resources.Save');

        this.getPreviousSessions();
        this.refreshCalendar();
        this.removeMarkers();

        this.topRowLegendData = [{
            name: this.translate.instant('Resources.Practice'), cssClass: 'practice-session-calendar'
        }, {
            name: this.translate.instant('Resources.Gameday'), cssClass: 'gameday-session-calendar'
        }, {
            name: this.translate.instant('Resources.BulkGameday'), cssClass: 'bulk-gameday-session-calendar'
        }];

        this.bottomRowLegendData = [{
            name: this.translate.instant('Resources.Practice'), cssClass: 'practice-session-calendar-upcoming-session'
        }, {
            name: this.translate.instant('Resources.Gameday'), cssClass: 'gameday-session-calendar-upcoming-session'
        }, {
            name: this.translate.instant('Resources.BulkGameday'),
            cssClass: 'bulk-gameday-session-calendar-upcoming-session'
        }];
    }

    ngOnInit() {
        setTimeout(() => {
            const title = document.getElementsByClassName('title')[0] as HTMLElement;
            title.addEventListener('click', () => {
                setTimeout(() => {
                    this.updateMarkersOnCalendar();
                }, 1);
            });
        }, 100);
    }

    populateCalendarWithData() {
        this.previousSessions = Object.assign([], this.dataStore.activeSquad.squadSessions);
        // Need out why we have duplicates in this list to figure
        this.previousSessions = this.previousSessions.filter((obj, index, self) => index === self.findIndex((o) => o.sessionId === obj.sessionId));
        this.groupSessionsByDate();
        this.parseCalendarDays();
        this.refreshCalendar();
        this.updateMarkersOnCalendar();
    }

    dateChange($event: CalendarComponentPayloadTypes) {
        // this.selected date has array of selected dates
        this.updateMarkersOnCalendar();
    }


    getSessionByDate(startOfInterval, endOfInterval) {
        const squadId = this.dataStore.activeSquad.squadId;
        this.sessionService.getSessionsByDate(squadId, startOfInterval, endOfInterval)
            .subscribe((recivedSessions) => {
                if (this.dataStore.viewCalendarMode === 1) {

                    const groupedSessions: { [key: string]: Session[] } = {};

                    recivedSessions.forEach(session => {
                        const sessionDateKey = new Date(session.sessionDate).toLocaleDateString('en-US', {
                            year: 'numeric', month: '2-digit', day: '2-digit'
                        });

                        if (!groupedSessions[sessionDateKey]) {
                            groupedSessions[sessionDateKey] = [];
                        }

                        groupedSessions[sessionDateKey].push(session);
                    });

                    const keysArray = Object.keys(groupedSessions);

                    keysArray.sort((a, b) => {
                        const numA = parseInt(a, 10);
                        const numB = parseInt(b, 10);

                        if (numA < numB) {
                            return -1;
                        }
                        if (numA > numB) {
                            return 1;
                        }
                        return 0;
                    });

                    const sortedDict: { [key: string]: Session[] } = {};
                    keysArray.forEach(key => {
                        sortedDict[key] = groupedSessions[key].reverse();
                    });

                }
                const uniqueSessions = recivedSessions.filter(newSession => {
                    return !this.dataStore.activeSquad.squadSessions.some(existingSession => existingSession.sessionId === newSession.sessionId);
                });
                this.dataStore.activeSquad.squadSessions.push(...uniqueSessions);
                this.dataStore.setMonthLoaded(endOfInterval);
                this.populateCalendarWithData();

                this.dataStore.sessionEvent$.next({type: EventType.Added, sessions: recivedSessions});
            });
    }


    monthChange($event: CalendarComponentMonthChange) {
        const startOfMonth = moment($event.newMonth.dateObj).startOf('month').valueOf();
        const finalDayOfMonth = moment($event.newMonth.dateObj).endOf('month').valueOf();
        if (!this.dataStore.isMonthLoaded(startOfMonth)) {
            this.getSessionByDate(startOfMonth, finalDayOfMonth);
        }
        this.updateMarkersOnCalendar();
    }

    disableSubmitButton = () => {
        return this.selectedDate.length < 1;
    }

    submitButtonPressed = async () => {
        const gamedayBulkId = await uuidv4();
        this.loading = true;

        this.analyticsService.sessionAdded('BGS', 'A', this.selectedDate.length);

        const addSessionsWithDelay = async (index: number) => {
            if (index >= this.selectedDate.length) {
                setTimeout(() => {
                    this.openWarningModel();
                }, 500);
                return;
            }

            const date = this.selectedDate[index];

            // Add session
            await this.addBulkGameDaySession(date, gamedayBulkId);

            setTimeout(() => {
                addSessionsWithDelay(index + 1);
            }, (index + 1) * 1000);
        };

        // Start adding sessions from index 0
        await addSessionsWithDelay(0);
    }

    openWarningModel = async () => {
        this.modalController.dismiss();

        const modal = await this.modalController.create({
            component: ActionRequiredBulkGamedayModelComponent, cssClass: 'modal-structure-5', backdropDismiss: true,
        });

        modal.onDidDismiss()
            .then((dismiss) => {
                this.dataStore.modalOpen = false;
            });

        return await modal.present();
    }

    addBulkGameDaySession = async (date, gameDayBulkId) => {
        const momentDate = moment(date); // Convert the passed date string to a moment object

        // Calculate session times based on the provided date
        const session1stStartTime = momentDate.clone().set({hour: 14, minute: 0, second: 0});
        const session1stEndTime = momentDate.clone().set({hour: 14, minute: 45, second: 0});
        const session2ndStartTime = momentDate.clone().set({hour: 15, minute: 0, second: 0});
        const session2ndEndTime = momentDate.clone().set({hour: 15, minute: 45, second: 0});

        const newSession: Session = {
            sessionScorePointsAway: 0,
            sessionScorePointsHome: 0,
            sessionId: '',
            sessionSquadId: this.dataStore.activeSquad.squadId,
            sessionType: 1,
            sessionDate: momentDate.valueOf(),
            sessionTitle: ``,
            session1stEndTime: session1stEndTime.valueOf(),
            session1stStartTime: session1stStartTime.valueOf(),
            session2ndStartTime: session2ndStartTime.valueOf(),
            session2ndEndTime: session2ndEndTime.valueOf(),
            sessionStartTime: session1stStartTime.valueOf(),
            sessionEndTime: session2ndEndTime.valueOf(),
            sessionOppoTeamName: '',
            sessionPitchId: null,
            sessionLocation: 0,
            sessionResult: null,
            sessionScoreAway: 0,
            sessionScoreHome: 0,
            sessionSyncTotal: 0,
            isCoachCreated: true,
            createdBy: this.dataStore.user.coachId,
            gameDayBulkId
        };

        if (this.dataStore.activeSquad.squadSport === 2) {
            newSession.sessionScorePointsHome = 0;
            newSession.sessionScorePointsAway = 0;
        }

        this.sessionService.createSession(newSession).subscribe((session: Session) => {
            this.dataStore.addSessionToActiveSquad(session);

            this.removeMarkers();
            this.populateCalendarWithData();
        });
    }

    showHideError(showHide: boolean, transKey?: string) {
        if (showHide) {
            this.translate.get(transKey).subscribe((res: string) => {
                this.errorMessage = res;
                this.showError = true;
            });
        } else {
            this.showError = false;
            this.errorMessage = '';
        }
    }

    getPreviousSessions() {
        this.previousSessions = [];

        if (this.dataStore.activeSquad.squadSessions && this.dataStore.activeSquad.squadSessions.length > 0) {
            this.previousSessions = Object.assign([], this.dataStore.activeSquad.squadSessions);

            // Need out why we have duplicates in this list to figure
            this.previousSessions = this.previousSessions.filter((obj, index, self) => index === self.findIndex((o) => o.sessionId === obj.sessionId));
            this.groupSessionsByDate();
            this.setSessionsToSelected();
            this.parseCalendarDays();
        }
    }

    parseCalendarDays() {
        this.daysConfig = [];
        const currentDate = Date.now();

        Object.values(this.groupedSessions).map((sessionList: Session []) => {
            const gamedaySessionsBulkCount = sessionList.reduce((count, session) => {
                return session.sessionType === SessionType.GameDay && session.gameDayBulkId ? count + 1 : count;
            }, 0);
            const gamedaySessionsCount = sessionList.reduce((count, session) => {
                return session.sessionType === SessionType.GameDay && !session.gameDayBulkId ? count + 1 : count;
            }, 0);
            const practiceSessionsCount = sessionList.reduce((count, session) => {
                return session.sessionType === SessionType.Practice ? count + 1 : count;
            }, 0);

            if (sessionList.length > 0) {
                this.sessionCountDictPerDay[sessionList[0].sessionId] = {
                    isAfterCurrentDate: currentDate < sessionList[0].sessionStartTime,
                    gamedaySessionsCount,
                    gamedaySessionsBulkCount,
                    practiceSessionsCount
                };
                this.daysConfig.push({
                    date: moment(sessionList[0].sessionDate).toDate(),
                    marked: false,
                    subTitle: ``,
                    cssClass: this.getSessionCssClassNewUi(sessionList[0])
                });
            }
        });
        this.refreshCalendar();
        this.updateMarkersOnCalendar();
    }

    refreshCalendar() {
        this.optionsRange = {
            showAdjacentMonthDay: false,
            daysConfig: this.daysConfig,
            color: 'light',
            weekdays: ['Sun', 'Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat'],
            monthFormat: 'MMMM YYYY',
            showMonthPicker: false,
            pickMode: 'multi'
        };

    }

    groupSessionsByDate() {
        this.groupedSessions = _.groupBy(this.previousSessions, (session) => {
            return new Date(session.sessionDate).toLocaleDateString();
        });
    }

    removeMarkers() {
        const elementsToRemove = document.querySelectorAll('.session-calendar-container');

// Loop through each element and remove it
        elementsToRemove.forEach(element => {
            element.remove();
        });
    }

    updateMarkersOnCalendar() {
        setTimeout(() => {
            if (!document.querySelector('.session-calendar-container')) {
                const elements = document.getElementsByClassName('session');

                for (let i = 0; i < elements.length; i++) {
                    const element = elements[i];

                    const classNames = element.className.split(' ');
                    const sessionIdClass = classNames.find(className => className.includes('$'));
                    const sessionId = sessionIdClass ? sessionIdClass.split('$')[1] : null;
                    const sessionCountDict = this.sessionCountDictPerDay[sessionId];

                    const containerDiv = document.createElement('div');
                    containerDiv.className = 'session-calendar-container';
                    element.appendChild(containerDiv);

                    if (sessionCountDict.gamedaySessionsCount) {
                        const newDiv = document.createElement('div');
                        newDiv.textContent = sessionCountDict.gamedaySessionsCount > 1 ? '+ ' + sessionCountDict.gamedaySessionsCount : '';
                        newDiv.className = sessionCountDict.isAfterCurrentDate ? 'session-calendar gameday-session-calendar-upcoming-session' : 'session-calendar gameday-session-calendar';
                        containerDiv.appendChild(newDiv);
                    }

                    if (sessionCountDict.gamedaySessionsBulkCount) {
                        const newDiv = document.createElement('div');
                        newDiv.textContent = sessionCountDict.gamedaySessionsBulkCount > 1 ? '+ ' + sessionCountDict.gamedaySessionsBulkCount : '';
                        newDiv.className = sessionCountDict.isAfterCurrentDate ? 'session-calendar bulk-gameday-session-calendar-upcoming-session' : 'session-calendar bulk-gameday-session-calendar';
                        containerDiv.appendChild(newDiv);
                    }


                    if (sessionCountDict.practiceSessionsCount) {
                        const newDiv = document.createElement('div');
                        newDiv.textContent = sessionCountDict.practiceSessionsCount > 1 ? '+ ' + sessionCountDict.practiceSessionsCount : '';
                        newDiv.className = sessionCountDict.isAfterCurrentDate ? 'session-calendar practice-session-calendar-upcoming-session' : 'session-calendar practice-session-calendar';
                        containerDiv.appendChild(newDiv);
                    }
                }
            }
        });
    }

    getSessionCssClassNewUi(session: Session) {
        return `session $${session.sessionId}`;
    }

    private setSessionsToSelected() {
        this.previousSessions.forEach(s => s.isSessionSelected = false);
        this.selectedSessions.forEach(s => {
            const sIndex = this.previousSessions.findIndex(s2 => s2.sessionId === s.sessionId);

            if (sIndex !== -1) {
                this.previousSessions[sIndex].isSessionSelected = true;
            }
        });
    }
}
