import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../../services/auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class UserConfirmationGuard implements CanActivate {

    constructor(private auth: AuthService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.auth.isUserToBeConfirmed()) {
            return true;
        }
        // not logged in so redirect to login page with the return url
        // this.auth.logoutWithURL(state);
        return true;
    }
}
