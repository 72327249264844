import {Injectable} from '@angular/core';

import {LocalStorageService} from '../local-storage/local-storage.service';
import {TranslateService} from '@ngx-translate/core';

import * as moment from 'moment';
import {Regions} from '../../enums/regions.enum';


@Injectable({
    providedIn: 'root'
})
export class LocalisationService {
    currentUserRegion: any;

    constructor(private storage: LocalStorageService, private translate: TranslateService) {
    }

    public iniLocaleOptions() {
        this.storage.getUsersRegion()
            .then(val => {
                if (val === undefined || val === null) {
                    const defaultRegion = this.getDeviceRegion();
                    this.setStorageRegion(defaultRegion);
                    this.setUserOptions(defaultRegion);
                } else {
                    this.currentUserRegion = val;
                    this.setUserOptions(val);
                }
            }, () => {
                const defaultRegion = Regions.Europe;
                this.setStorageRegion(defaultRegion);
                this.setUserOptions(defaultRegion);
            });
    }

    public setUserOptions(region: Regions) {
        // set language
        if (region === Regions.NorthAmerica) {
            this.translate.setDefaultLang('en-US');
            this.translate.use('en-US');
            moment.locale('en-us');
        } else {
            this.translate.setDefaultLang('en-GB');
            this.translate.use('en-GB');
            moment.locale('en-gb');
        }
    }

    public setStorageRegion(region: Regions) {
        this.currentUserRegion = region;
        this.storage.setUsersRegion(region);
    }

    private getDeviceRegion(): Regions {
        if (moment().utcOffset() >= 0) { // EU
            return Regions.Europe;
        } else { // US
            return Regions.NorthAmerica;
        }
    }

    public getUserRegion(region: Regions) {
        switch (region) {
            case Regions.Africa:
                return 'Africa';
            case Regions.Asia:
                return 'Asia';
            case Regions.Europe:
                return 'Europe';
            case Regions.NorthAmerica:
                return 'NorthAmerica';
            case Regions.Oceania:
                return 'Oceania';
            case Regions.SouthAmerica:
                return 'SouthAmerica';
        }
    }
}
