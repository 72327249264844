import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-only-editing-current-session-warning', template: `
        <app-reusable-modal-structure-six  [iconHeaderDivClass]="warningIconClass" [modalBorderContainerColor]="themeColor" [titleFontColor]="themeColor"
                                          [descriptionPoint1]="description" [title]="title" [buttonText]="buttonText"
                                          [buttonPress]="closeModal"></app-reusable-modal-structure-six>
    `, styles: [`
        .only-editing-current-session-warning {
        }
    `]
})
export class OnlyEditingCurrentSessionWarningComponent implements OnInit {

    title: string;
    description: string;
    buttonText: string;
    warningIconClass = 'warning-icon warning-icon-editing-scheduled-session';
    themeColor: string = '--warning-orange';
    constructor(private translate: TranslateService, private modalController: ModalController) {
        this.title = this.translate.instant('Resources.Warning');
        this.description = this.translate.instant('Resources.EditingTheSessionWillOnlyEditTheCurrentSessionDescription');
        this.buttonText = this.translate.instant('Resources.Ok');
    }

    ngOnInit() {
    }

    closeModal = () => {
        this.modalController.dismiss();
    }
}
