import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {Session} from "../../../interfaces/session/session";
import {ScheduledService} from "../../../services/scheduled/scheduled.service";
import {DataStoreService} from "../../../services/data-store/data-store.service";
import {NgForm} from "@angular/forms";
import * as moment from "moment/moment";
import {ModalController} from "@ionic/angular";
import {SessionService} from "../../../services/session/session.service";
import {EventType} from "../../../interfaces/event-type";
import {HelperService} from "../../../services/helper-service/helper.service";

@Component({
    selector: 'app-edit-practice-session-action-modal', template: `
        <app-reusable-modal-structure-five [title]="title" [descriptionPoint1]="descriptionPoint1" [loading]="updatingSession"
                                           [descriptionPoint2]="descriptionPoint2"
                                           [actionButton2Text]="editButtonGroupText"
                                           [actionButton3Text]="editButtonIndividualText"
                                           [actionButton2PressedFunction]="editAllPressed"
                                           [actionButton3PressedFunction]="editIndividualPressed"></app-reusable-modal-structure-five>
    `, styles: [`
        .edit-practice-session-action-modal {
            /* Add your CSS styles here */
        }
    `]
})
export class EditPracticeSessionActionModalComponent implements OnInit {
    title: string;
    descriptionPoint1: string;
    descriptionPoint2: string;
    editButtonIndividualText: string;
    editButtonGroupText: string;
    @Input() scheduledSession: Session;
    @Input() sessionAlreadyAdded: boolean = false;
    updatingSession: boolean = false;


    // TODO editing item that already exsists

    constructor(private translate: TranslateService, private scheduleService: ScheduledService, private dataStore: DataStoreService, private sessionService: SessionService, private modalController: ModalController, private helperService: HelperService) {
        // delete buttons
        // this.editButtonIndividualText = this.translate.instant('Resources.DeleteThisEvent');
        // this.editButtonGroupText = this.translate.instant('Resources.DeleteAllEvents');
    }

    ngOnInit() {
        this.title = this.translate.instant('Resources.DoYouWantToEdit');
        this.descriptionPoint1 = this.translate.instant('Resources.EditScheduledPracticeSessionDescriptionPoint1');
        this.descriptionPoint2 = this.translate.instant('Resources.EditScheduledPracticeSessionDescriptionPoint2');
        this.editButtonIndividualText = this.translate.instant('Resources.EditThisEvent');
        this.editButtonGroupText = this.translate.instant('Resources.EditAllEvents');
    }


    editIndividualPressed = async () => {
        this.updatingSession = true;

        const scheduledPracticeSessionToUpdate = this.dataStore.activeSquad.squadScheduledPracticeSessions.find(p => p.sessionScheduleId === this.scheduledSession.practiceSessionScheduleId);

        if (!this.sessionAlreadyAdded) {
            await this.handleUpdate(scheduledPracticeSessionToUpdate, EventType.Removed);
        } else {
            await this.handleUpdate(scheduledPracticeSessionToUpdate, EventType.Edited);
        }

        this.updatingSession = false;
        this.closeModal();
    }

    async handleUpdate(scheduledPracticeSessionToUpdate, eventType) {
        if (scheduledPracticeSessionToUpdate) {
            if (this.sessionAlreadyAdded){
                scheduledPracticeSessionToUpdate.datesToExclude.push(this.helperService.getTimestamp7DaysAgo(scheduledPracticeSessionToUpdate.nextSessionToAddDate));
            }else{
                scheduledPracticeSessionToUpdate.datesToExclude.push(this.scheduledSession.originalScheduledSessionTime);
            }
            try {
                this.dataStore.sessionEvent$.next({type: eventType, session: this.scheduledSession});
                await this.scheduleService.updatePracticeScheduledSession(scheduledPracticeSessionToUpdate);
                if (!this.sessionAlreadyAdded) {
                    this.dataStore.addPracticeScheduledSession(scheduledPracticeSessionToUpdate);
                } else {
                    this.sessionService.updateSession(this.scheduledSession).subscribe((session) => {
                        const index = this.dataStore.activeSquad.squadSessions.findIndex(s => s.sessionId === session.sessionId);
                        this.dataStore.activeSquad.squadSessions[index] = session;
                        this.dataStore.sessionEvent$.next({type: EventType.Edited, session});
                    });
                }
            } catch (e) {
                console.log(e);
            }
        }
    }


    editAllPressed = () => {
        alert('edit all pressed');
    }


    closeModal() {
        this.modalController.dismiss();
    }

}