import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Pipe({
    name: 'sort'
})

@Injectable({
    providedIn: 'root'
})

export class SortPipe implements PipeTransform {

    transform(array: any[], field: string, desc?: boolean): any[] {
        if (array && array.length > 0) {
            array.sort((a: any, b: any) => {
                if (a !== b) {
                    if (String(a[field]).toLowerCase() < String(b[field]).toLowerCase()) {
                        return !desc ? -1 : 1;
                    } else if (String(a[field]).toLowerCase() > String(b[field]).toLowerCase()) {
                        return !desc ? 1 : -1;
                    }
                }
                return 0;
            });
        }
        return array;
    }
}
