import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'sortNumbers'
})
export class SortNumbersPipe implements PipeTransform {

    transform(array: any[], field: string, des?: boolean): any[] {
        if (array && array.length > 0) {
            array.sort((a: any, b: any) => {
                if (a !== b) {
                    if (Number(a[field]) <= Number(b[field])) {
                        if (!des) {
                            return -1;
                        } else {
                            return 1;
                        }
                    } else if (Number(a[field]) > Number(b[field])) {
                        if (!des) {
                            return 1;
                        } else {
                            return -1;
                        }
                    } else {
                        return 0;
                    }
                }
            });
        }
        return array;
    }
}
