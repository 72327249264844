import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SessionService} from '../../../services/session/session.service';
import {ModalController} from '@ionic/angular';
import {DataStoreService} from "../../../services/data-store/data-store.service";
import {EventType} from "../../../interfaces/event-type";
import {HelperService} from "../../../services/helper-service/helper.service";
import {AnalyticsService} from "../../../services/analytics/analytics.service";

@Component({
    selector: 'app-delete-gameday-bulk-sessions-action-modal', template: `
        <app-reusable-modal-structure-five [title]="title" [descriptionPoint1]="descriptionPoint1"
                                           [loading]="updatingSession"
                                           [descriptionPoint2]="descriptionPoint2"
                                           [actionButton2Text]="editButtonGroupText"
                                           [actionButton3Text]="editButtonIndividualText"
                                           [actionButton2PressedFunction]="deleteAllPressed"
                                           [actionButton3PressedFunction]="deleteIndividualPressed"></app-reusable-modal-structure-five>
    `, styles: [`
        /* Your component styles go here */
    `]
})
export class DeleteGamedayBulkSessionsActionModalComponent implements OnInit {
    title: string;
    descriptionPoint1: string;
    descriptionPoint2: string;
    editButtonIndividualText: string;
    editButtonGroupText: string;
    @Input() bulkGameDayId: string;
    @Input() sessionId: string;
    updatingSession: boolean = false;


    constructor(private translate: TranslateService, private sessionService: SessionService, private modalController: ModalController, private dataStore: DataStoreService, private helperService: HelperService, private analyticService: AnalyticsService) {
    }

    ngOnInit() {
        this.title = this.translate.instant('Resources.DoYouWantToDelete');
        this.descriptionPoint1 = this.translate.instant('Resources.DeleteScheduledPracticeSessionDescriptionPoint1');
        this.descriptionPoint2 = this.translate.instant('Resources.DeleteScheduledPracticeSessionDescriptionPoint2');
        this.editButtonIndividualText = this.translate.instant('Resources.DeleteThisEvent');
        this.editButtonGroupText = this.translate.instant('Resources.DeleteAllEvents');
    }

    deleteAllPressed = () => {
        this.sessionService.deleteAllBulkGameDaySessionsById(this.bulkGameDayId);
        const removedSessions = this.dataStore.activeSquad.squadSessions.filter(s => s.gameDayBulkId === this.bulkGameDayId && this.helperService.checkTimestampIsAfterToday(s.sessionStartTime));
        this.dataStore.activeSquad.squadSessions = this.dataStore.activeSquad.squadSessions.filter(s => !(s.gameDayBulkId === this.bulkGameDayId && this.helperService.checkTimestampIsAfterToday(s.sessionStartTime)));
        this.dataStore.sessionEvent$.next({type: EventType.Removed, sessions: removedSessions});
        this.modalController.dismiss();
        this.analyticService.sessionDeleted('BGAS', this.helperService.isSessionBeforeOrAfterCurrentDateToLetter(removedSessions[0].sessionDate));
    }

    deleteIndividualPressed = () => {
        this.sessionService.deleteSession(this.sessionId);
        const removedSession = this.dataStore.activeSquad.squadSessions.find(s => s.sessionId === this.sessionId);
        this.analyticService.sessionDeleted('BGS', this.helperService.isSessionBeforeOrAfterCurrentDateToLetter(removedSession.sessionDate));
        this.dataStore.activeSquad.squadSessions = this.dataStore.activeSquad.squadSessions.filter(s => s.sessionId !== this.sessionId);
        this.dataStore.sessionEvent$.next({type: EventType.Removed, session: removedSession});
        this.modalController.dismiss();
    }

}
