import {Component, Input, OnInit} from '@angular/core';
import {Session} from '../../../interfaces/session/session';
import {TranslateService} from '@ngx-translate/core';
import {ScheduledService} from '../../../services/scheduled/scheduled.service';
import {DataStoreService} from '../../../services/data-store/data-store.service';
import {SessionService} from '../../../services/session/session.service';
import {ModalController} from '@ionic/angular';
import {EventType} from '../../../interfaces/event-type';
import * as moment from 'moment';
import {SessionType} from "../../../enums/session-type.enum";
import {AnalyticsService} from "../../../services/analytics/analytics.service";
import {HelperService} from "../../../services/helper-service/helper.service";

@Component({
    selector: 'app-delete-practice-scheduled-session-action-modal', template: `
        <app-reusable-modal-structure-five [title]="title" [descriptionPoint1]="descriptionPoint1"
                                           [loading]="updatingSession"
                                           [descriptionPoint2]="descriptionPoint2"
                                           [actionButton2Text]="editButtonGroupText"
                                           [actionButton3Text]="editButtonIndividualText"
                                           [actionButton2PressedFunction]="deleteAllPressed"
                                           [actionButton3PressedFunction]="deleteIndividualPressed"></app-reusable-modal-structure-five>
    `, styles: [`
        .delete-practice-modal {

        }
    `],
})
export class DeletePracticeScheduledSessionActionModalComponent implements OnInit {
    title: string;
    descriptionPoint1: string;
    descriptionPoint2: string;
    editButtonIndividualText: string;
    editButtonGroupText: string;
    @Input() scheduledSession: Session;
    @Input() sessionAlreadyAdded: boolean = false;
    updatingSession: boolean = false;

    constructor(private translate: TranslateService, private scheduleService: ScheduledService, private dataStore: DataStoreService, private sessionService: SessionService, private modalController: ModalController, private analyticsService: AnalyticsService, private helperService: HelperService) {
        this.title = this.translate.instant('Resources.DoYouWantToDelete');
        this.descriptionPoint1 = this.translate.instant('Resources.DeleteScheduledPracticeSessionDescriptionPoint1');
        this.descriptionPoint2 = this.translate.instant('Resources.DeleteScheduledPracticeSessionDescriptionPoint2');
        this.editButtonIndividualText = this.translate.instant('Resources.DeleteThisEvent');
        this.editButtonGroupText = this.translate.instant('Resources.DeleteAllEvents');
    }

    ngOnInit() {
    }

    deleteAllPressed = async () => {
        // removed scheduled sessionÒ
        this.updatingSession = true;
        try {
            await this.scheduleService.deleteAllScheduledPracticeSession(this.scheduledSession.practiceSessionScheduleId, this.scheduledSession.sessionSquadId);
            this.dataStore.activeSquad.squadScheduledPracticeSessions = this.dataStore.activeSquad.squadScheduledPracticeSessions.filter(ps => ps.sessionScheduleId !== this.scheduledSession.practiceSessionScheduleId);
            this.dataStore.sessionEvent$.next({
                type: EventType.Removed,
                practiceSessionScheduleId: this.scheduledSession.practiceSessionScheduleId
            });

           // get current sessions that are part of the group of practice schedule sessions and check that they are in the future remove them else dont
            const currentSquadSessionsThatContainPracticeSessions = this.dataStore.activeSquad.squadSessions.filter((s) => s.practiceSessionScheduleId === this.scheduledSession.practiceSessionScheduleId);

            // remove future practice sessions
            for (const session of currentSquadSessionsThatContainPracticeSessions) {
                if (moment(session.sessionStartTime).isAfter(moment.now())){
                   await this.sessionService.deleteSession(session.sessionId);
                   this.dataStore.activeSquad.squadSessions = this.dataStore.activeSquad.squadSessions.filter(s => s.sessionId !== session.sessionId);
                }
            }
            this.dataStore.sessionEvent$.next({
                type: EventType.Removed,
                practiceSessionScheduleId: this.scheduledSession.practiceSessionScheduleId
            });
        } catch (e) {
            console.log(e);
        }
        this.updatingSession = false;
        this.analyticsService.sessionDeleted('RPS', this.helperService.isSessionBeforeOrAfterCurrentDateToLetter(this.scheduledSession.sessionStartTime));
        this.closeModal();
    }

    closeModal = () => {
        this.modalController.dismiss();
    }

    deleteIndividualPressed = async () => {
        const scheduledPracticeSessionToUpdate = this.dataStore.activeSquad.squadScheduledPracticeSessions.find(p => p.sessionScheduleId === this.scheduledSession.practiceSessionScheduleId);
        if (scheduledPracticeSessionToUpdate) {

            try {
                this.updatingSession = true;
                if (!this.sessionAlreadyAdded) {
                    scheduledPracticeSessionToUpdate.datesToExclude.push(this.scheduledSession.sessionDate);
                    await this.scheduleService.updatePracticeScheduledSession(scheduledPracticeSessionToUpdate);

                    // update the scheduled item to not contain this item in the datastore
                    const indexToUpdate = this.dataStore.activeSquad.squadScheduledPracticeSessions.findIndex(s => s.sessionScheduleId === scheduledPracticeSessionToUpdate.sessionScheduleId);
                    this.dataStore.activeSquad.squadScheduledPracticeSessions[indexToUpdate] = {
                        ...scheduledPracticeSessionToUpdate,
                        datesToExclude: [...scheduledPracticeSessionToUpdate.datesToExclude, this.scheduledSession.sessionDate]
                    };
                } else {
                    await this.sessionService.deleteSession(this.scheduledSession.sessionId);
                    this.dataStore.activeSquad.squadSessions = this.dataStore.activeSquad.squadSessions.filter(s => s.sessionId !== this.scheduledSession.sessionId);

                }
                this.dataStore.sessionEvent$.next({type: EventType.Removed, session: this.scheduledSession});
            } catch (e) {
                console.log(e);
            }
            this.updatingSession = false;
            this.analyticsService.sessionDeleted('RPS', this.helperService.isSessionBeforeOrAfterCurrentDateToLetter(this.scheduledSession.sessionStartTime));

            this.closeModal();
        }
    }

}
