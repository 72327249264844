import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {PracticeSessionScheduleInterface} from "../../interfaces/practice-session-schedule-interface";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ScheduledService {
    scheduledPath = '/schedule';

    constructor(private apiService: ApiService) {}

    public updatePracticeScheduledSession(practiceSessionSchedule: PracticeSessionScheduleInterface): Observable<any> {
        return this.apiService.post('/schedule/practice/session', practiceSessionSchedule);
    }

    public getPracticeScheduledSessionsBySquadId(squadId: string): Observable<any> {
        return this.apiService.get(`${this.scheduledPath}/practice/sessions/coach/get/${squadId}`);
    }

    public deleteAllScheduledPracticeSession(sessionScheduleId: string, squadId: string): Observable<any> {
        return this.apiService.post('/schedule/practice/sessions/delete/all', {
            sessionScheduleId,
            squadId
        });

    }
}
