import {NgModule} from '@angular/core';
import {
    RouterModule,
    Routes
} from '@angular/router';
import {AuthGuard} from './guards/auth/auth.guard';
import {DataStoreGuard} from './guards/data-store/data-store.guard';
import {UserConfirmationGuard} from './guards/user-confirmation/user-confirmation.guard';

const routes: Routes = [
    {
        path: 'welcome',
        loadChildren: () => import('./pages/auth/welcome/welcome.module').then(m => m.WelcomePageModule)
    },
    {
        path: 'login',
        loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginPageModule)
    },
    {
        path: 'register',
        loadChildren: () => import('./pages/auth/register/register.module').then(m => m.RegisterPageModule)
    },
    {
        path: 'forgottonPassword',
        loadChildren: () => import('./pages/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
    },
    {
        canActivate: [UserConfirmationGuard],
        path: 'userConfirmation',
        loadChildren: () => import('./pages/auth/user-confirmation/user-confirmation.module').then(m => m.UserConfirmationPageModule)
    },
    {
        path: 'setup',
        loadChildren: () => import('./pages/setup/setup.module').then(m => m.SetupPageModule)
    },
    {
        path: 'user-confirmation',
        loadChildren: () => import('./pages/auth/user-confirmation/user-confirmation.module').then(m => m.UserConfirmationPageModule)
    },
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [DataStoreGuard],
        loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
    }
];


@NgModule({
    imports: [
        RouterModule.forRoot(routes )
    ],
    exports: [RouterModule]
})

export class AppRoutingModule {
}
