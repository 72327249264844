import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BrowserModule, HammerModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {SocialSharing} from '@ionic-native/social-sharing/ngx';
import {FileOpener} from '@ionic-native/file-opener/ngx';
import {File} from '@ionic-native/file/ngx';
import {IonicStorageModule} from '@ionic/storage-angular';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {Geolocation} from '@ionic-native/geolocation/ngx';
import {Device} from '@ionic-native/device/ngx';
import {NativeGeocoder} from '@ionic-native/native-geocoder/ngx';
import * as Hammer from 'hammerjs';
import {HammerGestureConfig, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ScreenOrientation} from '@ionic-native/screen-orientation/ngx';
import {Keyboard} from '@ionic-native/keyboard/ngx';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {UserService} from './services/user/user.service';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {SortNumbersPipe} from './pipes/sort-numbers/sort-numbers.pipe';
import {HTTP} from '@ionic-native/http/ngx';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {NgxMaskModule} from 'ngx-mask';
import {LayoutModule} from '@angular/cdk/layout';
import {AngularFireModule} from '@angular/fire/compat';
import {environment} from '../environments/environment';
import {AngularFireAnalyticsModule, ScreenTrackingService} from '@angular/fire/compat/analytics';
import {FirebaseX} from '@ionic-native/firebase-x/ngx';
import {DataStoreService} from "./services/data-store/data-store.service";
import {DragDropModule} from "@angular/cdk/drag-drop";
import { CalendarModule } from 'ion2-calendar';
import { FullCalendarModule } from '@fullcalendar/angular';

export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, 'assets/i18n/', '.json');
}
// declare module "@angular/core" {
//     interface ModuleWithProviders<T = any> {
//         ngModule: Type<T>;
//         providers?: Provider[];
//     }
// }

export class MyHammerConfig extends HammerGestureConfig {
    overrides = {
        swipe: {direction: Hammer.DIRECTION_ALL},
    } as any;
}

@NgModule({
    declarations: [
        AppComponent,
        SortNumbersPipe
    ],
    imports: [
        BrowserModule,
        IonicModule.forRoot({scrollAssist: true, animated: !isFirefox(), hardwareBackButton: false, swipeBackEnabled: false}),
        CalendarModule.forRoot({
            doneLabel: 'Save',
            closeIcon: true
        }),
        FullCalendarModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FontAwesomeModule,
        HttpClientModule,
        LayoutModule,
        DragDropModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            }
        }),
        NgxMaskModule.forRoot(),
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAnalyticsModule,
        IonicStorageModule.forRoot()
    ],
    providers: [
        StatusBar,
        SplashScreen,
        ScreenOrientation,
        Keyboard,
        FirebaseX,
        UserService,
        SocialSharing,
        SortNumbersPipe,
        FileOpener,
        File,
        AppVersion,
        Device,
        HTTP,
        Geolocation,
        NativeGeocoder,
        DataStoreService,
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy
        },
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: MyHammerConfig,
        },
        ScreenTrackingService
    ],
    exports: [],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}

function isFirefox() {
    return navigator.userAgent.toLowerCase().indexOf('firefox') !== -1;
}