import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { DataStoreService } from '../../services/data-store/data-store.service';

@Injectable({
    providedIn: 'root'
})
export class DataStoreGuard implements CanActivate, CanActivateChild {

    constructor(private dataStoreService: DataStoreService) {}


    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        if (this.dataStoreService.isDataLoaded === true) {
            // The data is already loaded, no need to make a new request
            return true;
        } else {
            if (!this.dataStoreService.isFetchingData) {
                // Data has not been loaded, must make a new request
                const retryAttempts = 8;
                let currentAttempt = 1;

                while (currentAttempt <= retryAttempts) {
                    console.log(`Retrieving data: Retry attempt ${currentAttempt}`);

                    await this.dataStoreService.loadData(true, state.url);
                    console.log('Data loaded?' + this.dataStoreService.isDataLoaded);

                    if (this.dataStoreService.isDataLoaded) {
                        // Data loaded successfully
                        return true;
                    } else {
                        console.error('Error occurred during data retrieval');
                    }

                    currentAttempt++;
                }

                console.error(`Failed to retrieve data after ${retryAttempts} attempts`);
                return false;
            }

            return false;
        }
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this.canActivate(route, state);
    }

}