import {Injectable, NgZone} from '@angular/core';

import {Geolocation} from '@ionic-native/geolocation/ngx';
import {NativeGeocoder, NativeGeocoderResult, NativeGeocoderOptions} from '@ionic-native/native-geocoder/ngx';
import {Platform} from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class LocationService {

    geoencoderOptions: NativeGeocoderOptions = {
        useLocale: true,
        maxResults: 5
    };

    geoLatitude: number;
    geoLongitude: number;
    geoAccuracy: number;
    geoAddress: string;

    constructor(private geolocation: Geolocation, private nativeGeocoder: NativeGeocoder,
                private ngZone: NgZone, private platform: Platform) {
        this.geoAddress = 'unknown';
    }

    // Get current coordinates of device
    public getGeolocation() {
        setTimeout(() => {
            this.geolocation.getCurrentPosition().then((resp) => {
                this.geoLatitude = resp.coords.latitude;
                this.geoLongitude = resp.coords.longitude;
                this.geoAccuracy = resp.coords.accuracy;

                if (this.platform.is('android') || this.platform.is('ios')) {
                    this.getGeoencoder(this.geoLatitude, this.geoLongitude);
                } else {
                    // Google maps
                    this.getGeoLocationGoogle(this.geoLatitude, this.geoLongitude).then();
                }
            }).catch((error) => {
                // Error getting co-ordinates
            });
        }, 0);
    }

    // Geocoder method to fetch address from coordinates passed as arguments
    private getGeoencoder(latitude, longitude) {
        this.nativeGeocoder.reverseGeocode(latitude, longitude, this.geoencoderOptions)
            .then((result: NativeGeocoderResult[]) => {
                this.geoAddress = this.generateAddress(result[0]);
            })
            .catch((error: any) => {
                // Error getting location
            });
    }

    async getGeoLocationGoogle(lat: number, lng: number) {
        if (navigator.geolocation) {
            // @ts-ignore
            const geocoder = await new google.maps.Geocoder();
            // @ts-ignore
            const latlng = await new google.maps.LatLng(lat, lng);
            const request = {latLng: latlng};

            await geocoder.geocode(request, (results, status) => {
                // @ts-ignore
                if (status === google.maps.GeocoderStatus.OK) {
                    const result = results[0];
                    this.ngZone.run(() => {
                        if (result != null) {
                            this.geoAddress = result.formatted_address;
                        }
                    });
                }
            });
        }
    }

    // Return Comma saperated address
    private generateAddress(addressObj) {
        const obj = [];
        let address = '';

        // tslint:disable-next-line:forin
        for (const key in addressObj) {
            obj.push(addressObj[key]);
        }
        obj.reverse();
        for (const val in obj) {
            if (obj[val].length) {
                address += obj[val] + ', ';
            }
        }
        return address.slice(0, -2);
    }
}
