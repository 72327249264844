import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import * as moment from 'moment';

import {of} from 'rxjs';
import {Observable} from 'rxjs/internal/Observable';
import {catchError, flatMap, switchMap} from 'rxjs/operators';

import {ApiService} from '../api/api.service';
import {AuthService} from '../auth/auth.service';
import {User} from '../../interfaces/user';
import {ReportTemplate} from '../../interfaces/report-template';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    coachPath = '/coach/';
    feedbackPath = '/feedback';
    notificationPath = 'notification/';
    templatesPath = 'templates/';

    constructor(private http: HttpClient, private apiService: ApiService, private authService: AuthService) {
    }

    public createUser(coachName, coachEmail): Observable<any> {
        const user = {coachName, coachEmail};
        return this.apiService.post(this.coachPath, user);
    }

    public createUserIfNotPresent(name, email): Observable<any> {
        const id = this.authService.getCurrentUserId();

        return new Observable<any>(observer => {
            this.getById(id).subscribe(
                data => {
                    observer.next(data);
                    observer.complete();
                },
                error => {
                    this.createUser(name, email).subscribe(
                        userData => {
                            this.getById(id).subscribe(
                                newData => {
                                    observer.next(newData);
                                    observer.complete();
                                },
                                err => {
                                    observer.error(err);
                                }
                            );
                        },
                        err => {
                            observer.error(err);
                        }
                    );
                }
            );
        });
    }


    public getById(id): Observable<User> {
        return this.apiService.get(this.coachPath + 'get/' + id);
    }

    // get modified JSON and save it.
    public updateUserData(changedData: User): Observable<any> {
        return this.apiService.put(this.coachPath + 'put', changedData);
    }

    public updateActiveSquadId(id: string): Observable<any> {
        return this.apiService.put(this.coachPath + 'activeSquadId/put/' + id, {});
    }

    public deleteById(id: string): Observable<User> {
        return this.apiService.delete(this.coachPath + 'delete/' + id);
    }

    public sendFeedBackEmail(userId, userEmail, userName, text): Observable<any> {
        return this.apiService.post(this.feedbackPath, {userEmail, userId, userName, text});
    }

    public getLastNotification(): Observable<any> {
        return this.apiService.get(this.coachPath + this.notificationPath + 'get');
    }

    public deleteAllNotifications(): Observable<any> {
        return this.apiService.delete(this.coachPath + this.notificationPath + 'delete');
    }

    public createLoginEntry(platform: string, deviceType: string): Observable<any> {
        return this.apiService.post(this.coachPath + 'login-entry', {platform, deviceType});
    }

    public getUserFeedback(feedbackRating, startDate, endDate): Observable<any[]> {
        return this.apiService.post(this.feedbackPath + '/getUserFeedback', {feedbackRating, startDate, endDate});
    }

    public getReportTemplates(): Observable<ReportTemplate[]> {
        return this.apiService.get(this.coachPath + this.templatesPath + 'get');
    }

    public createReportTemplate(reportTemplate: ReportTemplate): Observable<ReportTemplate> {
        return this.apiService.post(this.coachPath + this.templatesPath + 'create', reportTemplate);
    }

    public updateReportTemplate(reportTemplate: ReportTemplate): Observable<boolean> {
        return this.apiService.post(this.coachPath + this.templatesPath + 'update', reportTemplate);
    }

    public deleteReportTemplate(templateId: string): Observable<boolean> {
        return this.apiService.delete(this.coachPath + this.templatesPath + 'delete/' + templateId);
    }
}
