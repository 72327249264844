import {Injectable} from '@angular/core';

import {API} from 'aws-amplify';
import {from, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    apiName = 'StatSportsApi';
    myInit: any = {};

    constructor() {
    }

    get(path: string): Observable<any> {
        return from(API.post(this.apiName, path, null));
    }

    post(path: string, body: any): Observable<any> {
        this.myInit.body = body;
        return from(API.post(this.apiName, path, this.myInit));
    }

    put(path: string, body: any): Observable<any> {
        this.myInit.body = body;
        return from(API.post(this.apiName, path, this.myInit));
    }

    delete(path: string): Observable<any> {
        return from(API.post(this.apiName, path, null));
    }
}
