import {Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';

import {FirebaseX} from '@ionic-native/firebase-x/ngx';

@Injectable({
    providedIn: 'root'
})
export class FcmService {

    constructor(
        private platform: Platform,
        private firebaseX: FirebaseX) {
    }

    public async getToken() {
        let token;
        if (this.platform.is('android')) {
            token = await this.firebaseX.getToken();
        }

        if (this.platform.is('ios')) {
            await this.firebaseX.hasPermission().then(
                (hasPerm) => {
                    if (!hasPerm) {
                        this.firebaseX.grantPermission().then(
                            () => token = this.firebaseX.getToken()
                        );
                    } else {
                        token = this.firebaseX.getToken();
                    }
                });
        }

        return token;
    }

    public subscribeToNotifications() {
        return this.firebaseX.onMessageReceived();
    }

    public subscribeToTokenChange() {
        return this.firebaseX.onTokenRefresh();
    }

}
