import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Pitch } from '../../interfaces/pitch';
import { ApiService } from '../api/api.service';

@Injectable({
    providedIn: 'root'
})
export class PitchService {
    pitchPath = '/pitch';

    constructor(private apiService: ApiService) {
    }

    public getById(id: string): Observable<Pitch> {
        return this.apiService.get(this.pitchPath + 'get/' + id);
    }

    public getBySquadId(squadId): Observable<Pitch[]> {
        return this.apiService.post(this.pitchPath + '/get', { squadId });
    }

    public createPitch(createdPitch): Observable<Pitch> {
        return this.apiService.post(this.pitchPath, createdPitch);
    }

    public deletePitch(pitchId) {
        return this.apiService.delete(this.pitchPath + '/delete/' + pitchId);
    }

    public updatePitch(updatedPitch): Observable<Pitch> {
        return this.apiService.put(this.pitchPath + '/put', updatedPitch);
    }
}
