import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';

import {ApiService} from '../api/api.service';
import {environment} from '../../../environments/environment';
import {Squad} from 'src/app/interfaces/squad';
import {SelectedZonesEnum} from "../../enums/selected-zones-enum";
import {HeartRateZoneValues, SpeedZoneValues} from "../../interfaces/zone-value-interface";

@Injectable({
    providedIn: 'root'
})

export class SquadService {
    squadPath = '/squad/';

    constructor(private apiService: ApiService) {
    }

    public createSquad(squad: { squadName: string, squadSport: number }): Observable<Squad> {
        return this.apiService.post(this.squadPath, squad);
    }

    public updateSquad(squad: { squadName: string, squadSport: number }): Observable<Squad> {
        return this.apiService.put(this.squadPath + 'put', squad);
    }

    public updateAvatar(squadId: string, squadAvatar: string): Observable<any> {
        return this.apiService.put(this.squadPath + 'avatar/put/' + squadId, {squadAvatar});
    }

    public uploadAvatar(squadId: string, squadAvatar: string): Observable<any> {
        return this.apiService.post(this.squadPath + 'upload/' + squadId, {squadAvatar});
    }

    public getSquadById(squadId: string): Observable<Squad> {
        return this.apiService.get(this.squadPath + 'coach/get/' + squadId);
    }

    public getAllByCoachId(coachId: string): Observable<any> {
        return this.apiService.get(this.squadPath + 'coach/list/get/' + coachId);
    }

    public setSquadsZonalIntervals(squadId, selectedZone: SelectedZonesEnum, updatedZoneIntervals: SpeedZoneValues | HeartRateZoneValues){
        return this.apiService.post(this.squadPath + 'update-zonal-intervals/' + squadId , {updatedZoneIntervals, selectedZone});
    }

    public getSquadByCode(code: string): Observable<Squad> {
        return this.apiService.get(this.squadPath + 'coach/code/get/' + code);
    }

    public joinSquadByCode(code: string): Observable<any> {
        return this.apiService.put(this.squadPath + 'join/coach/put/' + code, {});
    }

    public acceptPlayerJoinRequest(playerId: string): Observable<Squad> {
        return this.apiService.put(this.squadPath + 'accept/put/' + playerId, {});
    }

    public acceptMultiplePlayerJoinRequests(playerIds: string[]): Observable<Squad> {
        return this.apiService.put(this.squadPath + 'accept-multiple', {playerIds});
    }

    public editPlayerInitials(playerId: string, playerInitials: string): Observable<Squad> {
        return this.apiService.put(this.squadPath + 'edit-initials/put/' + playerId, {playerInitials});
    }

    public switchSquadAdmin(newCoachId: string): Observable<Squad> {
        return this.apiService.put(this.squadPath + 'switch-admin/put/' + newCoachId, {});
    }

    public removePlayerFromSquad(playerId: string): Observable<Squad> {
        return this.apiService.put(this.squadPath + 'remove/put/' + playerId, {});
    }

    public removeMultiplePlayerFromSquad(playerIds: string[]): Observable<any> {
        return this.apiService.put(this.squadPath + 'remove-multiple', {playerIds});
    }

    public removeCoachFromSquad(coachId: string): Observable<Squad> {
        return this.apiService.put(this.squadPath + 'remove/put/' + coachId, {});
    }

    public sendSyncReminder(sessionId: string, playersIds: string[]): Observable<any> {
        return this.apiService.put(this.squadPath + 'send-reminder/' + sessionId, {playersIds});
    }

    public deleteSquad(squadId: string): Observable<Squad> {
        return this.apiService.delete(this.squadPath + 'delete/' + squadId);
    }

    public leaveSquad(coachId: string): Observable<Squad> {
        return this.apiService.post(this.squadPath + 'leave/coach/put/' + coachId, {});
    }

    public resetInviteCode(): Observable<any> {
        return this.apiService.put(this.squadPath + 'change-code/put', {});
    }

    // Todo create interface updated groups
    // Todo update url to match other api request urls
    public updateSquadGroups(squadId: string, updatedGroups: any): Observable<any> {
        return this.apiService.put(this.squadPath + 'updateGroups/put/' + squadId, {updatedGroups});
    }

    public updateSquadEntrySpeeds(squadId: string, squadEntrySpeeds: any): Observable<any>{
        return this.apiService.put(this.squadPath + 'updateEntrySpeeds/put/' + squadId, {
            hSREntrySpeed: squadEntrySpeeds.hSREntrySpeed,
            sprintEntrySpeed: squadEntrySpeeds.sprintEntrySpeed
        });
    }
}
