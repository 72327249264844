export enum MetricGroup {
    Volume,
    Speed,
    Intensity,
    Stress,
    HeartRate,
    Combo,
    Custom,
    selectedPlayerComparisonMetrics,
}
