import {Component, Input, NgZone, OnInit, Renderer2, ViewEncapsulation} from '@angular/core';
import {NgForm} from '@angular/forms';
import {AlertController, ModalController, NavController, PickerController, Platform} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {LocalStorageService} from '../../../services/local-storage/local-storage.service';
import {DataStoreService} from '../../../services/data-store/data-store.service';
import {SessionService} from '../../../services/session/session.service';
import {MetricService} from 'src/app/services/metric/metric.service';
import {Session} from '../../../interfaces/session/session';
import {Pitch} from '../../../interfaces/pitch';
import {HelperService} from '../../../services/helper-service/helper.service';
import {CalendarComponentOptions, DayConfig} from 'ion2-calendar';
import {EventType} from '../../../interfaces/event-type';
import {PracticeSessionScheduleInterface} from '../../../interfaces/practice-session-schedule-interface';
import {ScheduledService} from '../../../services/scheduled/scheduled.service';
import {
    EditPracticeSessionActionModalComponent
} from '../edit-practice-session-action-modal/edit-practice-session-action-modal.component';
import {
    DeletePracticeScheduledSessionActionModalComponent
} from '../delete-practice-scheduled-session-action-modal/delete-practice-scheduled-session-action-modal.component';
import {AddBulkGamedaysComponent} from '../add-bulk-gamedays/add-bulk-gamedays.component';
import {
    DeleteGamedayBulkSessionsActionModalComponent
} from '../delete-gameday-bulk-sessions-action-modal/delete-gameday-bulk-sessions-action-modal.component';
import {SessionType} from '../../../enums/session-type.enum';
import {AnalyticsService} from "../../../services/analytics/analytics.service";

@Component({
    selector: 'app-add-session',
    templateUrl: './add-session.component.html',
    styleUrls: ['./add-session.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AddSessionComponent implements OnInit {
    modalType: number;
    modalScreen: number;
    selectedDate: Date;
    existingSession: Session;
    sessionId: string;
    @Input() sessionType: number;
    sessionDate: string;
    sessionStartTime: string;
    sessionEndTime: string;
    session1stStartTime: string;
    session1stEndTime: string;
    session2ndStartTime: string;
    session2ndEndTime: string;
    sessionLocation: number;
    sessionPitchName: string;
    sessionPitchId: string;
    sessionTitle: string;
    sessionOpp: string;
    sessionScore: number;
    sessionScorePoints: number;
    sessionOppScore: number;
    sessionOppScorePoints: number;
    showError: boolean;
    errorMessage: string;
    updatingSession: boolean;
    showLoadingScreen: boolean;
    isWebApp: boolean;
    isUserAdmin: boolean;
    didUserCreateSession = false;

    // Properties to support user entered times
    sessionStartHHMM: string;
    sessionEndHHMM: string;
    sessionStartDate: string;
    sessionEndDate: string;
    session1stStartTimeHHMM: string;
    session1stEndTimeHHMM: string;
    session2ndStartTimeHHMM: string;
    session2ndEndTimeHHMM: string;
    session1stStartDate: string;
    session1stEndDate: string;
    session2ndStartDate: string;
    session2ndEndDate: string;
    sessionEndRepeatDate: string;

    dateFormat = 'DD/MM/YYYY';

    // Calender Widget
    type: 'moment';
    calWidgetOptions: CalendarComponentOptions;
    daysConfig: DayConfig[];
    dateFieldSelected: string;
    updateDateFieldValue: string;
    calendarDate: any;
    pickerCurrentIndex: number = 0;
    pickerMaxIndex: number = 1;
    picker: HTMLIonPickerElement;
    translate: TranslateService;
    originalPracticeSessionRecurringValue: boolean = false;
    isPracticeSessionRecurring: boolean = false;
    disableGameDayOption: boolean = false;
    private editPitchModalOpen: boolean = false;

    constructor(private modalController: ModalController, public dataStore: DataStoreService, private pickerController: PickerController, private alertController: AlertController, private ngZone: NgZone, private sessionService: SessionService, private nav: NavController, private storage: LocalStorageService, private metricService: MetricService, public helperService: HelperService, private platform: Platform, private renderer: Renderer2, private scheduleService: ScheduledService, private analyticsService: AnalyticsService) {
        this.sessionType = 0;
        this.sessionLocation = 0;
        this.sessionScore = 0;
        this.sessionScorePoints = 0;
        this.sessionOppScore = 0;
        this.sessionOppScorePoints = 0;
        this.sessionTitle = '';
        this.showError = false;
        this.updatingSession = false;
        this.showLoadingScreen = false;
        this.dateFieldSelected = ' ';
        this.updateDateFieldValue = ' ';
        this.setDefaultCalendarOptions();

        this.translate = this.helperService.translate;
    }

    setDefaultCalendarOptions = () => {
        this.calWidgetOptions = {
            pickMode: 'single',
            showAdjacentMonthDay: false,
            from: moment().set('year', 2018).toDate(),
            daysConfig: this.daysConfig,
            color: 'light',
            weekdays: ['Sun', 'Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat']
        };
    }

    setCalendarOptionsRecurringSelection = () => {
        this.calWidgetOptions = {
            pickMode: 'single',
            showAdjacentMonthDay: false,
            from: this.helperService.add7DaysToTheDate(moment(this.sessionEndTime).valueOf()),
            daysConfig: this.daysConfig,
            color: 'light',
            weekdays: ['Sun', 'Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat']
        };
    }

    ngOnInit() {
        // Update date format for US
        if (moment().utcOffset() < 0) { // EU
            this.dateFormat = 'MM/DD/YYYY';
        }

        this.isWebApp = !(this.platform.is('ios') || this.platform.is('android'));
        this.isUserAdmin = this.dataStore.isCurrentUserAdmin();

        if (this.modalType === 1) {
            this.setNewSessionTimes();
        } else {
            this.setExistingSessionTimes();
        }
        this.modalScreen = 1;
        this.checkIfUserCreatedSession();
    }

    checkIfUserCreatedSession() {
        if (this.modalType === 2) {
            if (this.existingSession.createdBy) {
                if (this.existingSession.createdBy === this.dataStore.user.coachId || this.existingSession.createdBy.includes('Scheduled Session Generator')) {
                    this.didUserCreateSession = true;
                }
            } else {
                this.didUserCreateSession = true;
            }
        }
    }

    getGaaStyle(is80Per: boolean) {
        if (is80Per) {
            return this.dataStore.activeSquad.squadSport === 2 ? {'min-width': '60%'} : {'min-width': '80%'};
        }

        return this.dataStore.activeSquad.squadSport === 2 ? {'min-width': '50%'} : {'min-width': '70%'};
    }

    setNewSessionTimes() {
        if (!this.selectedDate) {
            this.selectedDate = new Date();
        }

        this.sessionDate = moment(this.selectedDate).toISOString();
        this.sessionStartTime = moment(this.selectedDate).toISOString();
        this.sessionEndTime = moment(this.selectedDate).add(2, 'h').toISOString();
        this.sessionStartHHMM = moment(this.selectedDate).format('HH:mm');
        this.sessionStartDate = moment(this.selectedDate).format(this.dateFormat);
        this.sessionEndHHMM = moment(this.selectedDate).add(2, 'h').format('HH:mm');
        this.sessionEndDate = moment(this.selectedDate).add(2, 'h').format(this.dateFormat);

        this.sessionEndRepeatDate = moment(this.selectedDate).add(1, 'y').format(this.dateFormat);
        this.session1stStartTime = moment(this.selectedDate).toISOString();
        this.session1stEndTime = moment(this.selectedDate).add(this.getHalfLength(), 'm').toISOString();
        this.session2ndStartTime = moment(this.selectedDate).add(this.getHalfLength() + this.getTimeBetweenHalves(), 'm').toISOString();
        this.session2ndEndTime = moment(this.session2ndStartTime).add(this.getHalfLength(), 'm').toISOString();
        this.session1stStartTimeHHMM = moment(this.selectedDate).format('HH:mm');
        this.session1stStartDate = moment(this.selectedDate).format(this.dateFormat);
        this.session1stEndTimeHHMM = moment(this.selectedDate).add(this.getHalfLength(), 'm').format('HH:mm');
        this.session1stEndDate = moment(this.selectedDate).add(this.getHalfLength(), 'm').format(this.dateFormat);
        this.session2ndStartTimeHHMM = moment(this.selectedDate).add(this.getHalfLength() + this.getTimeBetweenHalves(), 'm').format('HH:mm');
        this.session2ndStartDate = moment(this.selectedDate).add(this.getHalfLength() + this.getTimeBetweenHalves(), 'm').format(this.dateFormat);
        this.session2ndEndTimeHHMM = moment(this.session2ndStartTime).add(this.getHalfLength(), 'm').format('HH:mm');
        this.session2ndEndDate = moment(this.session2ndStartTime).add(this.getHalfLength(), 'm').format(this.dateFormat);
    }

    setExistingSessionTimes() {
        this.sessionTitle = this.existingSession.sessionTitle;
        this.sessionOpp = this.existingSession.sessionOppoTeamName;
        this.sessionType = this.existingSession.sessionType;

        this.sessionPitchId = this.existingSession.sessionPitchId;
        const pitch = this.dataStore.activeSquad.squadPitches.find(p => p.pitchId === this.sessionPitchId);
        if (pitch) {
            this.sessionPitchName = pitch.pitchName;
        }

        this.sessionDate = this.validateDate(this.existingSession.sessionDate).toISOString();
        this.sessionEndRepeatDate = moment(this.existingSession.sessionDate).add(1, 'y').format(this.dateFormat);
        this.sessionStartTime = this.validateDate(this.existingSession.sessionStartTime).toISOString();
        this.sessionEndTime = this.validateDate(this.existingSession.sessionEndTime).toISOString();
        this.sessionStartHHMM = this.validateDate(this.existingSession.sessionStartTime).format('HH:mm');
        this.sessionStartDate = this.validateDate(this.existingSession.sessionStartTime).format(this.dateFormat);
        this.sessionEndHHMM = this.validateDate(this.existingSession.sessionEndTime).format('HH:mm');
        this.sessionEndDate = this.validateDate(this.existingSession.sessionEndTime).format(this.dateFormat);

        if (this.sessionType === 0) {
            this.session1stStartTime = this.validateDate(this.existingSession.sessionStartTime).toISOString();
            this.session1stEndTime = this.validateDate(this.existingSession.sessionStartTime).add(this.getHalfLength(), 'm').toISOString();
            this.session2ndStartTime = this.validateDate(this.existingSession.sessionStartTime).add(this.getHalfLength() + this.getTimeBetweenHalves(), 'm').toISOString();
            this.session2ndEndTime = this.validateDate(this.existingSession.sessionStartTime).add(this.getHalfLength() * 2 + this.getTimeBetweenHalves(), 'm').toISOString();
            this.session1stStartTimeHHMM = this.validateDate(this.existingSession.sessionStartTime).format('HH:mm');
            this.session1stStartDate = this.validateDate(this.existingSession.sessionStartTime).format(this.dateFormat);
            this.session1stEndTimeHHMM = this.validateDate(this.existingSession.sessionStartTime).add(this.getHalfLength(), 'm').format('HH:mm');
            this.session1stEndDate = this.validateDate(this.existingSession.sessionStartTime).add(this.getHalfLength(), 'm').format(this.dateFormat);
            this.session2ndStartTimeHHMM = this.validateDate(this.existingSession.sessionStartTime).add(this.getHalfLength() + this.getTimeBetweenHalves(), 'm').format('HH:mm');
            this.session2ndStartDate = this.validateDate(this.existingSession.sessionStartTime).add(this.getHalfLength() + this.getTimeBetweenHalves(), 'm').format(this.dateFormat);
            this.session2ndEndTimeHHMM = this.validateDate(this.existingSession.sessionStartTime).add(this.getHalfLength() * 2 + this.getTimeBetweenHalves(), 'm').format('HH:mm');
            this.session2ndEndDate = this.validateDate(this.existingSession.sessionStartTime).add(this.getHalfLength() * 2 + this.getTimeBetweenHalves(), 'm').format(this.dateFormat);

            // if (this.existingSession.practiceSessionScheduleId && this.helperService.checkTimestampIsAfterToday(moment(this.sessionStartTime))) {
            //     this.disableGameDayOption = true;
            // }

            this.sessionLocation = 0;

        } else {
            this.session1stStartTime = this.validateDate(this.existingSession.session1stStartTime).toISOString();
            this.session1stEndTime = this.validateDate(this.existingSession.session1stEndTime).toISOString();
            this.session2ndStartTime = this.validateDate(this.existingSession.session2ndStartTime).toISOString();
            this.session2ndEndTime = this.validateDate(this.existingSession.session2ndEndTime).toISOString();
            this.session1stStartTimeHHMM = this.validateDate(this.existingSession.session1stStartTime).format('HH:mm');
            this.session1stStartDate = this.validateDate(this.existingSession.session1stStartTime).format(this.dateFormat);
            this.session1stEndTimeHHMM = this.validateDate(this.existingSession.session1stEndTime).format('HH:mm');
            this.session1stEndDate = this.validateDate(this.existingSession.session1stEndTime).format(this.dateFormat);
            this.session2ndStartTimeHHMM = this.validateDate(this.existingSession.session2ndStartTime).format('HH:mm');
            this.session2ndStartDate = this.validateDate(this.existingSession.session2ndStartTime).format(this.dateFormat);
            this.session2ndEndTimeHHMM = this.validateDate(this.existingSession.session2ndEndTime).format('HH:mm');
            this.session2ndEndDate = this.validateDate(this.existingSession.session2ndEndTime).format(this.dateFormat);
        }

        if (this.existingSession.sessionLocation !== undefined) {
            this.sessionLocation = this.existingSession.sessionLocation;
        } else {
            this.sessionLocation = 0;
        }

        if (this.existingSession.sessionScoreHome) {
            this.sessionScore = this.existingSession.sessionScoreHome;
        } else {
            this.sessionScore = 0;
        }

        if (this.existingSession.sessionScorePointsHome) {
            this.sessionScorePoints = this.existingSession.sessionScorePointsHome;
        } else {
            this.sessionScorePoints = 0;
        }

        if (this.existingSession.sessionScorePointsAway) {
            this.sessionOppScorePoints = this.existingSession.sessionScorePointsAway;
        } else {
            this.sessionOppScorePoints = 0;
        }

        if (this.existingSession.sessionScoreAway) {
            this.sessionOppScore = this.existingSession.sessionScoreAway;
        } else {
            this.sessionOppScore = 0;
        }
    }

    closeModal() {
        this.modalController.dismiss();
    }

    async practiceFormSubmit(form: NgForm) {
        // Check session times are valid
        if (moment(this.sessionEndTime).isAfter(moment(this.sessionStartTime))) {
            if (this.modalType === 1) {
                this.updatingSession = true;
                // when recurring value has changed run a function
                if (this.isPracticeSessionRecurring && this.disableRecurringSessionOption()) {
                    await this.setupAsRecurringSession();
                } else {
                    this.addPracticeSession(form);
                }
            } else {
                if (this.existingSession.practiceSessionScheduleId) {
                    this.updatingSession = true;
                    if (this.dataStore.isScheduledPracticeSessionDateBeforeNextSessionToAddDate(this.existingSession.practiceSessionScheduleId, this.existingSession.sessionDate)) {
                        this.removeSession();
                    } else {
                        this.dataStore.removeSessionFromScheduledPracticeSession(this.existingSession, this.existingSession.practiceSessionScheduleId);
                    }

                    const updatedSessionThatIsScheduled: Session = {
                        ...this.existingSession,
                        sessionDate: moment(this.sessionStartTime).valueOf(),
                        sessionTitle: form.value.name,
                        sessionStartTime: moment(this.sessionStartTime).valueOf(),
                        sessionEndTime: moment(this.sessionEndTime).valueOf(),
                        originalScheduledSessionTime: this.existingSession.sessionStartTime
                    };

                    if (this.isPracticeSessionRecurring && this.disableRecurringSessionOption()) {
                        await this.setupAsRecurringSession();
                    } else {
                        this.updatingSession = true;
                        this.dataStore.addPracticeScheduledSession(updatedSessionThatIsScheduled);
                        this.closeModal();
                    }

                    this.updatingSession = false;
                } else {
                    this.updatingSession = true;
                    if (this.isPracticeSessionRecurring && this.disableRecurringSessionOption()) {
                        this.removeSession();
                        await this.setupAsRecurringSession();
                    } else {
                        this.updatePracticeSession(form);
                    }
                }
                this.analyticsService.sessionEdited('PS',  this.helperService.isSessionBeforeOrAfterCurrentDateToLetter(this.sessionStartTime));
            }
        } else {
            this.showHideError(true, 'ErrorMessages.InvalidTimes');
        }
    }


    async editPracticeSessionPopUpModal(form: NgForm) {
        const updatedSessionThatIsScheduled: Session = {
            ...this.existingSession,
            sessionDate: moment(this.sessionStartTime).valueOf(),
            sessionTitle: form.value.name,
            sessionStartTime: moment(this.sessionStartTime).valueOf(),
            sessionEndTime: moment(this.sessionEndTime).valueOf(),
            originalScheduledSessionTime: this.existingSession.sessionStartTime
        };

        const modal = await this.modalController.create({
            component: EditPracticeSessionActionModalComponent,
            cssClass: 'modal-structure-5',
            backdropDismiss: true,
            componentProps: {
                scheduledSession: updatedSessionThatIsScheduled,
                sessionAlreadyAdded: this.dataStore.isScheduledPracticeSessionDateBeforeNextSessionToAddDate(this.existingSession.practiceSessionScheduleId, this.existingSession.sessionDate)
            }
        });

        modal.onDidDismiss()
            .then((dismiss) => {
                this.closeModal();
            });

        return await modal.present();
    }
    addPracticeSession(form: NgForm) {
        const newSession: Session = {
            sessionScorePointsAway: 0,
            sessionScorePointsHome: 0,
            sessionId: '',
            sessionSquadId: this.dataStore.activeSquad.squadId,
            sessionType: 0,
            sessionDate: moment(this.sessionStartTime).valueOf(),
            sessionTitle: form.value.name,
            session1stEndTime: 0,
            session1stStartTime: 0,
            session2ndStartTime: 0,
            session2ndEndTime: 0,
            sessionStartTime: moment(this.sessionStartTime).valueOf(),
            sessionEndTime: moment(this.sessionEndTime).valueOf(),
            sessionOppoTeamName: '',
            sessionPitchId: this.sessionPitchId,
            sessionLocation: this.sessionLocation,
            sessionResult: 0,
            sessionScoreAway: 0,
            sessionScoreHome: 0,
            sessionSyncTotal: 0,
            isCoachCreated: true,
            createdBy: this.dataStore.user.coachId,
        };

        this.sessionService.createSession(newSession).subscribe((session: Session) => {
            this.dataStore.addSessionToActiveSquad(session);
            this.analyticsService.sessionAdded('PS', this.helperService.isSessionBeforeOrAfterCurrentDateToLetter(this.sessionStartTime));
            this.closeModal();
        }, () => {
            this.updatingSession = false;
            // To do: Add translation
            this.showHideError(true, 'Creation failed');
        });
    }

    updatePracticeSession(form: NgForm) {
        const session = this.dataStore.activeSquad.squadSessions.find(s => s.sessionId === this.existingSession.sessionId);
        if (session) {
            session.sessionType = 0;
            session.sessionDate = moment(this.sessionStartTime).valueOf();
            session.sessionTitle = form.value.name;
            session.session1stEndTime = 0;
            session.session1stStartTime = 0;
            session.session2ndStartTime = 0;
            session.session2ndEndTime = 0;
            session.sessionStartTime = moment(this.sessionStartTime).valueOf();
            session.sessionEndTime = moment(this.sessionEndTime).valueOf();
            session.sessionOppoTeamName = '';
            session.sessionPitchId = this.sessionPitchId;
            session.sessionLocation = this.sessionLocation;
            session.sessionResult = 0;
            session.sessionScoreAway = 0;
            session.sessionScoreHome = 0;

            this.updateSession(session);
        }
    }

    gameDayFormSubmit(form: NgForm) {

        if (this.modalType === 1) {
            // Check session times are valid
            if (moment(this.session1stEndTime).isAfter(moment(this.session1stStartTime)) && moment(this.session2ndEndTime).isAfter(moment(this.session2ndStartTime)) && moment(this.session2ndStartTime).isAfter(moment(this.session1stStartTime))) {
                this.updatingSession = true;
                this.addGameDaySession(form);
            } else {
                this.showHideError(true, 'ErrorMessages.InvalidTimes');
            }
        } else {
            // Check session times are valid
            if (moment(this.session1stEndTime).isAfter(moment(this.session1stStartTime)) && moment(this.session2ndEndTime).isAfter(moment(this.session2ndStartTime)) && moment(this.session2ndStartTime).isAfter(moment(this.session1stStartTime))) {
                this.updatingSession = true;

                // if session has already been added we need to remove it
                if (this.existingSession.practiceSessionScheduleId) {
                    // already been added
                    if (this.dataStore.isScheduledPracticeSessionDateBeforeNextSessionToAddDate(this.existingSession.practiceSessionScheduleId, this.existingSession.sessionDate)) {
                        this.removeSession();
                    } else {
                        this.dataStore.removeSessionFromScheduledPracticeSession(this.existingSession, this.existingSession.practiceSessionScheduleId);
                    }
                    this.addGameDaySession(form);
                } else {
                    this.updateGameDaySession(form);
                }
            } else {
                this.showHideError(true, 'ErrorMessages.InvalidTimes');
            }
        }
    }

    addLeadingZero = (time) => {
        const [hours, minutes] = time.split(':');
        return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
    }

    addGameDaySession(form: NgForm) {

        const newSession: Session = {
            sessionScorePointsAway: 0,
            sessionScorePointsHome: 0,
            sessionId: '',
            sessionSquadId: this.dataStore.activeSquad.squadId,
            sessionType: 1,
            sessionDate: moment(this.session1stStartTime).valueOf(),
            sessionTitle: `v ${form.value.oppName}`,
            session1stEndTime: moment(this.session1stEndTime).valueOf(),
            session1stStartTime: moment(this.session1stStartTime).valueOf(),
            session2ndStartTime: moment(this.session2ndStartTime).valueOf(),
            session2ndEndTime: moment(this.session2ndEndTime).valueOf(),
            sessionStartTime: moment(this.session1stStartTime).valueOf(),
            sessionEndTime: moment(this.session2ndEndTime).valueOf(),
            sessionOppoTeamName: form.value.oppName,
            sessionPitchId: this.sessionPitchId,
            sessionLocation: this.sessionLocation,
            sessionResult: this.getSessionResult(+form.value.sessionScore, +form.value.sessionOppScore, +form.value.sessionScorePointsHome, +form.value.sessionScorePointsAway),
            sessionScoreAway: +form.value.sessionOppScore,
            sessionScoreHome: +form.value.sessionScore,
            sessionSyncTotal: 0,
            isCoachCreated: true,
            createdBy: this.dataStore.user.coachId
        };
        if (this.dataStore.activeSquad.squadSport === 2) {
            newSession.sessionScorePointsHome = +form.value.sessionScorePointsHome;
            newSession.sessionScorePointsAway = +form.value.sessionScorePointsAway;
        }
        this.sessionService.createSession(newSession).subscribe((session: Session) => {
            this.dataStore.addSessionToActiveSquad(session);
            this.analyticsService.sessionAdded('GS', this.helperService.isSessionBeforeOrAfterCurrentDateToLetter(this.sessionStartTime));

            this.closeModal();
        }, () => {
            this.updatingSession = false;
            // To do: Add translation
            this.showHideError(true, 'Creation failed');
        });
    }

    updateGameDaySession(form: NgForm) {
        const session = this.dataStore.activeSquad.squadSessions.find(s => s.sessionId === this.existingSession.sessionId);
        if (session) {
            session.sessionType = 1;
            session.sessionDate = moment(this.session1stStartTime).valueOf();
            session.sessionTitle = `v ${form.value.oppName}`;
            session.session1stEndTime = moment(this.session1stEndTime).valueOf();
            session.session1stStartTime = moment(this.session1stStartTime).valueOf();
            session.session2ndStartTime = moment(this.session2ndStartTime).valueOf();
            session.session2ndEndTime = moment(this.session2ndEndTime).valueOf();
            session.sessionStartTime = moment(this.session1stStartTime).valueOf();
            session.sessionEndTime = moment(this.session2ndEndTime).valueOf();
            session.sessionOppoTeamName = form.value.oppName;
            session.sessionPitchId = this.sessionPitchId;
            session.sessionLocation = this.sessionLocation;
            session.sessionResult = this.getSessionResult(+form.value.sessionScore, +form.value.sessionOppScore, +form.value.sessionScorePointsHome, +form.value.sessionScorePointsAway);
            session.sessionScoreAway = +form.value.sessionOppScore;
            session.sessionScoreHome = +form.value.sessionScore;

            if (this.dataStore.activeSquad.squadSport === 2) {
                session.sessionScorePointsHome = +form.value.sessionScorePointsHome;
                session.sessionScorePointsAway = +form.value.sessionScorePointsAway;
            }

            this.analyticsService.sessionEdited('GS', this.helperService.isSessionBeforeOrAfterCurrentDateToLetter(this.sessionStartTime));
            this.updateSession(session);
        }
    }

    updateSession(session: Session) {
        session.sessionResultRecorded = true;
        this.sessionService.updateSession(JSON.parse(JSON.stringify(this.helperService.omitKeys(session, ['sessionPlayers'])))).subscribe(() => {
            this.dataStore.editSessionForActiveSquad(session);

            if (session.sessionPlayers && session.sessionPlayers.length > 0) {
                this.metricService.updatePlayerPBs(session);
            }
            this.updatingSession = false;
            this.existingSession = session;
            this.dataStore.sessionEvent$.next({type: EventType.Edited, session});
            this.closeModal();
        }, () => {
            this.updatingSession = false;
            // To do: Add translation
            this.showHideError(true, 'Update failed');
        });

    }

    convertToNumber(event): number {
        return +event;
    }

    getSessionResult(homeScore: number, awayScore: number, homePoints?: number, awayPoints?: number): number {
        // 1 - Win
        // 2 - Lose
        // 3 - Draw
        if (this.dataStore.activeSquad.squadSport === 2) {
            homeScore = (homeScore * 3) + homePoints;
            awayScore = (awayScore * 3) + awayPoints;
        }

        if (homeScore > awayScore) {
            return 1;
        } else if (awayScore > homeScore) {
            return 2;
        } else {
            return 3;
        }
    }

    getHalfLength(): number {
        switch (Number(this.dataStore.activeSquad.squadSport)) {
            case 0: {
                return 45;
            }
            case 1: {
                return 40;
            }
            case 2: {
                return 35;
            }
            case 3: {
                return 35;
            }
            default: {
                return 35;
            }
        }
    }

    getTimeBetweenHalves(): number {
        switch (Number(this.dataStore.activeSquad.squadSport)) {
            case 0: {
                return 15;
            }
            case 1: {
                return 10;
            }
            case 2: {
                return 15;
            }
            case 3: {
                return 10;
            }
            default: {
                return 15;
            }
        }
    }

    async openPitchPicker() {
        if (this.dataStore.activeSquad.squadPitches && this.dataStore.activeSquad.squadPitches.length > 0) {
            this.picker = await this.pickerController.create({
                columns: this.getPitchPickerColumns(this.dataStore.activeSquad.squadPitches),
                buttons: [{
                    text: 'Cancel', role: 'cancel'
                }, {
                    text: 'Confirm', handler: (value) => {
                        this.sessionPitchName = value['col-1'].text;
                        this.sessionPitchId = value['col-1'].value;
                    }
                }],
                animated: false,
                mode: 'md',
                cssClass: this.sessionType === SessionType.Practice ? 'open-pitch-picker-add-session-practice' : 'open-pitch-picker-add-session-gameday'
            });

            if (this.existingSession && this.existingSession.sessionPitchId) {
                this.picker.columns[0].selectedIndex = this.dataStore.activeSquad.squadPitches.findIndex(item => item.pitchId === this.existingSession.sessionPitchId);
            }

            this.picker.present();

            this.pickerMaxIndex = this.dataStore.activeSquad.squadPitches.length - 1;

            this.picker.addEventListener('wheel', this.handleWheelEvent);

        } else {
            this.showNoPitchesAlert();
        }
    }

    async pickerScroll(newIndex) {
        this.editPitchModalOpen = true;
        const prevPicker = this.picker;

        this.picker = await this.pickerController.create({
            columns: this.getPitchPickerColumns(this.dataStore.activeSquad.squadPitches),
            buttons: [{
                text: 'Cancel', role: 'cancel'
            }, {
                text: 'Confirm', handler: (value) => {
                    this.sessionPitchName = value['col-1'].text;
                    this.sessionPitchId = value['col-1'].value;
                }
            }],
            animated: false,
            mode: 'md',
            cssClass: this.sessionType === SessionType.Practice ? 'open-pitch-picker-add-session-practice' : 'open-pitch-picker-add-session-gameday'
        });

        this.pickerCurrentIndex = newIndex;

        if (this.pickerCurrentIndex > this.pickerMaxIndex) {
            this.pickerCurrentIndex = 0;
        } else if (this.pickerCurrentIndex < 0) {
            this.pickerCurrentIndex = this.pickerMaxIndex;
        }


        this.picker.columns[0].selectedIndex = this.pickerCurrentIndex;

        this.picker.present();
        await prevPicker.dismiss();

        setTimeout(() => {
            this.editPitchModalOpen = false;
        }, 100);

        this.picker.addEventListener('wheel', this.handleWheelEvent);

    }


    handleWheelEvent = async (event) => {
        const deltaY = event.deltaY;
        const scrollThreshold = 20;

        const newIndex2 = deltaY > 0 ? this.pickerCurrentIndex + 1 : this.pickerCurrentIndex - 1;

        if (Math.abs(event.deltaY) > scrollThreshold && this.editPitchModalOpen === false) {
            // Remove the event listener using the handleWheelEvent function reference
            this.picker.removeEventListener('wheel', this.handleWheelEvent);
            await this.pickerScroll(newIndex2);
        }
    }

    showHideError(showHide: boolean, transKey?: string) {
        if (showHide) {
            this.translate.get(transKey).subscribe((res: string) => {
                this.errorMessage = res;
                this.showError = true;
            });
        } else {
            this.showError = false;
            this.errorMessage = '';
        }
    }

    isFutureGameDaySession(): boolean {
        return moment(this.session1stStartTime).isAfter(moment()) || moment(this.session1stEndTime).isAfter(moment()) || moment(this.session2ndStartTime).isAfter(moment()) || moment(this.session2ndEndTime).isAfter(moment());
    }

    numberOnlyChecker($event): boolean {
        return !($event.keyCode !== 46 && $event.keyCode > 31 && ($event.keyCode < 48 || $event.keyCode > 57));
    }

    async removeSessionAlert() {
        if (this.existingSession.practiceSessionScheduleId && this.helperService.checkTimestampIsAfterToday(this.existingSession.sessionStartTime)) {
            const modal = await this.modalController.create({
                component: DeletePracticeScheduledSessionActionModalComponent,
                cssClass: 'modal-structure-5',
                backdropDismiss: true,
                componentProps: {
                    scheduledSession: this.existingSession,
                    sessionAlreadyAdded: this.dataStore.isScheduledPracticeSessionDateBeforeNextSessionToAddDate(this.existingSession.practiceSessionScheduleId, this.existingSession.sessionDate)
                }
            });

            modal.onDidDismiss()
                .then((dismiss) => {
                    this.closeModal();
                });

            return await modal.present();
        } else if (this.existingSession.gameDayBulkId && this.helperService.checkTimestampIsAfterToday(this.existingSession.sessionStartTime)) {
            const modal = await this.modalController.create({
                component: DeleteGamedayBulkSessionsActionModalComponent,
                cssClass: 'modal-structure-5',
                backdropDismiss: true,
                componentProps: {
                    bulkGameDayId: this.existingSession.gameDayBulkId, sessionId: this.existingSession.sessionId
                }
            });

            modal.onDidDismiss()
                .then((dismiss) => {
                    this.closeModal();
                });

            return await modal.present();
        } else {
            const alert = await this.alertController.create({
                header: 'Delete Session',
                message: `Are you sure you want to permanently delete this session? This will remove all your players data for this session.`,
                buttons: [{
                    text: 'Cancel', cssClass: 'alert-dialog-btn', role: 'cancel'
                }, {
                    text: 'Delete',
                    cssClass: 'alert-dialog-btn alert-danger-btn',
                    handler: () => this.ngZone.run(() => {
                        this.removeSession();
                        this.analyticsService.sessionDeleted(this.sessionType === SessionType.GameDay ? 'GS' : 'PS' , this.helperService.isSessionBeforeOrAfterCurrentDateToLetter(this.sessionStartDate));
                    })
                }],
                cssClass: 'alert-dialog',
                mode: 'md'
            });
            await alert.present();
        }
    }

    validatePracticeTime($event, timeUpdate: boolean, startTime: boolean) {
        if ($event.length < 4) {
            return;
        }//
        if (timeUpdate) {
            if ($event.length === 4) {
                const tempTime = moment($event, 'HH:mm');

                if (tempTime.isValid()) {
                    if (startTime) {
                        this.sessionStartTime = moment(this.sessionStartTime).set({
                            hour: tempTime.hour(), minute: tempTime.minute()
                        }).toISOString();

                        if (moment(this.sessionStartTime).isAfter(moment(this.sessionEndTime))) {
                            this.sessionEndTime = moment(this.sessionStartTime).add(2, 'h').toISOString();
                            this.sessionEndHHMM = moment(this.sessionEndTime).format('HH:mm');
                        }
                    } else {
                        this.sessionEndTime = moment(this.sessionEndTime).set({
                            hour: tempTime.hour(), minute: tempTime.minute()
                        }).toISOString();

                        if (moment(this.sessionEndTime).isBefore(moment(this.sessionStartTime))) {
                            this.sessionStartTime = moment(this.sessionEndTime).add(-2, 'h').toISOString();
                            this.sessionStartHHMM = moment(this.sessionStartTime).format('HH:mm');
                        }
                    }


                }
            }
        } else {
            if ($event.isValid()) {
                if (startTime) {
                    this.sessionStartTime = moment(this.sessionStartTime).set({
                        date: $event.date(), month: $event.month(), year: $event.year()
                    }).toISOString();

                    // session is after
                    if (moment(this.sessionStartTime).isAfter(moment(this.sessionEndTime))) {
                        this.sessionEndTime = moment(this.sessionEndTime).set({
                            date: $event.date(), month: $event.month(), year: $event.year()
                        }).toISOString();

                        this.sessionEndDate = moment(this.sessionEndTime).format(this.dateFormat);
                    }
                } else {
                    this.sessionEndTime = moment(this.sessionEndTime).set({
                        date: $event.date(), month: $event.month(), year: $event.year()
                    }).toISOString();

                    if (moment(this.sessionStartTime).isAfter(moment(this.sessionEndTime))) {
                        this.sessionStartTime = moment(this.sessionStartTime).set({
                            date: $event.date(), month: $event.month(), year: $event.year()
                        }).toISOString();
                        this.sessionStartDate = moment(this.sessionStartTime).format(this.dateFormat);
                    }


                }
            }

        }

        const startDate = moment(this.sessionStartDate, this.dateFormat);
        const repeatDate = moment(this.sessionEndRepeatDate, this.dateFormat);


        if (startDate.isAfter(repeatDate)) {
            this.sessionEndRepeatDate = startDate.add(1, 'year').format(this.dateFormat);
        }
    }

    validateGameDay1stHalfTime($event, timeUpdate: boolean, startTime: boolean) {
        if ($event.length < 4) {
            return;
        }
        if (timeUpdate) {
            if ($event.length === 4) {
                const tempTime = moment($event, 'HH:mm');

                if (tempTime.isValid()) {
                    if (startTime) {
                        this.session1stStartTime = moment(this.session1stStartTime).set({
                            hour: tempTime.hour(), minute: tempTime.minute()
                        }).toISOString();

                        if (moment(this.session1stStartTime).isAfter(moment(this.session1stEndTime))) {
                            this.session1stEndTime = moment(this.session1stStartTime).add(this.getHalfLength(), 'm').toISOString();
                            this.session1stEndTimeHHMM = moment(this.session1stStartTime).add(this.getHalfLength(), 'm').format('HH:mm');
                        }

                        this.validateTimeBetweenHalves();
                        this.validateTimeBetween2ndHalf();
                    } else {
                        this.session1stEndTime = moment(this.session1stEndTime).set({
                            hour: tempTime.hour(), minute: tempTime.minute()
                        }).toISOString();

                        this.validateTimeBetween1stHalf();
                        this.validateTimeBetweenHalves();
                        this.validateTimeBetween2ndHalf();
                    }
                }
            }
        } else {
            if ($event.isValid()) {
                if (startTime) {
                    this.session1stStartTime = moment(this.session1stStartTime).set({
                        date: $event.date(), month: $event.month(), year: $event.year()
                    }).toISOString();

                    if (moment(this.session1stStartTime).isAfter(moment(this.session1stEndTime))) {
                        this.session1stEndTime = moment(this.session1stEndTime).set({
                            date: $event.date(), month: $event.month(), year: $event.year()
                        }).toISOString();

                        this.session1stEndDate = moment(this.session1stEndTime).format(this.dateFormat);

                        this.validateTimeBetweenHalves();
                        this.validateTimeBetween2ndHalf();
                    }
                } else {
                    this.session1stEndTime = moment(this.session1stEndTime).set({
                        date: $event.date(), month: $event.month(), year: $event.year()
                    }).toISOString();

                    if (moment(this.session1stStartTime).isAfter(moment(this.session1stEndTime))) {
                        this.session1stStartTime = moment(this.session1stStartTime).set({
                            date: $event.date(), month: $event.month(), year: $event.year()
                        }).toISOString();

                        this.session1stStartDate = moment(this.session1stStartTime).format(this.dateFormat);

                        this.validateTimeBetween1stHalf();
                        this.validateTimeBetweenHalves();
                        this.validateTimeBetween2ndHalf();
                    }
                }
            }
        }
    }

    validateGameDay2ndHalfTime($event, timeUpdate: boolean, startTime: boolean) {
        if ($event.length < 4) {
            return;
        }

        if (timeUpdate) {
            if ($event.length === 4) {
                const tempTime = moment($event, 'HH:mm');

                if (tempTime.isValid()) {
                    if (startTime) {
                        this.session2ndStartTime = moment(this.session2ndStartTime).set({
                            hour: tempTime.hour(), minute: tempTime.minute()
                        }).toISOString();

                        if (moment(this.session2ndStartTime).isAfter(moment(this.session2ndEndTime))) {
                            this.session2ndEndTime = moment(this.session2ndStartTime).add(this.getHalfLength(), 'm').toISOString();
                            this.session2ndEndTimeHHMM = moment(this.session2ndStartTime).add(this.getHalfLength(), 'm').format('HH:mm');
                        }

                        this.validateTimeBetween2ndHalf();
                        this.validateTimeBefore2ndHalf();
                        this.validateTimeBetween1stHalf();
                    } else {
                        this.session2ndEndTime = moment(this.session2ndEndTime).set({
                            hour: tempTime.hour(), minute: tempTime.minute()
                        }).toISOString();

                        this.validateTimeBefore2ndHalf();
                        this.validateTimeBetween1stHalf();
                    }
                }
            }
        } else {
            if ($event.isValid()) {
                if (startTime) {
                    this.session2ndStartTime = moment(this.session2ndStartTime).set({
                        date: $event.date(), month: $event.month(), year: $event.year()
                    }).toISOString();

                    if (moment(this.session2ndStartTime).isAfter(moment(this.session2ndEndTime))) {
                        this.session2ndEndTime = moment(this.session2ndEndTime).set({
                            date: $event.date(), month: $event.month(), year: $event.year()
                        }).toISOString();

                        this.session2ndEndDate = moment(this.session2ndEndTime).format(this.dateFormat);

                        this.validateTimeBetween2ndHalf();
                        this.validateTimeBefore2ndHalf();
                        this.validateTimeBetween1stHalf();
                    }
                } else {
                    this.session2ndEndTime = moment(this.session2ndEndTime).set({
                        date: $event.date(), month: $event.month(), year: $event.year()
                    }).toISOString();

                    if (moment(this.session2ndStartTime).isAfter(moment(this.session2ndEndTime))) {
                        this.session2ndStartTime = moment(this.session2ndStartTime).set({
                            date: $event.date(), month: $event.month(), year: $event.year()
                        }).toISOString();

                        this.session2ndStartDate = moment(this.session2ndStartTime).format(this.dateFormat);

                        this.validateTimeBefore2ndHalf();
                        this.validateTimeBetween1stHalf();
                    }
                }
            }
        }
    }

    validateTimeBetweenHalves() {
        if (moment(this.session1stEndTime).isAfter(moment(this.session2ndStartTime))) {
            this.session2ndStartTime = moment(this.session1stEndTime).add(this.getTimeBetweenHalves(), 'm').toISOString();
            this.session2ndStartTimeHHMM = moment(this.session1stEndTime).add(this.getTimeBetweenHalves(), 'm').format('HH:mm');
            this.session2ndStartDate = moment(this.session1stEndTime).add(this.getTimeBetweenHalves(), 'm').format(this.dateFormat);
        }
    }

    validateTimeBetween1stHalf() {
        if (moment(this.session1stEndTime).isBefore(moment(this.session1stStartTime))) {
            this.session1stStartTime = moment(this.session1stEndTime).add(-this.getHalfLength(), 'm').toISOString();
            this.session1stStartTimeHHMM = moment(this.session1stEndTime).add(-this.getHalfLength(), 'm').format('HH:mm');
            this.session1stStartDate = moment(this.session1stEndTime).add(-this.getHalfLength(), 'm').format(this.dateFormat);
        }
    }

    validateTimeBetween2ndHalf() {
        if (moment(this.session2ndStartTime).isAfter(moment(this.session2ndEndTime))) {
            this.session2ndEndTime = moment(this.session2ndStartTime).add(this.getHalfLength(), 'm').toISOString();
            this.session2ndEndTimeHHMM = moment(this.session2ndStartTime).add(this.getHalfLength(), 'm').format('HH:mm');
            this.session2ndEndDate = moment(this.session2ndStartTime).add(this.getHalfLength(), 'm').format(this.dateFormat);
        }
    }

    validateTimeBefore2ndHalf() {
        if (moment(this.session2ndStartTime).isBefore(moment(this.session1stEndTime))) {
            this.session1stEndTime = moment(this.session2ndStartTime).add(-this.getTimeBetweenHalves(), 'm').toISOString();
            this.session1stEndTimeHHMM = moment(this.session2ndStartTime).add(-this.getTimeBetweenHalves(), 'm').format('HH:mm');
            this.session1stEndDate = moment(this.session2ndStartTime).add(-this.getTimeBetweenHalves(), 'm').format(this.dateFormat);
        }
    }

    async showNoPitchesAlert() {
        const alert = await this.alertController.create({
            header: `There are no saved pitches. Add a pitch through the Settings`, buttons: [{
                text: 'Cancel', cssClass: 'alert-dialog-btn', role: 'cancel'
            }, {
                text: 'Add new pitch', cssClass: 'alert-dialog-btn', handler: () => this.ngZone.run(() => {
                    this.navigateToPitches();
                })
            }], cssClass: 'alert-dialog', mode: 'md'
        });

        await alert.present();
    }

    async navigateToPitches() {

        const alert = await this.alertController.create({
            header: this.translate.instant('Resources.DoYouWantToDiscardUnsavedChanges'),
            message: this.translate.instant('Resources.NavigateToPitchMessage'),
            buttons: [{
                text: this.translate.instant('Resources.Cancel'), cssClass: 'alert-dialog-btn', role: 'cancel'
            }, {
                text: this.translate.instant('Resources.AddPitch'), cssClass: 'alert-dialog-btn', handler: () => {
                    this.translate.get('Resources.NewPitch').subscribe((res: string) => {
                        this.dataStore.activePitchName = res;
                    });
                    this.modalController.dismiss().then(() => this.nav.navigateForward('/tabs/settings/pitches/managePitch/').then());
                }
            }],
            cssClass: 'alert-dialog',
            mode: 'md'
        });

        await alert.present();
    }

    // Calendar Widget logic
    openDatePicker($event) {
        this.dateFieldSelected = $event.target.name;

        this.setDefaultCalendarOptions();

        if (this.dateFieldSelected === 'startDate') {
            this.calendarDate = moment(this.sessionStartDate, this.dateFormat);
        } else if (this.dateFieldSelected === 'endDate') {
            this.calendarDate = moment(this.sessionEndDate, this.dateFormat);
            // the end date is more than the current repeat until date so we set the repeat until date to 365 + the end date
        } else if (this.dateFieldSelected === 'session1stStartDate') {
            this.calendarDate = moment(this.session1stStartDate, this.dateFormat);
        } else if (this.dateFieldSelected === 'session1stEndDate') {
            this.calendarDate = moment(this.session1stEndDate, this.dateFormat);
        } else if (this.dateFieldSelected === 'session2ndStartDate') {
            this.calendarDate = moment(this.session2ndStartDate, this.dateFormat);
        } else if (this.dateFieldSelected === 'session2ndEndDate') {
            this.calendarDate = moment(this.session2ndEndDate, this.dateFormat);
        } else if (this.dateFieldSelected === 'sessionEndRepeatDate') {
            this.setCalendarOptionsRecurringSelection();
            this.calendarDate = moment(this.sessionEndRepeatDate, this.dateFormat);
        }

        this.modalScreen = 2;
    }

    onChange($event) {
        this.showHideError(false);
        this.modalScreen = 1;

        if (this.dateFieldSelected === 'startDate') {
            this.sessionStartDate = $event.format(this.dateFormat);
            this.validatePracticeTime($event, false, true);
        } else if (this.dateFieldSelected === 'endDate') {
            this.sessionEndDate = $event.format(this.dateFormat);
            this.validatePracticeTime($event, false, false);
        } else if (this.dateFieldSelected === 'session1stStartDate') {
            this.session1stStartDate = $event.format(this.dateFormat);
            this.validateGameDay1stHalfTime($event, false, true);
        } else if (this.dateFieldSelected === 'session1stEndDate') {
            this.session1stEndDate = $event.format(this.dateFormat);
            this.validateGameDay1stHalfTime($event, false, false);
        } else if (this.dateFieldSelected === 'session2ndStartDate') {
            this.session2ndStartDate = $event.format(this.dateFormat);
            this.validateGameDay2ndHalfTime($event, false, true);
        } else if (this.dateFieldSelected === 'session2ndEndDate') {
            this.session2ndEndDate = $event.format(this.dateFormat);
            this.validateGameDay2ndHalfTime($event, false, false);
        } else if (this.dateFieldSelected === 'sessionEndRepeatDate') {
            this.sessionEndRepeatDate = $event.format(this.dateFormat);
        }

        this.setSessionSdEd();
    }

    setSessionSdEd() {

        if (this.sessionType === 0) {
            if (this.dateFieldSelected === 'startDate') {
                this.sessionEndDate = this.sessionStartDate;
                this.sessionEndTime = moment(this.sessionEndTime)
                    .year(moment(this.sessionStartTime).year())
                    .month(moment(this.sessionStartTime).month())
                    .date(moment(this.sessionStartTime).date())
                    .hour(moment(this.sessionEndTime).hour())
                    .minute(moment(this.sessionEndTime).minute())
                    .second(moment(this.sessionEndTime).second())
                    .toISOString();
            }
        } else if (this.sessionType === 1) {
            if (this.dateFieldSelected === 'session1stStartDate') {
                this.session1stEndDate = this.session1stStartDate;
                this.session2ndStartDate = this.session1stStartDate;
                this.session2ndEndDate = this.session1stStartDate;

                this.session1stEndTime = moment(this.session1stEndTime)
                    .year(moment(this.session1stStartTime).year())
                    .month(moment(this.session1stStartTime).month())
                    .date(moment(this.session1stStartTime).date())
                    .hour(moment(this.session1stEndTime).hour())
                    .minute(moment(this.session1stEndTime).minute())
                    .second(moment(this.session1stEndTime).second())
                    .toISOString();
                this.session2ndStartTime = moment(this.session2ndStartTime)
                    .year(moment(this.session1stStartTime).year())
                    .month(moment(this.session1stStartTime).month())
                    .date(moment(this.session1stStartTime).date())
                    .hour(moment(this.session2ndStartTime).hour())
                    .minute(moment(this.session2ndStartTime).minute())
                    .second(moment(this.session2ndStartTime).second())
                    .toISOString();
                this.session2ndEndTime = moment(this.session2ndEndTime)
                    .year(moment(this.session1stStartTime).year())
                    .month(moment(this.session1stStartTime).month())
                    .date(moment(this.session1stStartTime).date())
                    .hour(moment(this.session2ndEndTime).hour())
                    .minute(moment(this.session2ndEndTime).minute())
                    .second(moment(this.session2ndEndTime).second())
                    .toISOString();

                this.sessionStartTime = this.session2ndEndTime;
                this.sessionEndTime = this.session2ndEndTime;
            }
        }
    }

    validateDate(currentDate: number): any {
        const momentConvert = moment(currentDate);

        if (momentConvert.isValid()) {
            return moment(currentDate);
        }

        // Deal with a bug when times are in nanoseconds
        if (currentDate.toString().length === 19) {
            return moment(currentDate / 1000000);
        }

        return moment();
    }

    getSliderStyle(gameType) {
        if (this.sessionType === gameType) {
            return {width: '100%'};
        }
        return {width: '0'};
    }

    getSliderStyle2(sessionLocation) {
        if (this.sessionLocation === sessionLocation) {
            return {width: '100%'};
        }
        return {width: '0'};
    }

    convertTo24hrFormat($event, startTime = true, gameday = false, firstHalf = true) {
        $event.target.value = this.formatTimeWithColonAndZero($event.target.value, startTime, gameday, firstHalf);
    }

    formatTimeWithColonAndZero(value: string, startTime = true, gameday = false, firstHalf = true): string {
        const [hours, minutes] = value.split(':');
        let formattedHours = hours;
        let formattedMinutes = minutes;

        const timeHHMM = formattedHours + ':' + formattedMinutes;

        if (!hours && !minutes) {
            return '';
        }

        // if number is in correct format no need to format
        if (hours.length === 2 && minutes.length === 2) {
            this.validateTime(timeHHMM, startTime, gameday, firstHalf);
            return hours + ':' + minutes;
        }

        if (hours.length > 1) {
            formattedMinutes += '0';
            this.validateTime(timeHHMM, startTime, gameday, firstHalf);
        } else {
            formattedHours = this.addLeadingZeroAndHandle12HourFormat(+hours, minutes, startTime, gameday, firstHalf);
        }

        // Convert hours to a number and handle 12-hour format

        // Return the modified value
        return formattedHours + ':' + formattedMinutes;
    }


    addLeadingZeroAndHandle12HourFormat(hours: number, minutes: string, startTime = true, gameday = false, firstHalf = true): string {
        // Handle 12-hour format by converting 12 to 0
        const formattedHours = hours < 10 ? '0' + hours : hours.toString();
        const stringNum = formattedHours + ':' + minutes;
        this.validateTime(stringNum, startTime, gameday, firstHalf);
        return formattedHours;
    }


    validateTime(stringNum, startTime = true, gameday = false, firstHalf = true) {
        if (!gameday) {
            this.validatePracticeTime(stringNum.replace(/[^0-9:.-]+/g, ''), true, startTime);
        } else {
            if (firstHalf) {
                this.validateGameDay1stHalfTime(stringNum.replace(/[^0-9:.-]+/g, ''), true, startTime);
            } else {
                this.validateGameDay2ndHalfTime(stringNum.replace(/[^0-9:.-]+/g, ''), true, startTime);
            }
        }

        if (stringNum.length === 5) {
            if (!gameday) {
                if (startTime) {
                    this.sessionStartHHMM = stringNum;
                } else {
                    this.sessionEndHHMM = stringNum;
                }
            } else {
                if (firstHalf) {
                    if (startTime) {
                        this.session1stStartTimeHHMM = stringNum;
                    } else {
                        this.session1stEndTimeHHMM = stringNum;
                    }
                } else {
                    if (startTime) {
                        this.session2ndStartTimeHHMM = stringNum;
                    } else {
                        this.session2ndEndTimeHHMM = stringNum;
                    }
                }
            }
        }
    }


    getPitchPickerColumns(pitches: Pitch[]) {
        return [{
            name: `col-1`, options: this.getPitchPickerColumnOptions(pitches)
        }];
    }


    getPitchPickerColumnOptions(pitches: Pitch[]) {
        const options = [];
        const favPitches = [];

        // Add favourites to the top of the list
        if (this.storage.favouritePitches && this.storage.favouritePitches.length > 0) {
            this.storage.favouritePitches.forEach(pId => {
                if (pitches.findIndex(p => p.pitchId === pId) !== -1) {
                    favPitches.push(pitches.find(p => p.pitchId === pId));
                }
            });
        }

        if (favPitches.length > 0) {
            pitches = pitches.filter(p => !favPitches.map(p2 => p2.pitchId).includes(p.pitchId));

            favPitches.forEach(p => {
                options.push({
                    text: p.pitchName, value: p.pitchId
                });
            });
        }

        pitches.forEach(p => {
            options.push({
                text: p.pitchName, value: p.pitchId
            });
        });

        return options;
    }

    removeSession() {
        this.showLoadingScreen = true;

        this.sessionService.deleteSession(this.existingSession.sessionId).subscribe(() => {
            this.dataStore.activeSquad.squadSessions = this.dataStore.activeSquad.squadSessions.filter(s => s.sessionId !== this.existingSession.sessionId);
            this.dataStore.selectedSessions = this.dataStore.selectedSessions.filter(s => s.sessionId !== this.existingSession.sessionId);
            this.dataStore.sessionEvent$.next({type: EventType.Removed, session: this.existingSession});
            this.closeModal();
        }, () => {
            this.showLoadingScreen = false;
            // To do: Add translation
            this.showHideError(true, 'Deletion failed');
        });
    }


    onInputFocus() {
        // Code to execute when the input gains focus
        if (this.platform.is('ios')) {
            this.translateElement(-250);
        }
    }

    onInputBlur() {
        if (this.platform.is('ios')) {
            this.translateElement(0);
        }
    }

    async setupAsRecurringSession() {
        const practiceSessionSchedule: PracticeSessionScheduleInterface = {
            sessionScheduleId: null,
            squadId: this.dataStore.activeSquad.squadId,
            nextSessionToAddDate: this.helperService.add7DaysToTheDate(moment(this.sessionStartTime).valueOf()),
            startTime: this.helperService.add7DaysToTheDate(moment(this.sessionStartTime).valueOf()),
            endTime: this.helperService.add7DaysToTheDate(moment(this.sessionEndTime).valueOf()),
            datesToExclude: [],
            sessionTitle: this.sessionTitle,
            sessionPitchId: this.sessionPitchId,
            scheduleSessionEndDate: moment(this.sessionEndRepeatDate, this.dateFormat).valueOf()
        };

        try {
            this.scheduleService.updatePracticeScheduledSession(practiceSessionSchedule)
                .subscribe(async (session) => {
                    this.dataStore.activeSquad.squadSessions.push(session);
                    this.dataStore.sessionEvent$.next({type: EventType.Added, session});
                    await this.dataStore.fetchUpdatedScheduledPracticeSessionsForSquad();
                    this.closeModal();
                    this.updatingSession = false;
                    // need to add on 1 to include the day that was originally  added
                    this.analyticsService.sessionAdded('PSR', this.helperService.isSessionBeforeOrAfterCurrentDateToLetter(this.sessionStartTime), 1, this.helperService.getDifferenceInWeeksBetweenTwoDates(practiceSessionSchedule.nextSessionToAddDate, practiceSessionSchedule.scheduleSessionEndDate) + 1);
                }, (error) => {
                    console.error('Error updating practice scheduled session:', error);
                });
        } catch (e) {
            console.log(e);
        }
    }

    disableRecurringSessionOption(): boolean {
        if (this.sessionStartHHMM.length < 4 || this.sessionEndHHMM.length < 4) {
            return false;
        }

        return moment(this.sessionStartTime).isAfter(moment());
    }

    async openAddBulkGamedaysModel() {
        this.closeModal();

        const modal = await this.modalController.create({
            component: AddBulkGamedaysComponent, cssClass: 'add-bulk-gameday-model', backdropDismiss: true
        });

        modal.onDidDismiss()
            .then((dismiss) => {
                this.dataStore.modalOpen = false;
            });

        return await modal.present();
    }

    private translateElement(value: number) {
        const modalElement = document.getElementsByClassName('add-session-modal')[0];

        if (modalElement) {
            this.renderer.setStyle(modalElement, 'marginTop', `${value}px`);
        }
    }
}
